import { RestServiceConnection } from '../restServiceConnection';

const TIPS_GROUPS_ENDPOINT = '/tip_group';

export class TipsGroupsService extends RestServiceConnection {

    listGroups = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_GROUPS_ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    getGroup = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_GROUPS_ENDPOINT + '/get',
            data: {
                tipGroup: id
            }
        }, true);
        return this;
    }

    getClientTips = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/clients/get-client-tips',
            data: {
                client: id
            }
        }, true);
        return this;
    }

    createGroup = async (group: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_GROUPS_ENDPOINT + '/create',
            data: {
                ...group
            }
        }, true);
        return this;
    }

    editGroup = async (group: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_GROUPS_ENDPOINT + '/edit',
            data: {
                ...group
            }
        }, true);
        return this;
    }

    deleteGroup = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_GROUPS_ENDPOINT + '/delete',
            data: {
                tipGroup: id
            }
        }, true);
        return this;
    }

    assignGroupToPatient = async (group: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_GROUPS_ENDPOINT + '/assign-tip-group',
            data: {
                ...group
            }
        }, true);
        return this;
    }

    deleteGroupFromPatient = async (tipGroupId: string, client: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_GROUPS_ENDPOINT + '/delete-tip-group',
            data: {
                tipGroup: tipGroupId,
                client: client
            }
        }, true);
        return this;
    }

}
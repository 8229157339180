import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const PATIENT_ENDPOINT = '/clients';

export class PatientService extends RestServiceConnection {

    getPatients = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/list',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getPatientsByUser = async (id: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/list',
            data: { user: id }
        }, true) as AxiosResponse;
        return this;
    }

    getUserPatients = async (id: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/list-user-clients',
            data: { user: id }
        }, true) as AxiosResponse;
        return this;
    }

    getPatientById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/get',
            data: { client: id }
        }, true);
        return this;
    }

    togglePatientStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/toggle',
            data: {
                client: id
            }
        }, true);
        return this;
    }

    createPatient = async (patient: any) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/create',
            data: patient,
        }, true);
        return this;
    }

    editPatient = async (patient: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/edit',
            data: patient,
        }, true);
        return this;
    }

    getAllPatientTags = async (companyId?: string, filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/list-labels',
            data: {
                filter: filters,
                company: companyId
            }
        }, true);
        return this;
    }

    getActivePatientTags = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/list-active-labels',
            data: {
                company: id
            }
        }, true);
        return this;
    }

    getPatientTags = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/get-label',
            data: {
                client: id
            }
        }, true);
        return this;
    }

    createPatientTags = async (tag: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/insert-label',
            data: tag,
        }, true);
        return this;
    }

    editPatientTags = async (id: string, tags: string[], company: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/create-label',
            data: {
                client: id,
                labels: tags,
                company: company
            },
        }, true);
        return this;
    }

    deletePatientTag = async (id: string, tags: string[], company: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/delete-label',
            data: {
                client: id,
                labels: tags,
            }
        }, true);
        return this;
    }

    toggleTagStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/toggle-label',
            data: {
                label: id
            }
        }, true);
        return this;
    }

    dropOrganizationLabel = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/drop-label',
            data: {
                label: id
            }
        }, true);
        return this;
    }

    editPatientImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('client', id);
        formData.append('profile_img', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/add-image',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deletePatientImg = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/remove-image',
            data: {
                client: id
            }
        }, true);
        return this;
    }

    deletePatient = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/delete',
            data: {
                client: id
            }
        }, true);
        return this;
    }

    /* TODO: Quitar de aqui */
    sendMessage = async (patientId: string, message: string, companyId: string, isUser: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/chat',
            data: {
                topic: patientId,
                text: message,
                //company: companyId,
                //sender: isUser
            }
        }, true);
        return this;
    }

    listDocuments = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/list-documents',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    uploadDocument = async (formData: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/upload-document',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    downloadDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/download-document',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: {
                document: id
            }
        }, true);
        return this;
    }

    deleteDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: PATIENT_ENDPOINT + '/delete-document',
            data: {
                document: id
            }
        }, true);
        return this;
    }

}
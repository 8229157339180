import { useCallback, useEffect, useState } from 'react';
import { FoodService } from '../../services/foods/foodService';
import { RecipeService } from '../../services/recipes/recipeService';
import { ActivitiesService } from '../../services/activities/activitiesService';
import useFetch from '../../hooks/useFetch';
import useFilters from '../../hooks/useFilters';
import Card, { CardTabItem } from '../bootstrap/Card';
import DraggableItem from './DraggableItem';
import FormGroup from '../bootstrap/forms/FormGroup';
import CustomSearchInput from '../bootstrap/forms/CustomSearchInput';
import Input from '../bootstrap/forms/Input';
import FoodFilters from './Filters/FoodFilters';
import RecipeFilters from './Filters/RecipeFilters';
import { activityFilters, foodFilters, recipeFilters, supplementFilters } from '../../type/menu-type';
import { removeAccents } from '../../utils/StringUtils';
import './styles/styles.scss';

const NutritionalMenuSelector = () => {

  const [searchFoodValue, setSearchFoodValue] = useState("");
  const [searchRecipeValue, setSearchRecipeValue] = useState("");
  const [searchPhysicalActivityValue, setSearchPhysicalActivityValue] = useState("");

  const [searchRecipeResults, setSearchRecipeResults] = useState<any[]>([]);
  const [searchPhysicalActivityResults, setSearchPhysicalActivityResults] = useState<any[]>([]);
  const [searchFoodResults, setSearchFoodResults] = useState<any[]>([]);
  const [searchSupplementResults, setSearchSupplementResults] = useState<any[]>([]);

  const [foodQuantity, setFoodQuantity] = useState<number>(100);
  const [physicalActivityDuration, setPhysicalActivityDuration] = useState<number>(30);

  const { filters, updateFilters, resetFilters } = useFilters(foodFilters);
  const { filters: filtersSupplements } = useFilters(supplementFilters);
  const { filters: filtersRecipes, updateFilters: updateFiltersRecipe, resetFilters: resetFiltersRecipe } = useFilters(recipeFilters);
  const { filters: filtersPhysicalActivities, updateFilters: updateFiltersPhysicalActivities } = useFilters(activityFilters);

  const [foodsData] = useFetch(useCallback(async () => {
    const response = await new FoodService().getAliments({ ...filters, limit: 999999 });
    return response.getResponseData();
  }, [filters]));

  const [supplementsData] = useFetch(useCallback(async () => {
    const response = await new FoodService().getAliments({ ...filtersSupplements, limit: 999999 });
    return response.getResponseData();
  }, [filtersSupplements]));

  const [recipesData] = useFetch(useCallback(async () => {
    const response = await new RecipeService().getRecipes({ ...filtersRecipes, limit: 999999 });
    return response.getResponseData();
  }, [filtersRecipes]));

  const [physicalActivitiesData] = useFetch(useCallback(async () => {
    const response = await new ActivitiesService().listActivities({ ...filtersPhysicalActivities, limit: 999999 });
    return response.getResponseData();
  }, [filtersPhysicalActivities]));

  const filterResults = (data: any[], searchValue: string, key: string) => {
    return data.filter((item: any) =>
      searchValue
        .toLowerCase()
        .split(' ')
        .every((searchWord: string) =>
          item[key]
            .toLowerCase()
            .split(' ')
            .some((itemWord: string) => removeAccents(itemWord).includes(searchWord))
        )
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const filteredResults = filterResults(recipesData?.recipes || [], searchRecipeValue, 'name');
      setSearchRecipeResults(
        filteredResults.map((result: any) => ({
          label: result.name,
          data: result,
        }))
      );
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchRecipeValue, recipesData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const filteredResults = filterResults(physicalActivitiesData?.physical_activities || [], searchPhysicalActivityValue, 'name');
      setSearchPhysicalActivityResults(
        filteredResults.map((result: any) => ({
          label: result.name,
          data: result,
        }))
      );
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchPhysicalActivityValue, physicalActivitiesData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const filteredResults = filterResults(foodsData?.data || [], searchFoodValue, 'name');
      setSearchFoodResults(
        filteredResults.map((result: any) => ({
          label: result.name,
          data: result,
        }))
      );
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchFoodValue, foodsData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const filteredResults = filterResults(supplementsData?.data || [], searchFoodValue, 'name');
      setSearchSupplementResults(
        filteredResults.map((result: any) => ({
          label: result.name,
          data: result,
        }))
      );
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchFoodValue, supplementsData]);

  const renderItems = (items: any[], isFood = false, isRecipe = false, isActivity = false, quantity = 0) => {
    return (
      <div className="row itemsCard-container d-flex justify-content-around p-2">
        {items.map((element, index) => {
          const { label, data } = element;
          return (
            <DraggableItem
              key={index}
              label={label}
              data={data}
              food={isFood}
              recipe={isRecipe}
              activity={isActivity}
              quantity={(isFood || isActivity) ? quantity : undefined}
              fromSelectors={isFood}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className='row mx-0'>
      <Card stretch={true} hasTab className='p-0' id='select-items'>
        <CardTabItem id='recipes' title='Recetas'>
          <div className='row p-0'>
            <FormGroup label='Nombre' className='col-md-2'>
              <CustomSearchInput
                onSearch={(search: string) => {
                  updateFiltersRecipe({ search_array: search });
                  setSearchRecipeValue(search);
                }}
                placeholder='Buscar receta ...'
              />
            </FormGroup>
            <div className='col-md-10'>
              <RecipeFilters updateFilters={updateFiltersRecipe} resetFilters={resetFiltersRecipe} />
            </div>
          </div>
          {renderItems(searchRecipeResults, false, true)}
        </CardTabItem>

        <CardTabItem id='foods' title='Alimentos'>
          <div className='row p-0'>
            <FormGroup label='Nombre' className='col-md-2'>
              <CustomSearchInput
                onSearch={(search: string) => {
                  updateFilters({ search_array: search });
                  setSearchFoodValue(search);
                }}
                placeholder='Buscar alimento ...'
              />
            </FormGroup>
            <FormGroup label='Cantidad (g)' className='col-md-2'>
              <Input
                type='number'
                value={foodQuantity}
                onChange={(e: any) => setFoodQuantity(e.target.value)}
                className='w-50'
              />
            </FormGroup>
            <div className='col-md-8'>
              <FoodFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
            </div>
          </div>
          {renderItems(searchFoodResults, true, false, false, foodQuantity)}
        </CardTabItem>

        <CardTabItem id='suplements' title='Suplementos'>
          <div className='row p-0'>
            <FormGroup label='Nombre' className='col-md-4'>
              <CustomSearchInput
                onSearch={(search: string) => {
                  updateFilters({ search_array: search });
                  setSearchFoodValue(search);
                }}
                placeholder='Buscar suplemento ...'
              />
            </FormGroup>
            <FormGroup label='Cantidad (g)' className='col-md-2'>
              <Input
                type='number'
                value={foodQuantity}
                onChange={(e: any) => setFoodQuantity(e.target.value)}
                className='w-50'
              />
            </FormGroup>
          </div>
          {renderItems(searchSupplementResults, true)}
        </CardTabItem>

        <CardTabItem id='physicalActivities' title='Ejercicio'>
          <div className='row p-0'>
            <FormGroup label='Nombre' className='col-md-3'>
              <CustomSearchInput
                onSearch={(search: string) => {
                  updateFiltersPhysicalActivities({ search_array: search });
                  setSearchPhysicalActivityValue(search);
                }}
                placeholder='Buscar ejercicio ...'
              />
            </FormGroup>
            <FormGroup label='Cantidad (min)' className='col-md-2'>
              <Input
                type='number'
                value={physicalActivityDuration}
                onChange={(e: any) => setPhysicalActivityDuration(e.target.value)}
                className='w-50'
              />
            </FormGroup>
          </div>
          {renderItems(searchPhysicalActivityResults, false, false, true, physicalActivityDuration)}
        </CardTabItem>
      </Card>
    </div>
  );
};

export default NutritionalMenuSelector;
import { RestServiceConnection } from '../restServiceConnection';

const FORM_ENDPOINT = '/forms';

export class FormService extends RestServiceConnection {

    listForms = async (formType: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/list',
            data: {
                formType: formType
            }
        }, true);
        return this;
    }

    listFormFills = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/list-fills',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    getForms = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    listFields = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/list-fields',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    getFillValues = async (formId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/get-fill',
            data: {
                formFill: formId
            }
        }, true);
        return this;
    }

    getFormById = async (formId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/get',
            data: {
                form: formId
            }
        }, true);
        return this;
    }

    getFieldById = async (formId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/get-field',
            data: {
                formField: formId
            }
        }, true);
        return this;
    }

    getFormTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/list-types',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    createForm = async (form: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/create',
            data: form
        }, true);
        return this;
    }

    createFormType = async (type: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/create-type',
            data: type
        }, true);
        return this;
    }

    createFormField = async (field: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/create-field',
            data: field
        }, true);
        return this;
    }

    editFill = async (fill: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/edit-fill',
            data: fill
        }, true);
        return this;
    }

    editFieldsValues = async (fields: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/edit-fill-value',
            data: fields
        }, true);
        return this;
    }

    editFields = async (fields: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/edit-field',
            data: fields
        }, true);
        return this;
    }

    editForm = async (form: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/edit',
            data: form
        }, true);
        return this;
    }

    deleteFormType = async (formType: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/delete-type',
            data: { formType }
        }, true);
        return this;
    }

    deleteForm = async (form: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/delete',
            data: { form }
        }, true);
        return this;
    }

    deleteFill = async (fillId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/delete-fill',
            data: { formFill: fillId }
        }, true);
        return this;
    }

    deleteField = async (field: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/delete-field',
            data: { formField: field }
        }, true);
        return this;
    }

    assignFormToPatient = async (patientId: string, formId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/create-fill',
            data: {
                client: patientId,
                form: formId,
            }
        }, true);
        return this;
    }

    getFieldGraph = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FORM_ENDPOINT + '/get-field-graph',
            data: { ...filters }
        }, true);
        return this;
    }

}
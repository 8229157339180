import { useCallback, FC } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { PatientApiResponse } from "../../type/patients-type";
import { PatientService } from "../../services/patients/patientService";
import useFetch from "../../hooks/useFetch";
import Spinner from "../bootstrap/Spinner";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../bootstrap/Modal";
import moment from "moment";
import { CardBody } from "../bootstrap/Card";
import AsyncImg from "../AsyncImg";
import PlaceholderImage from "../extras/PlaceholderImage";

interface PatientDataModalProps {
    isOpen: boolean;
    setIsOpen: any;
    patientId: string;
}

const PatientDataModal: FC<PatientDataModalProps> = ({ isOpen, setIsOpen, patientId }) => {

    const fetchPatient = useCallback(async () => {
        const patientService = new PatientService();
        const response = await patientService.getPatientById(patientId);
        return response.getResponseData() as PatientApiResponse;
    }, []);

    const [patientData, loadingPatient] = useFetch(fetchPatient);

    if (loadingPatient) return <div className="text-center"><Spinner size={'xl'} /></div>;

    return (
        patientData && (
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del paciente'>
                <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                    <ModalTitle id='patient-data'>Datos del paciente</ModalTitle>
                </ModalHeader>
                <ModalBody className='px-4'>
                    <div className="row">
                        <div className="col-4">
                            <CardBody>
                                <div className="row align-items-center">
                                    {patientData.profileImg
                                        ? <AsyncImg isBackground height="100px" width="100px" styles="rounded" id={patientData.profileImg.id} />
                                        : <PlaceholderImage width={100} height={100} className='mx-auto d-block img-fluid mb-3 rounded' />
                                    }
                                </div>
                            </CardBody>
                        </div>

                        <div className="col-8">
                            <div>
                                <div className="row d-flex justify-content-center">
                                    <div>Nombre: <b>{patientData.name} {patientData.lastName && patientData.lastName}</b></div>
                                    <div>Género: <b>{patientData.sex === 'H' ? 'Masculino' : 'Femenino'}</b></div>
                                    <div>Correo electrónico: <b>{patientData.email}</b></div>
                                    <div>Teléfono: <b>{patientData.telephone}</b></div>
                                    <div>Provincia: <b>{patientData.province}</b></div>
                                    <div>Dirección: <b>{patientData.address}</b></div>
                                    <div>Fecha de nacimiento: <b>{moment(patientData.birthdayDate?.date).format('DD/MM/YYYY')}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    )
}

export default PatientDataModal;
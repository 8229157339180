import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { CustomTable } from "../../../components/table/CustomTable";
import { useNavigate } from "react-router-dom";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import useFilters from "../../../hooks/useFilters";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { menusMenu } from "../../../menu";
import Input from "../../../components/bootstrap/forms/Input";
import Icon from "../../../components/icon/Icon";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Spinner from "../../../components/bootstrap/Spinner";
import { debounce } from "lodash";
import SvgCustomCheckMark from "../../../components/icon/svg-icons/CustomCheckMark";
import "./../../../styles/svg-styles.scss";
import { ActivitiesService } from "../../../services/activities/activitiesService";
import AsyncImg from "../../../components/AsyncImg";
import PlaceholderImage from "../../../components/extras/PlaceholderImage";
import { el } from "date-fns/locale";

interface ITipsFilters {
    client?: any;
}

const tipsFilters: ITipsFilters = {
    client: null,
};

let initialFormikValues = {
    names: [],
    descriptions: [],
    calories: [],
    times: []
};

const ActivitiesListGroup = () => {

    // STATES

    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [touchedFields, setTouchedFields] = useState<any>({});
    const [inlineRowsData, setInlineRowsData] = useState<any[]>([]);
    const [updating, setUpdating] = useState(0);
    const [selectedImages, setSelectedImages] = useState<{[key: string]: string}>({});

    // HOOKS

    const navigate = useNavigate();
    const activitiesService = new ActivitiesService();
    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(tipsFilters);

    // FETCH DATA

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE OBTENER LOS DATOS DE LOS EJERCICIOS FÍSICOS
     * @EN FUNCTIONALITY RESPONSIBLE FOR GETTING PHYSICAL ACTIVITIES DATA
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const [activities, loadingActivities, activitiesError, refetchActivities] = useFetch(useCallback(async () => {
        const response = (await activitiesService.listActivities(filters));
        return response.getResponseData();
    }, [filters]));
    //----------------------------------------------------------------------------------------------------------------------------

    // VALIDATION SCHEMA

    const validationSchema = Yup.object({
        names: Yup.object().shape(
            activities?.physical_activities?.reduce((acc: any, element: any) => {
                acc[element.id] = Yup.string()
                    .required('El nombre es obligatorio')
                    .min(3, 'Debe tener al menos 3 caracteres');
                return acc;
            }, {})
        ),
    });

    // FORMIK

    const formik = useFormik({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        onSubmit: (values) => console.log(values),
        validateOnChange: true,
        validateOnBlur: true,
    });

    // FUNCTIONS

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE AGREGAR UNA FILA
     * @EN FUNCTIONALITY RESPONSIBLE FOR ADDING A ROW
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const addRow = () => {
        setInlineRowsData(prevData => {
            return [
                ...prevData,
                {
                    name: '',
                    img: null,
                    inlineRow: true,
                }
            ];
        });
    }
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE REMOVER UNA FILA
     * @EN FUNCTIONALITY RESPONSIBLE FOR REMOVING A ROW
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const removeRow = (index: number) => {
        setInlineRowsData([]);
        setTouchedFields({});
    }
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE REALIZAR UNA LLAMADA DEBOUNCE
     * @EN FUNCTIONALITY RESPONSIBLE FOR MAKING A DEBOUNCE CALL
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const debouncedCall = debounce(() => {
        if (!formik.dirty || formik.initialValues?.names?.length <= 0) return;
        if (!formik.isValid || ((formik.errors?.names && formik.errors?.names?.length >= 1))) {
            setUpdating(2);
            return toast.error('Hay errores en el formulario');
        }

        let rowsUpdated = 0;
        activities?.physical_activities.forEach((group: any) => {
            if (formik.values.names?.[group.id] !== formik.initialValues.names?.[group.id]) {
                _handleUpdate(group.id);
                rowsUpdated++;
            }
        });
        setUpdating(0);
    }, 2500);
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE ELIMINAR UN EJERCICIO FÍSICO
     * @EN FUNCTIONALITY RESPONSIBLE FOR DELETING A PHYSICAL ACTIVITY
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const _handleDelete = async (id: string) => {
        try {
            const response = (await activitiesService.deleteActivity(id)).getResponseData();
            if (response.success) {
                refetchActivities();
                setTimeout(() => {
                    toast.success('Ejercicio físico eliminado correctamente');
                }, 100);
            } else {
                setTimeout(() => {
                    toast.error('Error al eliminar el ejercicio físico');
                }, 100);
            }
        } catch (error: any) {
            toast.error('Error al eliminar el ejercicio físico');
        }
    };
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE MANEJAR LA SUBIDA DE IMÁGENES
     * @EN FUNCTIONALITY RESPONSIBLE FOR HANDLING IMAGE UPLOAD
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>, id: string , inlineRow : boolean = false) => {
        const file = event.target.files?.[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImages(prevImages => ({
                ...prevImages,
                [id]: reader.result as string
            }));
        };
        reader.readAsDataURL(file);

        if (inlineRow) {
            console.log('inlineRow', file);
            setInlineRowsData(prevData => {
                return prevData.map((row: any) => {
                    if (row.id === id) {
                        return {
                            ...row,
                            img: file ? file : null
                        }
                    }
                    return row;
                });
            });
            return;
        }
    
        try {
            const activitiesService = new ActivitiesService();
            const response = await activitiesService.insertActivityImg(id, file);
            const responseData = response.getResponseData();
    
            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Imagen actualizada');
                }, 100);
            } else {
                throw new Error('La actualización de la imagen no fue exitosa');
            }
        } catch (error: unknown) {
            console.error('Error al subir la imagen:', error);
            toast.error(error instanceof Error ? error.message : "Formato de imagen incorrecto");
        }
    };
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE MANEJAR EL CAMBIO EN EL CAMPO DE BÚSQUEDA
     * @EN FUNCTIONALITY RESPONSIBLE FOR HANDLING THE CHANGE IN THE SEARCH FIELD
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE MANEJAR EL CAMBIO EN LA ARRAY DE EJERCICIOS FÍSICOS EXTERNOS A LA TABLA A CREAR
     * @EN FUNCTIONALITY RESPONSIBLE FOR HANDLING THE CHANGE IN THE ARRAY OF PHYSICAL ACTIVITIES EXTERNAL TO THE TABLE TO CREATE
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const handleInlineRowChange = (e: any) => {
        const { name, value } = e.target;
        setInlineRowsData(prevData => [{
            ...prevData[0],
            [name]: value
        }]);
        setTouchedFields((prev : any) => ({ ...prev, [e.target.name]: true }));
    };
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE VALIDAR UNA FILA DE LA ARRAY DE EJERCICIOS FÍSICOS EXTERNOS A LA TABLA
     * @EN FUNCTIONALITY RESPONSIBLE FOR VALIDATING A ROW OF THE ARRAY OF PHYSICAL ACTIVITIES EXTERNAL TO THE TABLE
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const validateInlineRow = (row : any) => {
        const errors : any = {};
        if (!row.name || row.name == '' ||row.name == null || row.name == undefined ) errors.name = 'El nombre es obligatorio';
        if (row.name.length < 3) errors.name = 'Debe tener al menos 3 caracteres';
        return errors;
    };
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE ACTUALIZAR UN EJERCICIO FÍSICO
     * @EN FUNCTIONALITY RESPONSIBLE FOR UPDATING A PHYSICAL ACTIVITY
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const _handleUpdate = async (activityId: any) => {

        let editActivityValues = {
            physicalActivity: activityId,
            name: formik.values?.names?.[activityId],
        }

        try {
            const response = await activitiesService.editActivity(editActivityValues);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(menusMenu.menus.subMenu.activities.path, { replace: true });
            } else {
                toast.error(responseData.message || "Error al editar el ejercicio físico");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            refetchActivities();
        }
    };
    //----------------------------------------------------------------------------------------------------------------------------

    //----------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES FUNCIONALIDAD ENCARGADA DE CREAR UN EJERCICIO FÍSICO
     * @EN FUNCTIONALITY RESPONSIBLE FOR CREATING A PHYSICAL ACTIVITY
     */
    //----------------------------------------------------------------------------------------------------------------------------
    const handleCreateActivity = async () => {

        setTouchedFields({ name: true });

        if (validateInlineRow(inlineRowsData[0]) && Object.keys(validateInlineRow(inlineRowsData[0])).length > 0) {
          return;
        }

        let data = {
            name: inlineRowsData[0].name,
            img: inlineRowsData[0].img
        };

        if (data.img !== null) {
            const reader = new FileReader();
            reader.onload = () => {
                data.img = reader.result;
            }
            reader.readAsDataURL(data.img);
        }

        setLoading(true);

        try {
            const response = await (await (new ActivitiesService()).createActivityFormData(data)).getResponseData();

            if (response.success) {
                setTimeout(() => {
                    toast.success('Ejercicio físico creado correctamente');
                }, 100);
                window.location.reload();
                setInlineRowsData([]);
                setTouchedFields({});
            } else {
                toast.error(response.message || 'Error al crear el ejercicio físico');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear el ejercicio físico');
        } finally {
            setLoading(false);
        }
    }
    //----------------------------------------------------------------------------------------------------------------------------

    // USE EFFECTS

    useEffect(() => {
        setIsMounted(false);
        initialFormikValues.names = activities?.physical_activities.reduce((acc: any, activity: any) => {
            acc[activity.id] = activity.name ? activity.name.trim() : '';
            return acc;
        }, {});
        formik.resetForm();
        setIsMounted(true);
    }, [activities]);

    useEffect(() => {
        if (formik.dirty || formik.initialValues?.names?.length <= 0) {
            setUpdating(1);
            debouncedCall();
        }
        return () => {
            debouncedCall.cancel();
            setUpdating(0);
        };
    }, [formik.isValid, formik.values]);

    // RENDER 

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Ejercicios Físicos</CardTitle>
                    <SubheaderSeparator />

                    {formik.values !== initialFormikValues && (
                        <Button
                            title="Descartar cambios"
                            color="danger"
                            isLight
                            onClick={() => {
                                handleConfirmationAlert({
                                    title: '¿Estás seguro?',
                                    text: 'Se descartarán todos los cambios realizados',
                                    icon: 'warning',
                                    onConfirm: async () => {
                                        formik.resetForm();
                                    }
                                })
                            }}
                        >
                            Descartar cambios
                        </Button>
                    )}


                    <div className="mx-2 d-flex flex-row justify-content-center align-content-center gap-2">
                        {updating === 1 ? (
                            <>
                                <Spinner color={'dark'} />
                                <h6 className="m-auto fw-bold align-middle">Actualizando...</h6>
                            </>) : updating === 0 ? (
                                <>
                                    <SvgCustomCheckMark />
                                    <h6 className="m-auto fw-bold align-middle">Guardado</h6>
                                </>
                            ) : (
                                <h6 className="m-auto fw-bold align-middle">Error en el formulario</h6>
                            )
                        }
                    </div>

                </SubHeaderLeft>
                <SubHeaderRight>
                    <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='secondary' />
                    </label>

                    <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <form onSubmit={formik.handleSubmit} autoComplete="off">
                            { !isMounted ? (
                                <div className="text-center">
                                    <Spinner color={'primary'} />
                                </div>
                            ) : (
                                <CustomTable
                                    title="Ejercicios Físicos"
                                    data={activities ? activities?.physical_activities : null}
                                    pagination
                                    addInLineRow
                                    paginationData={{
                                        pageSize: filters.limit,
                                        currentPage: filters.page,
                                        pageCount: activities ? activities.lastPage : 1,
                                        handlePagination: (page: any) => {
                                            updatePage({ selected: page.selected + 1 });
                                        },
                                        handlePerPage: updatePageSize,
                                    }}
                                    className={"table-striped table-hover"}
                                    inlineRows={{
                                        data: inlineRowsData,
                                        addSingleRowDataBase: handleCreateActivity,
                                        addRow: addRow,
                                        removeRow: removeRow,
                                    }}
                                    columns={[
                                        {
                                            name: "Nombre",
                                            keyValue: "name",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            render: (element: any) => {

                                                const hasErrors = formik.errors.names?.[element.id]; // HAS ERROR IN FORMIK
                                                console.log('element', element);
                                                console.log('formik.values.names', formik.values.names);
                                                console.log('element', formik.values.names?.[element.id]);

                                                //---------------------------------------------------------------
                                                /**
                                                 * @ES RENDERIZACIÓN DE LA FILA DE EJERCICIOS FÍSICOS SEGÚN SI ESTÁ PERTENECIENTE A LA TABLA O A LAS FILAS POR AGREGAR
                                                 * @EN RENDERING OF THE PHYSICAL ACTIVITIES ROW ACCORDING TO WHETHER IT BELONGS TO THE TABLE OR TO THE ROWS TO BE ADDED
                                                 */
                                                // --------------------------------------------------------------
                                                return (<>
                                                    {element.inlineRow ? ( 
                                                        <>
                                                            <Input
                                                                name="name"
                                                                value={element.name}
                                                                onChange={handleInlineRowChange}
                                                                onBlur={() => setTouchedFields((prev : any) => ({ ...prev, name: true }))}
                                                                isValid={!touchedFields.name || !validateInlineRow(element).name}
                                                            />
                                                            {touchedFields.name && validateInlineRow(element).name && (
                                                                <div className="text-danger p-1">
                                                                    {validateInlineRow(element).name}
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div key={element.id + formik.errors.names?.length}>
                                                            <Input
                                                                type="text"
                                                                name={`names.${element.id}`}
                                                                value={formik.values.names?.[element.id] || ''}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                placeholder="Nombre del ejercicio..."
                                                                className={hasErrors ? "form-control is-invalid" : "form-control"}
                                                                style={{ minWidth: '300px' }}
                                                            />
                                                            {formik.errors.names?.[element.id] && (
                                                                <div className="text-danger p-1">
                                                                    {formik.errors.names[element.id]}
                                                                </div>
                                                            )}
                                                        </div>
                                                )}</>)
                                            },
                                        },
                                        {
                                            name: "Imagen",
                                            keyValue: "img",
                                            render: (element: any) => {

                                                return ( <div key = { element.id } onClick={() => { document.getElementById(`file${element.id}`)?.click() }}>
                                                    {selectedImages[element.id] 
                                                        ? <img
                                                            width={50} height={50}
                                                            src={selectedImages[element.id]}
                                                            className='mx-auto d-block img-fluid mb-3 rounded'
                                                        />
                                                        : element.img
                                                            ? <AsyncImg isBackground height="50px" width="50px" styles="mx-auto d-block img-fluid rounded" id={element.img.id} />
                                                            : <PlaceholderImage width={50} height={50} className='mx-auto d-block img-fluid mb-3 rounded' />
                                                    }
                                                    <input
                                                        type="file"
                                                        id={`file${element.id}`}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            if (element.inlineRow) {
                                                                handleImageUpload(e, element.id, true);
                                                            } else {
                                                                handleImageUpload(e, element.id)
                                                            }
                                                        }}
                                                    />
                                                    </div>
                                                )
                                            }

                                        },
                                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                                    ]}
                                    actions={[
                                        {
                                            title: "Eliminar",
                                            icon: "Delete",
                                            buttonType: 'icon',
                                            additionalClasses: 'text-danger',
                                            description: "Eliminar grupo",
                                            click: (item: any) => {
                                                handleConfirmationAlert({
                                                    title: "Eliminar Ejercicio Físico",
                                                    text: "¿Está seguro que desea eliminar el ejercicio físico?",
                                                    icon: "warning",
                                                    onConfirm: () => {
                                                        _handleDelete(item.id);
                                                    }
                                                })
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </form>
                    </CardBody>
                </Card>
            </Page>
        </>
    );
}

export default ActivitiesListGroup;
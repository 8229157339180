import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle, } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { AlimentsApiResponse } from "../../../type/aliments-type";
import ErrorMessage from "../../../components/ErrorMessage";
import { toast } from "react-toastify";
import AlimentsFilters from "./foods-options/FoodsFilters";
import AsyncImg from "../../../components/AsyncImg";
import { CustomTable } from "../../../components/table/CustomTable";
import Allergens from "../../../components/Allergens";
import { FixNumber } from "../../../utils/FixNumber";
import useNutrients from "../../../hooks/api-calls/useNutrients";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import { FoodService } from "../../../services/foods/foodService";

export interface IFoodFilters {
  group_food?: string[];
  allergens?: string[];
  season: string;
  seasonend: string;
}

const foodFilters: IFoodFilters = {
  group_food: [],
  allergens: [],
  season: 'enero',
  seasonend: 'diciembre',
};

const FoodsList = () => {

  const { handleErrors } = useHandleErrors();
  const navigate = useNavigate();
  const { getAllNutrientsData } = useNutrients();
  const fileInputRef = useRef<HTMLInputElement>(null);  // asocia el input file a un ref para poder hacerlo con un boton

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(foodFilters, [], 1, 50);

  const [isUploading, setIsUploading] = useState(false);
  const [nutrientSelected, setNutrientSelected] = useState<string>('');

  const [foodData, loadingFood, foodError, refetchFood] = useFetch(useCallback(async () => {
    const response = await (new FoodService()).getAliments(filters);
    return response.getResponseData() as AlimentsApiResponse;
  }, [filters]));

  // abrir el input file al hacer click en el botón importar
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await (await (new FoodService()).deleteAliment(id)).getResponseData();
      if (response.success) {
        refetchFood();
        setTimeout(() => {
          toast.success('Alimento eliminado correctamente');
        }, 100);
      } else {
        handleErrors(response);
      }
    } catch (error: any) {
      toast.error('Error al eliminar el alimento');
    }
  };

  // importar alimentos desde excel
  const handleImport = async (e: React.ChangeEvent<any>) => {
    try {
      setIsUploading(true);
      const selectedFile = e.target.files && e.target.files[0];

      const response = await (await (new FoodService()).importFoods(selectedFile)).getResponseData();
      if (response.success) {
        toast.success('Alimentos importados correctamente');
        refetchFood(); // actualiza la llamada a la api para cargar los nuevos alimentos importados
      } else {
        handleErrors(response);
      }
    } catch (error: any) {
      toast.error('Error al importar los alimentos');
    } finally {
      setIsUploading(false);
    }
  };

  // exportar alimentos a excel
  const handleExport = async () => {
    try {
      const response = await (new FoodService()).exportFoods(filters);
      if (response) {
        const fileData = response.getResponseData();
        const blob = new Blob([fileData]);
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'foods_list.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error('Error al exportar los alimentos');
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (filters.filter_order) {
      filters.filter_order.forEach((item: any) => {
        setNutrientSelected(item.field);
      });
    }
  }, [filters]);

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Alimentos</CardTitle>
            <SubheaderSeparator />
            <Button color="storybook" icon="Add" isLight onClick={() => { navigate("create") }} />
            <Button color="light" isLight title="Importar alimentos" onClick={handleButtonClick}>Importar</Button>

            {isUploading && <Spinner color={"primary"} />}

            <Button color="light" isLight title="Exportar alimentos" onClick={handleExport}>Exportar</Button>

            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleImport} />
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <AlimentsFilters updateFilters={updateFilters} updateFilterOrder={updateFilterOrder} updatePageSize={updatePageSize} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <CardBody className="table-responsive" isScrollable={true}>
            <Fragment>
              {foodError && <ErrorMessage error={foodError} />}

              {foodData && foodData.data
                ? <CustomTable
                  key={nutrientSelected}
                  title="Alimentos"
                  data={foodData.data ? foodData.data : null}
                  pagination={true}
                  paginationData={{
                    pageSize: filters.limit,
                    currentPage: filters.page,
                    pageCount: foodData as AlimentsApiResponse ? foodData.lastPage : 1,
                    handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                    handlePerPage: updatePageSize,
                  }}
                  className={"table-striped table-hover"}
                  columns={[
                    {
                      name: "",
                      keyValue: "image",
                      render: (element: any) => {
                        return (
                          <div className="d-flex justify-content-center">
                            <div className='user-avatar'>
                              <AsyncImg id={element.foodImage ? element.foodImage.id : null} food />
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      name: "Nombre",
                      keyValue: "name",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      render: (element: any) => {
                        return (
                          <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${element.id}/edit`) }}>
                            {element.name}
                          </div>
                        )
                      },
                    },
                    {
                      name: "Grupo de alimentos",
                      keyValue: "foodGroup",
                      render: (element: any) => {
                        return (
                          <>
                            {element.foodGroup ? element.foodGroup.name : "-"}
                          </>
                        );
                      },
                    },
                    {
                      name: "Alérgenos",
                      keyValue: "allergens",
                      render: (element: any) => {
                        return (
                          <>{<Allergens recipe={element} /> || "-"}</>
                        )
                      }
                    },
                    {
                      name: "Calorías (kcal)",
                      keyValue: "energy",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      render: (element: any) => {
                        return <>{FixNumber(element.energy)}</>;
                      },
                    },
                    {
                      name: "Proteínas (g)",
                      keyValue: "proteins",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      render: (element: any) => {
                        return <>{FixNumber(element.proteins)}</>;
                      },
                    },
                    {
                      name: "Carbohidratos (g)",
                      keyValue: "carbohydrates",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      render: (element: any) => {
                        return <>{FixNumber(element.carbohydrates)}</>;
                      },
                    },
                    {
                      name: "Grasas (g)",
                      keyValue: "fat",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      render: (element: any) => {
                        return <>{FixNumber(element.fat)}</>;
                      },
                    },
                    {
                      name: getAllNutrientsData().find((item) => item.name === nutrientSelected)?.nombre || "Agua (g)",
                      keyValue: nutrientSelected || "water",
                      sortable: true,
                      sortColumn: updateFilterOrder,
                      render: (element: any) => {
                        return (<>{nutrientSelected ? FixNumber(element[nutrientSelected]) : element.water}</>);
                      },
                    },
                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                  ]}
                  actions={[
                    {
                      title: "Editar",
                      icon: "Edit",
                      buttonType: 'icon',
                      additionalClasses: 'text-primary',
                      description: "Editar alimento",
                      click: (item: any) => { navigate(`${item.id}/edit`) },
                    },
                    {
                      title: "Eliminar",
                      icon: "Delete",
                      buttonType: 'icon',
                      additionalClasses: 'text-danger',
                      description: "Eliminar alimento",
                      click: (item: any) => {
                        handleConfirmationAlert({
                          title: 'Eliminar alimento',
                          text: 'Se eliminará permanentemente',
                          onConfirm: () => handleDelete(item.id),
                          icon: 'warning'
                        })
                      }
                    }
                  ]}
                />
                : !foodError && <div className="text-center"><Spinner /></div>
              }
            </Fragment>
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
};

export default FoodsList;
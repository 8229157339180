import { FC } from "react";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import { AddStepToProcess } from "./AddStepToProcess";

interface IProcessProps {
    formik: any;
    processData: string[];
    setProcessData: any;
}

export const Process: FC<IProcessProps> = ({ formik, processData, setProcessData }) => {

    return (
        <Card stretch style={{ minHeight: '85vh' }}>
            <CardHeader className='rounded-1 mb-0'>
                <CardLabel icon='MenuBook' iconColor='secondary'>
                    <CardTitle>Proceso</CardTitle>
                </CardLabel>
            </CardHeader>

            <CardBody isScrollable>
                {processData && processData.length > 0 && (
                    processData?.map((process: any, index: number) => {
                        return (
                            <div className="row mb-4" key={index}>
                                <span className="col-md-12 mb-2">Paso {index + 1}:</span>

                                <div className="col-md-1 d-flex flex-column justify-content-center align-content-center">
                                    {
                                        index !== 0 && (
                                            <Button
                                                title="Subir paso"
                                                onClick={() => {
                                                    if (index !== 0) {
                                                        const newProcessData = [...processData];
                                                        const aux = newProcessData[index - 1];
                                                        newProcessData[index - 1] = newProcessData[index];
                                                        newProcessData[index] = aux;
                                                        setProcessData(newProcessData);
                                                    }
                                                }}
                                                icon="KeyboardArrowUp"
                                            />
                                        )
                                    }

                                    {
                                        index !== processData.length - 1 && (
                                            <Button
                                                title="Bajar paso"
                                                onClick={() => {
                                                    if (index !== processData.length - 1) {
                                                        const newProcessData = [...processData];
                                                        const aux = newProcessData[index + 1];
                                                        newProcessData[index + 1] = newProcessData[index];
                                                        newProcessData[index] = aux;
                                                        setProcessData(newProcessData);
                                                    }
                                                }}
                                                icon="KeyboardArrowDown"
                                            />
                                        )
                                    }
                                </div>

                                <div className="col-md-10">
                                    <textarea
                                        id={'step' + index}
                                        onChange={(e: any) => {
                                            const newProcessData = [...processData];
                                            newProcessData[index] = e.target.value;
                                            setProcessData(newProcessData);
                                        }}
                                        //onKeyDown={handleTextareaKeyPress}
                                        rows={1}
                                        value={process}
                                        style={{
                                            borderRadius: '10px',
                                            boxShadow: '0 0 0 3px rgb(245, 156, 57, 0.3)',
                                            backgroundColor: '#f8f9fa',
                                            width: '100%',
                                            minHeight: '90px',
                                            padding: '15px',
                                            border: '0'
                                        }}
                                    />
                                </div>

                                <div className="col-md-1 d-flex align-items-center">
                                    <Button
                                        title="Eliminar paso"
                                        onClick={() => {
                                            handleConfirmationAlert({
                                                title: "Eliminar paso",
                                                text: "¿Está seguro que desea eliminar el paso?",
                                                icon: "warning",
                                                onConfirm: () => {
                                                    const newProcessData = processData.filter((process: any) => process !== processData[index]);
                                                    setProcessData(newProcessData);
                                                }
                                            })
                                        }}
                                        icon="Delete"
                                    />
                                </div>
                            </div>
                        )
                    })
                )}

                <AddStepToProcess formik={formik} processData={processData} setProcessData={setProcessData} />
            </CardBody>
        </Card>
    );
};
import React, { useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import { TipsGroupsService } from '../../../services/tips/groupsService';
import { toast } from 'react-toastify';
import Input from "../../../components/bootstrap/forms/Input";
import { useFormik } from 'formik';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import * as Yup from 'yup';


interface CreateGroupModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    refetchGroups: () => void;
}

const validateSchema = Yup.object().shape({
    name: Yup.string().required('El nombre del grupo es requerido'),
    description: Yup.string().notRequired(),
});

const CreateGroupModal: React.FC<CreateGroupModalProps> = ({ isOpen, setIsOpen, refetchGroups }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: validateSchema,
        onSubmit: (values) => {
            handleCreateTipGroup(values);
        },
        validateOnChange: true,
        validateOnBlur: true,
    });

    const handleCreateTipGroup = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new TipsGroupsService()).createGroup(values)).getResponseData();

            if (response.success) {
                setTimeout(() => {
                    toast.success('Grupo creado correctamente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al crear el grupo');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear el grupo');
        } finally {
            setLoading(false);
            refetchGroups();
            setIsOpen(false);
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del grupo de consejos'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4 border-bottom'>
                <ModalTitle id='patient-data'>Crear un nuevo grupo de consejos</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4 border-bottom'>
                <form onSubmit={formik.handleSubmit} className='m-3' >
                    <FormGroup className='row gap-3'>
                        <Input
                            name='name'
                            type='text'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Nombre del grupo'
                        />
                        <>
                            {formik.touched.name && formik.errors && formik.errors.name && (
                                <div className='text-danger'>{formik.errors.name}</div>
                            )}
                        </>
                        <Textarea
                            name='description'
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Descripción del grupo'
                        />
                    </FormGroup>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button
                    color='primary'
                    onClick={formik.handleSubmit}
                    isDisable={loading || !formik.isValid}
                >
                    {loading ? <Spinner color={'light'} /> : 'Crear'}
                </Button>
                <Button
                    color='danger'
                    onClick={() => setIsOpen(false)}
                    isDisable={loading}
                >
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    )

};

export default CreateGroupModal;
import { FC, Fragment, useCallback } from "react";
import Card, { CardTabItem } from "../../../components/bootstrap/Card";
import { PatientService } from "../../../services/patients/patientService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { Label } from "../../../type/patients-type";
import PatientHasMenus from "./PatientHasMenus";
import FormsTab from "../../forms/patient-forms/FormsTab";
import ClientChat from "../../../components/chat/ClientChat";
import PatientHeader from "./PatientHeader";
import Notes from "./Notes";
import Labels from "./Labels";
import TipsTab from "./Tips";
import Documents from "../../../components/document/Documents";
import { useDispatch, useSelector } from "react-redux";
import { changeClientActiveTab } from "../../../redux/clientSlice";
import './../../../styles/styles.scss';

interface PersonalInfoCardProps {
    company: string;
    name: string;
    last_name: string | null;
    email: string;
    phone: string | null;
    address: string | null;
    profileImg: string | null;
    birthday: string | null;
}

const CardTab: FC<PersonalInfoCardProps> = ({ name, last_name, email, phone, address, profileImg, company, birthday }) => {

    const { id = '' } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const activeTab = useSelector((state: any) => state.client_profile.activeTab);

    const [labels] = useFetch(useCallback(async () => {
        const response = await (new PatientService()).getActivePatientTags(company);
        return response.getResponseData();
    }, []));

    const [patientTags, fetchingTags, tagsError, refetchPatientTags] = useFetch(useCallback(async () => {
        const response = await (new PatientService()).getPatientTags(id);
        return response.getResponseData();
    }, [labels]));

    const handleSaveTags = async (tags: string[]) => {
        const oldTags = patientTags?.map((tag: Label) => tag.name);
        const newTags = [...(patientTags || []).map((tag: Label) => tag.name), ...tags.filter((tag) => !oldTags.includes(tag))];
        const removedTags = oldTags.filter((tag: any) => !tags.includes(tag));
        const finalTags = newTags.filter((tag) => !removedTags.includes(tag));
        try {
            const response = await (new PatientService()).editPatientTags(id, finalTags, company);
            const responseData = response.getResponseData();
            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Etiquetas actualizadas');
                }, 100);
                refetchPatientTags();
            } else {
                toast.error(responseData.data.errors[0].message || 'Error al actualizar las etiquetas');
            }
        } catch (error: any) {
            toast.error(error || "Error al actualizar las etiquetas");
        }
    };

    const getLabelsOptions = () => {
        // Guardar los nombres de todas las etiquetas
        if (labels && patientTags) {
            const allLabelsNames = labels?.map((label: Label) => label.name);

            return allLabelsNames?.map((tagName: string) => {
                // Comprobar si la etiqueta está seleccionada
                const isTagSelected = patientTags?.findIndex((tag: Label) => tag.name === tagName) !== -1;

                return {
                    value: tagName,
                    label: tagName,
                    isSelected: isTagSelected,
                };
            });
        }
    };

    const showPatientTags = useCallback(patientTags?.map((tag: any) => (
        <span
            key={tag.name}
            className="badge text-center w-auto mb-3 me-4 p-2 ps-3 pe-3 position-relative"
            style={
                {
                    backgroundColor: tag.color || '#d7d7d7',
                    color: tag.color == '#3c3c3b' || tag.color == '#b81e53' || tag.color == '#e5133d' ? 'white' : 'black',
                }}>
            {tag.name}
        </span>
    )), [patientTags]);

    const dispatchSelectedTab = (tabId: string) => {
        dispatch(changeClientActiveTab(tabId));
    };

    return (
        <Fragment>
            <div className="row sticky-top w-100">
                <PatientHeader
                    profileImg={profileImg}
                    name={name} last_name={last_name}
                    email={email} phone={phone}
                    address={address}
                    birthday={birthday}
                    patientTags={patientTags}
                    showPatientTags={showPatientTags}
                />
            </div>

            <div className="row w-100">
                <div className="row">
                    <Card hasTab>
                        <CardTabItem id='forms' title='Formularios' icon='DynamicForm' onSelected={dispatchSelectedTab} isActive={activeTab === "forms"}>
                            <FormsTab />
                        </CardTabItem>

                        <CardTabItem id='menu' title='Menús' icon='Menu' onSelected={dispatchSelectedTab} isActive={activeTab === "menu"} permission={{ action: 'view-all-client-menus', group: 'clients' }}>
                            <PatientHasMenus id={id} />
                        </ CardTabItem>

                        <CardTabItem id='tips' title='Consejos' icon='TipsAndUpdates' onSelected={dispatchSelectedTab} isActive={activeTab === "tips"} >
                            <TipsTab />
                        </CardTabItem>

                        <CardTabItem id='documents' title='Documentos' icon='Article' onSelected={dispatchSelectedTab} isActive={activeTab === "documents"}>
                            <Documents id={id} isPatient />
                        </CardTabItem>

                        <CardTabItem id='tags' title='Etiquetas' icon='Tag' onSelected={dispatchSelectedTab} isActive={activeTab === "tags"}>
                            <Labels
                                labels={labels}
                                getLabelsOptions={getLabelsOptions}
                                handleSaveTags={handleSaveTags}
                            />
                        </CardTabItem>

                        <CardTabItem id='notes' title='Notas' icon='Note' onSelected={dispatchSelectedTab} isActive={activeTab === "notes"}>
                            <Notes />
                        </CardTabItem>

                        <CardTabItem id='chat' title='Chat' icon='Chat' onSelected={dispatchSelectedTab} isActive={activeTab === "chat"}>
                            <ClientChat client={id} />
                        </CardTabItem>
                    </Card>
                </div>
            </div>
        </Fragment>
    )
}

export default CardTab;
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { CompanyService } from "../../../services/companies/organizationService";
import { toast } from "react-toastify";
import { calendarMenu } from "../../../menu";
import ErrorMessage from "../../../components/ErrorMessage";
import GeneralConfigForm from "../GeneralConfigForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const EditConfig: FC = () => {

    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.auth.user);

    const companyService = new CompanyService();

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await companyService.getCompanyById(token?.company as string);
        return response.getResponseData();
    }, []));

    const handleUpdate = async (values: any) => {
        values.company = token?.company as string;

        try {
            const response = (await companyService.editConfig(values)).getResponseData();
            if (response.success) {
                navigate(calendarMenu.calendar.path, { replace: true });
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message || "Error al editar la configuración general");
        }
    };

    const getContent = () => {
        if (loading) return <Spinner />;

        if (error) return <ErrorMessage message={error} />;

        if (data !== null) {
            const configData = {
                timeSlot: data.timeSlot !== null ? data.timeSlot : 15,
                textField: data.textField !== null ? data.textField : "",
                foodRationConfiguration: data.foodRationConfiguration !== null ? data.foodRationConfiguration : []
            };

            return <GeneralConfigForm isLoading={false} submit={handleUpdate} configData={configData} token={token} />;
        }
    };

    return (
        <>{getContent()}</>
    );
};

export default EditConfig;
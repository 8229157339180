import React from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import useHandleErrors from '../../../../utils/hooks/useHandleErrors';
import { MenuService } from '../../../../services/menus/menuService';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import SearchableSelect from '../../../../components/select/SearchableSelect';
import Input from '../../../../components/bootstrap/forms/Input';
import Button from '../../../../components/bootstrap/Button';
import useClients from '../../../../hooks/api-calls/useClients';

interface AssignTemplateModalProps {
    isOpen: boolean;
    setIsOpen: any;
    menuId: string;
    refetch: () => void;
}

interface AssignTemplateToPatientForm {
    menu: string;
    client: string;
    name: string;
}

const schema = yup.object({
    client: yup.string().required('El campo es obligatorio'),
    name: yup.string().required('El campo es obligatorio').max(255, 'El campo no puede tener más de 255 caracteres'),
});

const AssignTemplateModal: React.FC<AssignTemplateModalProps> = ({ isOpen, setIsOpen, menuId, refetch }) => {

    const { handleErrors } = useHandleErrors();
    const { getClientsList } = useClients();

    const [isLoading, setIsLoading] = React.useState(false);

    const submit = async (values: AssignTemplateToPatientForm) => {
        setIsLoading(true);
        try {
            const response = await (await new MenuService().assignTemplate(values)).getResponseData();
            if (response.success) {
                refetch && refetch();
                setIsOpen && setIsOpen(false);
                toast.success('Plantilla asignada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al asignar la plantilla');
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik<AssignTemplateToPatientForm>({
        initialValues: {
            menu: menuId,
            client: '',
            name: '',
        },
        validationSchema: schema,
        onSubmit: values => submit(values)
    });

    const verifyClass = (inputFieldID: keyof AssignTemplateToPatientForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''; }

    const showErrors = (inputFieldID: keyof AssignTemplateToPatientForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='assign-template' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='patient-data'>Asignar plantilla al paciente</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-5 pt-0'>
                <form onSubmit={formik.handleSubmit}>
                    <FormGroup label='Paciente' className='mb-3' requiredInputLabel>
                        <SearchableSelect
                            isSearchable
                            isClearable
                            onChange={(e: any) => formik.setFieldValue('client', e?.value)}

                            name='client-select'
                            placeholder='paciente '
                            options={getClientsList()}
                            classname={verifyClass('client')}
                        />
                        {showErrors('client')}
                    </FormGroup>

                    <FormGroup label='Nombre' className='mb-3' requiredInputLabel>
                        <Input
                            id='name'
                            name='name'
                            type='text'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            className={`form-control ${verifyClass('name')}`}
                        />
                        {showErrors('name')}
                    </FormGroup>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={formik.submitForm} isDisable={isLoading}>Asignar</Button>
            </ModalFooter>
        </Modal>
    );
};

export default AssignTemplateModal;
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import { toast } from "react-toastify";
import FormForm from "./FormForm";
import { FormService } from "../../../services/forms/formService";

const FormCreate: React.FC = () => {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreateForm = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new FormService()).createForm(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success('Formulario creado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al crear el formulario');
        } finally {
            setLoading(false);
        }
    };

    return <FormForm submit={handleCreateForm} isLoading={loading} />;
}

export default FormCreate;
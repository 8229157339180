import { createSlice } from "@reduxjs/toolkit";
import { TechnicalSheetForm } from "../pages/recipes/components/TechnicalSheetModal";


export interface recipeState {
    sheet_config: TechnicalSheetForm;
}

export const initialState: recipeState = {
    sheet_config: {
        foodRationConfiguration: [],
        name: true,
        foodGroup: true,
        ingredients_rations: true,
        allergens: true,
        techniques: true,
        elaboration: true,
        presentation: true,
        storageConditions: true,
        processingTime: true,
        nutritionalInformation: true,
        observations: '',
    },
};

const recipeSlice = createSlice({
    name: 'recipe',
    initialState,
    reducers: {
        changeSheetConfig: (state, action) => {
            state.sheet_config = action.payload;
        },
    },
});

export const { changeSheetConfig } = recipeSlice.actions;

export default recipeSlice.reducer;
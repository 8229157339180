import { Fragment, useCallback, useRef, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import useFilters from "../../../hooks/useFilters";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import { ActivitiesApiResponse } from "../../../type/activities-type";
import { CustomTable } from "../../../components/table/CustomTable";
import StatusDropdown from "../../../components/StatusDropdown";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { DailyIntakesService } from "../../../services/daily-intakes/dailyIntakesService";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import DailyIntakesFilters from "./IntakesFilters";
import Spinner from "../../../components/bootstrap/Spinner";
import moment from "moment";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

const DailyIntakesList = () => {

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ active: 3, nutrients: [], age: null, gender: null, physiological_state: null });

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await (new DailyIntakesService()).listIntakes(filters);
        return response.getResponseData();
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await (new DailyIntakesService()).toggleIntakeStatus(id)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                refetch();
                toast.success(status ? 'Se ha activado la ingesta' : 'Se ha desactivado la ingesta');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                handleErrors(response);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error('Error al cambiar el estado de la ingesta');
        }
    };

    const handleImport = async (e: React.ChangeEvent<any>) => {
        try {
            setIsUploading(true);
            const selectedFile = e.target.files && e.target.files[0];

            const response = await (await (new DailyIntakesService()).importIntakes(selectedFile)).getResponseData();
            if (response.success) {
                toast.success('Datos importados correctamente');
                refetch();
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al importar los datos');
        } finally {
            setIsUploading(false);
        }
    };

    const handleExport = async () => {
        try {
            const response = await (new DailyIntakesService()).exportIntakes({ ...filters, limit: 999999999 });
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'daily_intakes_list.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al exportar los datos');
        }
    };

    const _handleDelete = async (id: string) => {
        try {
            const response = (await (new DailyIntakesService()).deleteIntake(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Ingesta eliminada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al eliminar la ingesta');
        }
    };

    if (error) return <ErrorMessage />;

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Ingestas Diarias</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="light"
                        isLight
                        title="Importar ingestas"
                        onClick={() => { fileInputRef.current?.click() }}
                    >
                        {isUploading ? <Spinner color={"primary"} /> : 'Importar'}
                    </Button>

                    <Button
                        color="light"
                        isLight
                        title="Exportar ingestas"
                        onClick={handleExport}
                    >
                        Exportar
                    </Button>

                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImport}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <DailyIntakesFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <CustomTable
                            title="Ingestas diarias"
                            data={data?.daily_intakes || null}
                            pagination={true}
                            paginationData={{
                                pageSize: filters.limit,
                                currentPage: filters.page,
                                pageCount: data as ActivitiesApiResponse ? data.lastPage : 1,
                                handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                                handlePerPage: updatePageSize,
                            }}
                            className={"table-striped table-hover"}
                            columns={[
                                {
                                    name: "Nutriente",
                                    keyValue: "nutrient",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (<div>{element?.nutrient}</div>)
                                    }
                                },
                                {
                                    name: "Cantidad",
                                    keyValue: "quantity",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (<div>{element?.quantity} {element?.measureUnit}</div>)
                                    }
                                },
                                {
                                    name: "Edad Desde",
                                    keyValue: "ageFrom",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (<div>{element?.ageFrom}</div>)
                                    },
                                },
                                {
                                    name: "Edad Hasta",
                                    keyValue: "ageTo",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (<div>{element?.ageTo}</div>)
                                    },
                                },
                                {
                                    name: "Género",
                                    keyValue: "gender",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (<div>{element?.gender}</div>)
                                    }
                                },
                                {
                                    name: "Estado fisiológico",
                                    keyValue: "physiologicalState",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (<div>{element?.physiologicalState || 'No'}</div>)
                                    }
                                },
                                {
                                    name: "Incremento en kcal",
                                    keyValue: "kcalIncrement",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (<div>{element?.kcalIncrement} kcal</div>)
                                    }
                                },
                                {
                                    name: "Estado",
                                    keyValue: "active",
                                    className: "text-center",
                                    render: (element: any) => {
                                        return (
                                            <div className={'text-center'}>
                                                <StatusDropdown
                                                    disabled={changingStatus.includes(element.id)}
                                                    itemId={element.id}
                                                    status={element.active}
                                                    change={toggleStatus}
                                                />
                                            </div>
                                        );
                                    },
                                },
                                {
                                    name: "Creación",
                                    keyValue: "createdAt",
                                    sortable: true,
                                    sortColumn: updateFilterOrder,
                                    render: (element: any) => {
                                        return (<div>{moment(element?.createdAt?.date).format('DD-MM-YY')}</div>)
                                    }
                                },
                                { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                            ]}
                            actions={[
                                {
                                    title: "Eliminar",
                                    icon: "Delete",
                                    buttonType: 'icon',
                                    additionalClasses: `text-danger ${userCan("delete_daily_intakes", "daily_intakes") ? "" : "d-none"}`,
                                    description: "Eliminar ingesta",
                                    click: (item: any) => {
                                        if (!userCan("delete_daily_intakes", "daily_intakes")) return;
                                        handleConfirmationAlert({
                                            title: "Eliminar ingesta",
                                            text: "¿Está seguro que desea eliminar la ingesta?",
                                            icon: "warning",
                                            onConfirm: () => _handleDelete(item.id)
                                        })
                                    },
                                },
                            ]}
                        />
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    )
}

export default DailyIntakesList;
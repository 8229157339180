import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ACTIVITIES_ENDPOINT = '/physical_activity';

export class ActivitiesService extends RestServiceConnection {

    listActivities = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/list',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getActivity = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/get',
            data: {
                physicalActivity: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createActivity = async (activity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/create',
            data: activity
        }, true) as AxiosResponse;
        return this;
    }

    createActivityFormData = async (activity: any) => {
        const formData = new FormData();
        formData.append('name', activity.name);
        if (activity.img) formData.append('img', activity.img);


        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/create',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        }, true) as AxiosResponse;
        return this;
    }

    editActivity = async (activity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/edit',
            data: activity
        }, true) as AxiosResponse;
        return this;
    }

    deleteActivity = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/delete',
            data: {
                physicalActivity: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    toggleActivityStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/toggle',
            data: {
                physicalActivity: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    insertActivityImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('physicalActivity', id);
        formData.append('img', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/insert-image',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        }, true);
        return this;
    }

    deleteActivityImg = async (physicalActivity: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/remove-image',
            data: { physicalActivity }
        }, true);
        return this;
    }

}
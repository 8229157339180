import React, { useCallback, useContext, useEffect, useState } from "react";
import { Appointment, AppointmentFormData } from "../../../type/appointment-type";
import useFilters, { FilterOptions, FilterOrders } from "../../../hooks/useFilters";
import useFetch from "../../../hooks/useFetch";
import { AppointmentService } from "../../../services/appointments/appointmentService";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { changeStorageDate, changeStorageViewMode } from "../../../redux/calendarSlice";
import moment from "moment";

type AppointmentCalendarContextData = {
    appointments?: Appointment[],
    refetchAppointments: () => void,
    viewMode: "month" | "week" | "work_week" | "day" | "agenda",
    changeViewMode: (view: "month" | "week" | "work_week" | "day" | "agenda") => void,
    currentDate: Date,
    setCalendarDate: (date: Date) => void,
    deleteAppointment: (id: string) => void,
    appointmentSelected: AppointmentFormData | null,
    selectAppointment: (appointment: AppointmentFormData | null) => void,
    updateFilters: (filters: any) => void,
    filters: FilterOptions,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    isLoading?: boolean,
    totalResults?: number,
    lastPage?: number,
    totalAmount?: number,
    clientsResponse: any,
    refetchClients: () => void,
    openPatientModal: boolean,
    setOpenPatientModal: (open: boolean) => void,
    newPatientSelected: { label: string, value: string },
    setNewPatientSelected: (newPatient: { label: string, value: string }) => void,
}

type AppointmentCalendarProviderProps = {
    children: React.ReactNode
    defaultFilters?: FilterOptions
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
}

const AppointmentCalendarContext: React.Context<AppointmentCalendarContextData> = React.createContext<AppointmentCalendarContextData>({
    appointments: [],
    refetchAppointments: () => { },
    viewMode: 'month',
    changeViewMode: (view: "month" | "week" | "work_week" | "day" | "agenda") => { },
    currentDate: new Date(),
    setCalendarDate: (date: Date) => { },
    deleteAppointment: (id: string) => { },
    appointmentSelected: null,
    selectAppointment: (appointment: AppointmentFormData | null) => { },
    updateFilters: (filters: any) => { },
    filters: {} as FilterOptions,
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    isLoading: false,
    totalResults: 0,
    lastPage: 1,
    totalAmount: 0,
    clientsResponse: null,
    refetchClients: () => { },
    openPatientModal: false,
    setOpenPatientModal: (open: boolean) => { },
    newPatientSelected: { label: '', value: '' },
    setNewPatientSelected: (newPatient: { label: string, value: string }) => { },
});

const AppointmentCalendarProvider: React.FC<AppointmentCalendarProviderProps> = ({
    children,
    defaultFilters = {} as FilterOptions,
    defaultOrders = [] as FilterOrders,
    defaultPage = 1,
    defaultPageSize = 500,
}) => {

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [appointmentSelected, setAppointmentSelected] = useState<AppointmentFormData | null>(null);
    const [totalPriceAmount, setTotalPriceAmount] = useState<number>(0);
    const [openPatientModal, setOpenPatientModal] = useState<boolean>(false);
    const [newPatientSelected, setNewPatientSelected] = useState<{ label: string, value: string }>({ label: '', value: '' });

    //  Redux connection
    const dispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.calendar.viewMode);
    const currentDate = useSelector((state: RootState) => moment(state.calendar.date).toDate());

    const { handleErrors } = useHandleErrors();

    // Events, this load when filters change
    const [appointmentsResponse, isLoading, errors, refetchAp] = useFetch(useCallback(async () => {
        const response = (await ((new AppointmentService()).getAppointments(filters))).getResponseData();
        if (!response.success) {
            handleErrors(response);
            return null;
        }
        setAppointments(response.data.appointments);
        return response
    }, [filters]));

    const [clientsResponse, loadingClients, clientsError, refetchCli] = useFetch(useCallback(async () => {
        const response = (await (new AppointmentService()).listClients({ limit: 100, filter_filters: { active: 1 } })).getResponseData();
        return response;
    }, []));

    const changeViewMode = (view: "month" | "week" | "work_week" | "day" | "agenda") => {
        dispatch(changeStorageViewMode(view));
    }

    const refetchAppointments = () => {
        refetchAp();
    }

    const refetchClients = () => {
        refetchCli();
    }

    const setCalendarDate = (date: Date) => {
        const dateStr = moment(date).format('YYYY-MM-DD');
        dispatch(changeStorageDate(dateStr))
    }

    const deleteAppointment = async (id: string) => {
        const response = (await ((new AppointmentService()).deleteAppointment(id))).getResponseData();
        if (!response.success) {
            handleErrors(response);
            return null;
        }
        refetchAppointments();

        return response;
    }

    const selectAppointment = (appointment: AppointmentFormData | null) => {
        setAppointmentSelected(appointment);
    }

    useEffect(() => {
        if (appointmentsResponse) {
            const total = appointmentsResponse.appointments.reduce((acc: number, appointment: Appointment) => {
                if (appointment.price) {
                    return acc + appointment.price;
                }
                return acc;
            }, 0);
            setTotalPriceAmount(total);
        }
    }, [appointmentsResponse]);

    return (
        <AppointmentCalendarContext.Provider value={{
            appointments,
            refetchAppointments,
            viewMode,
            changeViewMode,
            currentDate,
            setCalendarDate,
            deleteAppointment,
            appointmentSelected,
            selectAppointment,
            updateFilters,
            filters,
            updatePage,
            updatePageSize,
            updateFilterOrder,
            resetFilters,
            isLoading,
            lastPage: appointmentsResponse?.lastPage,
            totalResults: appointmentsResponse?.totalRegisters,
            totalAmount: totalPriceAmount,
            clientsResponse,
            refetchClients,
            openPatientModal,
            setOpenPatientModal,
            newPatientSelected,
            setNewPatientSelected,
        }}>
            {children}
        </AppointmentCalendarContext.Provider>
    );
}

export { AppointmentCalendarProvider, AppointmentCalendarContext };

export function useAppointmentCalendar() {
    return useContext(AppointmentCalendarContext);
}
import { FC } from "react";
import AsyncImg from "../../../components/AsyncImg";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import moment from "moment";
import './../../../styles/styles.scss';
import PlaceholderImage from "../../../components/extras/PlaceholderImage";

interface PatientHeaderProps {
    profileImg: string | null;
    name: string;
    last_name: string | null;
    email: string;
    phone: string | null;
    address: string | null;
    birthday: string | null;
    patientTags: string[];
    showPatientTags: JSX.Element[];
}

const PatientHeader: FC<PatientHeaderProps> = ({ profileImg, name, last_name, email, phone, address, birthday, patientTags, showPatientTags }) => {
    return (
        <div className="row">
            <Card className='shadow-3d-primary'>
                <CardBody>
                    <div className='row g-5 sticky-top'>
                        <div className='col-2 d-flex justify-content-center'>
                            {profileImg
                                ? <AsyncImg id={profileImg} isBackground styles="mx-auto d-block img-fluid rounded" style={{ width: '120px', height: '120px', objectFit: 'cover' }} />
                                : <PlaceholderImage width={120} height={120} className='mx-auto d-block img-fluid rounded' />
                            }
                        </div>

                        <div className='col-10 d-flex justify-content-center flex-column'>
                            <div className='row g-5'>
                                <div className='col-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='PermIdentity' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold mb-0' style={{ fontSize: '11px' }}>
                                                {name + ' ' + (last_name ? last_name : '')}
                                            </div>
                                            <div className='text-muted'>
                                                Nombre
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-4'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Mail' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold mb-0' style={{ fontSize: '11px' }}>
                                                {email ? email : 'No proporcionado'}
                                            </div>
                                            <div className='text-muted'>
                                                Correo electrónico
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {phone && (
                                    <div className='col-2'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon icon='Phone' size='2x' color='primary' />
                                            </div>
                                            <div className='flex-grow-1 ms-3' style={{ fontSize: '11px' }}>
                                                <div className='fw-bold mb-0'>
                                                    {phone ? phone : '-'}
                                                </div>
                                                <div className='text-muted'>
                                                    Teléfono
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {birthday && (
                                    <div className='col-1'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon icon='Cake' size='2x' color='primary' />
                                            </div>
                                            <div className='flex-grow-1 ms-3' style={{ fontSize: '11px' }}>
                                                <div className='fw-bold mb-0'>
                                                    {moment().diff(birthday, 'years')}
                                                </div>
                                                <div className='text-muted'>
                                                    Edad
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {address && (
                                    <div className='col-4'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon icon='Home' size='2x' color='primary' />
                                            </div>
                                            <div className='flex-grow-1 ms-3' style={{ fontSize: '11px' }}>
                                                <div className='fw-bold mb-0'>
                                                    {address ? address : '-'}
                                                </div>
                                                <div className='text-muted'>
                                                    Dirección
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {patientTags && (
                                    <div className='col-8 d-flex justify-content-end'>
                                        {showPatientTags}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default PatientHeader;
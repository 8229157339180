import React from 'react';
import Input from '../../../components/bootstrap/forms/Input';
import Checks from '../../../components/bootstrap/forms/Checks';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { formOptions } from '../../../utils/FormsUtils';
import SearchableSelect from '../../../components/select/SearchableSelect';
import { CardTitle } from '../../../components/bootstrap/Card';
import classNames from 'classnames';

interface FormFieldsProps {
    field: any;
    setField: any;
    formik: any;
    verifyClass: any;
    showErrors: any;
}

const FormFields: React.FC<FormFieldsProps> = ({ field, setField, formik, verifyClass, showErrors }) => {

    const [newOption, setNewOption] = React.useState('');
    const [newOptionValues, setNewOptionValues] = React.useState<string[]>([]);

    return (
        <>
            <CardTitle className='mt-4'>Campos del formulario</CardTitle>

            <div className={classNames({ 'd-none': !formik.values.fields.length })}>
                {formik.values.fields.map((field: any, index: number) => (
                    <div key={field + index} className='row pb-3 mb-3' style={{ borderBottom: '1px solid lightgrey' }}>
                        <div className='col-md-11'>
                            <>
                                <div className='row'>
                                    <div className='col-md-1 d-flex flex-column justify-content-center align-items-center'>
                                        {index !== 0 && (
                                            <Button
                                                icon="KeyboardArrowUp"
                                                onClick={() => {
                                                    if (index !== 0) {
                                                        const fields = [...formik.values.fields];
                                                        const aux = fields[index - 1];
                                                        fields[index - 1] = fields[index];
                                                        fields[index] = aux;
                                                        formik.setFieldValue('fields', fields);
                                                    }
                                                }}
                                            />
                                        )}

                                        {index !== formik.values.fields.length - 1 && (
                                            <Button
                                                icon="KeyboardArrowDown"
                                                onClick={() => {
                                                    if (index !== formik.values.fields.length - 1) {
                                                        const fields = [...formik.values.fields];
                                                        const aux = fields[index + 1];
                                                        fields[index + 1] = fields[index];
                                                        fields[index] = aux;
                                                        formik.setFieldValue('fields', fields);
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>

                                    <div className='col-md-11'>
                                        <>
                                            <div className='row'>
                                                <FormGroup requiredInputLabel label='Nombre' className='col-md-3'>
                                                    <Input
                                                        id={`fields.${index}.name`}
                                                        onChange={formik.handleChange}
                                                        value={field.name}
                                                        className={verifyClass(`formik.errors.fields.${index}?.name`)}
                                                    />
                                                    {showErrors(`formik.errors.fields.${index}?.name`)}
                                                </FormGroup>

                                                <FormGroup label='Tipo' className='col-md-2'>
                                                    <SearchableSelect
                                                        options={formOptions}
                                                        value={formOptions.find((option: any) => option.value === field.type)}
                                                        onChange={(e: any) => {
                                                            formik.setFieldValue(`fields.${index}.type`, e.value);
                                                            if (!['select', 'multi-select'].includes(e.value)) {
                                                                formik.setFieldValue(`fields.${index}.options`, []);
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>

                                                <FormGroup label='Requerido' className='col-md-1'>
                                                    <Checks
                                                        type='switch'
                                                        checked={field.required}
                                                        onChange={(e: any) => formik.setFieldValue(`fields.${index}.required`, e.target.checked)}
                                                    />
                                                </FormGroup>

                                                <FormGroup label='Principal' className='col-md-1'>
                                                    <Checks
                                                        type='switch'
                                                        checked={field.main}
                                                        onChange={(e: any) => formik.setFieldValue(`fields.${index}.main`, e.target.checked)}
                                                    />
                                                </FormGroup>

                                                <FormGroup label='Tamaño de la columna (del 1 al 12)' className='col-md-3'>
                                                    <Input
                                                        type='number'
                                                        value={field.columnSize}
                                                        onChange={(e: any) => formik.setFieldValue(`fields.${index}.columnSize`, e.target.value)}
                                                        min={1}
                                                        max={12}
                                                        className='w-25'
                                                    />
                                                </FormGroup>

                                                {['select', 'multi-select'].includes(field.type) && (
                                                    <div className='col-md-11 mt-2'>
                                                        <>
                                                            <div className='row'>
                                                                {field.options && field.options.map((option: any, i: number) => (
                                                                    <FormGroup label={`Opción ${i + 1}`} className='col-md-4 mb-2'>
                                                                        <div className='d-flex'>
                                                                            <Input
                                                                                id={`fields.${index}.options.${i}`}
                                                                                value={option}
                                                                                onChange={formik.handleChange}
                                                                            />

                                                                            <Button
                                                                                onClick={() => {
                                                                                    formik.setFieldValue(`fields.${index}.options`, field.options.filter((_: any, j: number) => j !== i));
                                                                                }}
                                                                                icon='Delete'
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                ))}

                                                                <FormGroup label='Añadir Opción' className='col-md-3'>
                                                                    <Input
                                                                        id={`newOption_${index}`}
                                                                        value={newOptionValues[index]}
                                                                        onChange={(e: any) => {
                                                                            const options = [...newOptionValues];
                                                                            options[index] = e.target.value;
                                                                            setNewOptionValues(options);
                                                                        }}
                                                                    />
                                                                </FormGroup>

                                                                <div className='col-md-1 d-flex align-items-center text-end'>
                                                                    <Button
                                                                        color='primary'
                                                                        isLight
                                                                        onClick={() => {
                                                                            formik.setFieldValue(`fields.${index}.options`, [...field.options, newOptionValues[index]]);
                                                                            const options = [...newOptionValues];
                                                                            options[index] = '';
                                                                            setNewOptionValues(options);
                                                                        }}
                                                                    >
                                                                        <Icon icon='Add' />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </>
                        </div>

                        <div className='col-md-1 d-flex align-items-center text-end'>
                            <Button
                                color='danger'
                                onClick={() => {
                                    formik.setFieldValue('fields', formik.values.fields.filter((_: any, i: number) => i !== index));
                                }}
                            >
                                <Icon icon='Delete' />
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="row">
                <div className='col-md-11'>
                    <>
                        <div className='row'>
                            <FormGroup requiredInputLabel label='Nombre' className="col-md-3">
                                <Input
                                    id='name'
                                    onChange={(e: any) => setField({ ...field, name: e.target.value })}
                                    value={field.name}
                                />
                            </FormGroup>

                            <FormGroup label='Tipo' className='col-md-2'>
                                <SearchableSelect
                                    key={field.type}
                                    options={formOptions}
                                    value={formOptions.find((option: any) => option.value === field.type)}
                                    onChange={(e: any) => {
                                        if (!['select', 'multi-select'].includes(e.value)) {
                                            setField({ ...field, type: e.value, options: [] });
                                            setNewOption('');
                                        } else {
                                            setField({ ...field, type: e.value });
                                        }
                                    }}
                                />
                            </FormGroup>

                            <FormGroup label='Requerido' className='col-md-1'>
                                <Checks
                                    type='switch'
                                    checked={field.required}
                                    onChange={(e: any) => setField({ ...field, required: e.target.checked })}
                                />
                            </FormGroup>

                            <FormGroup label='Principal' className='col-md-1'>
                                <Checks
                                    type='switch'
                                    checked={field.main}
                                    onChange={(e: any) => setField({ ...field, main: e.target.checked })}
                                />
                            </FormGroup>

                            <FormGroup label='Tamaño de la columna (del 1 al 12)' className='col-md-3'>
                                <Input
                                    type='number'
                                    value={field.columnSize}
                                    onChange={(e: any) => setField({ ...field, columnSize: e.target.value })}
                                    min={1}
                                    max={12}
                                    className='w-25'
                                />
                            </FormGroup>

                            {['select', 'multi-select'].includes(field.type) && (
                                <div className='col-md-11 mt-2'>
                                    <>
                                        <div className='row'>
                                            {field.options && field.options.map((option: any, index: number) => (
                                                <FormGroup label={`Opción ${index + 1}`} className='col-md-4 mb-2'>
                                                    <div className='d-flex'>
                                                        <Input
                                                            value={option}
                                                            onChange={(e: any) => {
                                                                const options = [...field.options];
                                                                options[index] = e.target.value;
                                                                setField({ ...field, options });
                                                            }}
                                                        />

                                                        <Button
                                                            onClick={() => {
                                                                const options = [...field.options];
                                                                options.splice(index, 1);
                                                                setField({ ...field, options });
                                                            }}
                                                            icon='Delete'
                                                        />
                                                    </div>
                                                </FormGroup>
                                            ))}

                                            <FormGroup label='Añadir Opción' className='col-md-3'>
                                                <Input
                                                    value={newOption}
                                                    onChange={(e: any) => setNewOption(e.target.value)}
                                                />
                                            </FormGroup>

                                            <div className='col-md-1 d-flex align-items-center text-end'>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    onClick={() => {
                                                        setField({ ...field, options: [...field.options, newOption] });
                                                        setNewOption('');
                                                    }}
                                                >
                                                    <Icon icon='Add' />
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )}
                        </div>
                    </>
                </div>

                <div className='col-md-1 d-flex align-items-center text-end'>
                    <Button
                        isDisable={!field.name}
                        color='secondary'
                        onClick={() => {
                            if (newOption) {
                                formik.setFieldValue('fields', [...formik.values.fields, { ...field, options: [...field.options, newOption] }]);
                            } else {
                                formik.setFieldValue('fields', [...formik.values.fields, field]);
                            }
                            setField({ name: '', required: false, type: 'number', main: false, columnSize: 12, options: [] });
                            setNewOption('');
                        }}
                    >
                        <Icon icon='Add' />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default FormFields;
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MenuService } from "../../../services/menus/menuService";
import { ProfileService } from "../../../services/profile/profileService";
import useFetch from "../../../hooks/useFetch";
import CreateTemplateForm from "../../../components/menu/CreateTemplateForm";
import useGetMyProfile from "../../../hooks/api-calls/useGetMyProfile";

const CreateMenu: FC = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const {fetchMyProfile} = useGetMyProfile();

    const [userProfile] = useFetch(fetchMyProfile);

    const handleCreateMenu = async (values: any) => {
        setLoading(true);

        values.user = userProfile.id;

        try {
            const response = await (await (new MenuService()).createMenu(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success('Menú creado correctamente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al crear el menú');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear el menú');
        } finally {
            setLoading(false);
        }
    }

    return (
        <CreateTemplateForm submit={handleCreateMenu} isLoading={loading} userProfile={userProfile} />
    )
}

export default CreateMenu;
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { useFormik } from "formik";
import { NotesService } from "../../../services/notes/notesService";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextArea";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import useFilters from "../../../hooks/useFilters";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import * as yup from 'yup';
import Icon from "../../../components/icon/Icon";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { NavigationLine } from "../../../layout/Navigation/Navigation";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import EasyEdit from 'react-easy-edit';
import './../../../styles/styles.scss';
import Checks from "../../../components/bootstrap/forms/Checks";
import moment from "moment";

interface PatientNoteForm {
    client: string;
    title: string;
    text: string;
    isPublic: boolean;
}

const noteSchema = yup.object({
    client: yup.string(),
    title: yup.string().required('El título es obligatorio'),
    text: yup.string().required('La nota es obligatoria'),
    isPublic: yup.boolean(),
});

interface INotesFilters {
    client?: string;
}

const Notes = () => {
    const { id = '' } = useParams<{ id: string }>();
    const notesService = new NotesService();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const navigate = useNavigate();

    const [noteValue, setNoteValue] = useState<string>("");
    const [displayAddNote, setDisplayAddNote] = useState<boolean>(false);

    useAutosizeTextArea(textAreaRef.current, noteValue);

    const notesFilters: INotesFilters = {
        client: id
    };

    const noteInitialValue: PatientNoteForm = {
        client: id,
        title: '',
        text: '',
        isPublic: false,
    }

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(notesFilters);

    const fetchNotes = useCallback(async () => {
        const response = await notesService.listNotes(filters);
        return response.getResponseData();
    }, [filters]);

    const fetchNote = useCallback(async () => {
        return [];
        const response = await notesService.getNote(id);
        setNoteValue(response.getResponseData().data);
        return response.getResponseData();
    }, []);

    const [notes, fetchingNotes, notesError, refetchNotes] = useFetch(fetchNotes);
    const [note, fetchingNote] = useFetch(fetchNote);

    // Guardar nota del paciente
    const _handleSaveNote = async (values: any) => {
        try {
            const response = await notesService.createPatientNote(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                _handleResetFilters();
                formik.resetForm();
                toast.success('Nota guardada');
            } else {
                toast.error('Error al guardar la nota');
            }
        } catch (error: any) {
            toast.error("Error al guardar la nota");
        }
    };

    // Editar nota del paciente
    const _handleEditNote = async (values: any) => {
        try {
            const response = await notesService.editPatientNote(values);
            const responseData = response.getResponseData();

            if (!responseData.success) {
                toast.error('Error al editar la nota');
            } else {
                refetchNotes();
            }
        } catch (error: any) {
            toast.error("Error al editar la nota");
        }
    };

    const _handleDeleteNote = async (id: string) => {
        try {
            const response = await notesService.deletePatientNote(id);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetchNotes();
                toast.success('Nota eliminada');
            } else {
                toast.error('Error al eliminar la nota');
            }
        } catch (error: any) {
            toast.error("Error al eliminar la nota");
        }
    };

    const _handleResetFilters = () => {
        resetFilters();
        refetchNotes();
    };

    const formik = useFormik<PatientNoteForm>({
        initialValues: noteInitialValue,
        validationSchema: noteSchema,
        onSubmit: values => _handleSaveNote(values)
    });

    const formikEdit = useFormik<PatientNoteForm>({
        initialValues: noteInitialValue,
        onSubmit: values => _handleEditNote(values)
    });

    const verifyClass = (inputFieldID: keyof PatientNoteForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof PatientNoteForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const addNote = () => {
        return (
            <>
                <CardBody className="ps-0 pe-0 mx-5">
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <div className="row d-flex justify-content-between">
                            <div className="col-md-3">
                                <FormGroup label="Título">
                                    <Input
                                        id="title"
                                        className={verifyClass('title')}
                                        onChange={formik.handleChange}
                                        value={formik.values.title}
                                    />
                                    {showErrors('title')}
                                </FormGroup>
                            </div>

                            <div className="col-md-3 text-end">
                                <FormGroup label="Visible para el paciente" className="d-flex justify-content-end">
                                    <Checks
                                        id="visible"
                                        type="switch"
                                        checked={formik.values.isPublic}
                                        onChange={(e: any) => formik.setFieldValue('isPublic', e.target.checked)}
                                        onBlur={formik.handleBlur}
                                        className={verifyClass('isPublic') + ' form-control ms-1 p-0'}
                                    />
                                    {showErrors('isPublic')}
                                </FormGroup>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-md-12">
                                <FormGroup label="Nota">
                                    <Textarea
                                        id="text"
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { formik.handleChange(e) }}
                                        rows={3}
                                        value={formik.values.text}
                                        className={verifyClass('text') + ' form-control'}

                                    />
                                </FormGroup>
                                {showErrors('text')}
                            </div>
                        </div>
                    </form>
                </CardBody>

                <CardFooter className="p-0 mx-5">
                    <CardFooterRight>
                        <Button
                            size='sm'
                            color='secondary'
                            icon='Save'
                            isLight
                            onClick={() => formik.handleSubmit()}
                        >
                            Guardar
                        </Button>
                    </CardFooterRight>
                </CardFooter>
            </>
        )
    }

    const getAllNotes = () => {
        if (fetchingNotes) return <div className="text-center"><Spinner /></div>;

        if (notesError) return <ErrorMessage message={notesError} />;

        return (
            notes && notes.notes?.length > 0 && (
                <div className="row mt-4">
                    {
                        notes && notes.notes?.map((note: any) => (
                            <div
                                key={note.id}
                                className="col-md-12 note-card"
                                style={{
                                    backgroundColor: '#fef5eb',
                                    borderColor: '#fef5eb'
                                }}
                            >
                                <form onSubmit={formikEdit.handleSubmit} autoComplete="off">
                                    <CardBody>
                                        <CardTitle className="row d-flex justify-content-between">
                                            <div className="col-md-4 p-0">
                                                <EasyEdit
                                                    type="text"
                                                    onSave={(e: any) => {
                                                        formikEdit.setFieldValue('title', e)
                                                        formikEdit.setFieldValue('note', note.id)
                                                        formikEdit.setFieldValue('text', note.text)
                                                        formikEdit.setFieldValue('isPublic', note.isPublic)
                                                        formikEdit.handleSubmit()
                                                    }}
                                                    value={note.title}
                                                    attributes={{ className: 'easy-input' }}
                                                    saveButtonLabel={<Icon icon='Check' color='dark' />}
                                                    cancelButtonLabel={<Icon icon='Close' color='dark' title='Cancelar' />}
                                                    inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                                />
                                            </div>
                                            <div className="col-md-1 d-flex justify-content-end">
                                                <Button
                                                    className="p-2"
                                                    color="dark"
                                                    icon="Delete"
                                                    isLight
                                                    onClick={() => _handleDeleteNote(note.id)}
                                                    title='Eliminar nota'
                                                />
                                            </div>
                                        </CardTitle>

                                        <p className="mt-4 textearea-inline">
                                            <EasyEdit
                                                type="textarea"
                                                onSave={(e: any) => {
                                                    formikEdit.setFieldValue('text', e)
                                                    formikEdit.setFieldValue('note', note.id)
                                                    formikEdit.setFieldValue('title', note.title)
                                                    formikEdit.setFieldValue('isPublic', note.isPublic)
                                                    formikEdit.handleSubmit()
                                                }}
                                                value={note.text}
                                                attributes={{ className: 'easy-input' }}
                                                saveButtonLabel={<Icon icon='Check' color='dark' />}
                                                cancelButtonLabel={<Icon icon='Close' color='dark' title='Cancelar' />}
                                                inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                            />
                                        </p>
                                    </CardBody>

                                    <CardFooter
                                        style={{
                                            backgroundColor:  '#fef5eb',
                                            borderColor:  '#fef5eb'
                                        }}
                                    >
                                        <CardFooterLeft>
                                            <p className="mb-0 text-muted">
                                                <span>Editado por </span>
                                                <span className={`fw-bold text-decoration-underline`} style={{color: note?.user?.color ? note?.user?.color : '#000000'  }} onClick={() => { navigate(`/users/${note.user.id}/profile`) } }
                                                >{' '+ note.user.name+ ' '+ (note.user.lastName?note.user.lastName:'')}</span>
                                                <span>{' el ' + moment(note.updatedAt.date).format('DD/MM/YYYY HH:mm') }</span>
                                            </p>
                                        </CardFooterLeft>
                                        <CardFooterRight>
                                            <p className="mb-0">{note.isPublic ? 'Visible' : 'Oculta'}</p>
                                            <Checks
                                                id="visible"
                                                type="switch"
                                                checked={note.isPublic}
                                                onChange={(e: any) => {
                                                    formikEdit.setFieldValue('isPublic', e.target.checked)
                                                    formikEdit.setFieldValue('note', note.id)
                                                    formikEdit.setFieldValue('text', note.text)
                                                    formikEdit.setFieldValue('title', note.title)
                                                    formikEdit.handleSubmit()
                                                }}
                                                onBlur={formikEdit.handleBlur}
                                            />
                                        </CardFooterRight>
                                    </CardFooter>
                                </form>
                            </div>
                        ))
                    }
                </div>
            )
        )
    }

    return (
        <>
            <div className="row d-flex justify-content-between">
                <CardTitle className="w-auto">Observaciones</CardTitle>
                <Button
                    className="btn btn-secondary p-2 w-auto"
                    onClick={() => { displayAddNote ? setDisplayAddNote(false) : setDisplayAddNote(true) }}
                    title='Añadir nota'
                >
                    <Icon icon="Add" color='dark' size={'lg'} />
                    {displayAddNote ? <Icon icon="KeyboardArrowUp" color='dark' size={'lg'} /> : <Icon icon="KeyboardArrowDown" color='dark' size={'lg'} />}
                </Button>
            </div>

            {displayAddNote && addNote()}

            {notes && getAllNotes()}
        </>
    );
}

export default Notes;
import { useEffect, useState } from 'react';
import { ProfileService } from '../../services/profile/profileService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage loged profile fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación usuario logeado.
----------------------------------------------------------------------------------------*/

const useGetMyProfile = (initialFilters = {}, limit = 99999999) => {

    const [data, setData] = useState<{}>({});
    const [filters, setFilters] = useState({
        limit: limit,
        filter_filters: { ...initialFilters }
    });

    const fetchData = async () => {
        try {
            const service = new ProfileService();
            const response = await service.getMyProfile();
            const fetchedData = response.getResponseData().data;

            if (fetchedData) {
                setData(fetchedData);
            }
        } catch (error) {
            console.log('Error fetching my profile:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filters]);

    const getList = () => {
        return data as any;
    };

    return {
        fetchMyProfile: fetchData,
        getMyProfile: getList,
    };
}

export default useGetMyProfile;
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Icon from "../icon/Icon";
import classNames from "classnames";
import Tooltips from "../bootstrap/Tooltips";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Spinner from "../bootstrap/Spinner";
import { DeviceService } from "../../services/devices/deviceService";
import useHandleErrors from "../../utils/hooks/useHandleErrors";
import { useDispatch, useSelector } from "react-redux";
import { Device, User, addDevice } from "../../redux/authSlice";

const firebaseConfig = {
    apiKey: "AIzaSyDwqTeSKqdTZyfFLaWy4Xpjqt3pn5tRnmo",
    authDomain: "nutric-app.firebaseapp.com",
    projectId: "nutric-app",
    storageBucket: "nutric-app.appspot.com",
    messagingSenderId: "888587176990",
    appId: "1:888587176990:web:fd90118cc09bb7b87ef20f",
    measurementId: "G-759V7K2QVZ"
};
const VAPID = "BPFthEPyRHmDSgslI_-J2azrpdYHFMEMaDZr23YXTbso85dV6T5PfD3eSHsC9drq41wpYv4H2z94sRKEU-f5N-A";
const app = initializeApp(firebaseConfig);

const PushNotificationManager = () => {

    const { devices }: User = useSelector((state: any) => state.auth.user);
    const dispatch = useDispatch();
    const { handleErrors } = useHandleErrors();

    const [allowed, setAllowed] = useState<boolean>(false);
    const [messaging, setMessaging] = useState<any>(null);
    const [token, setToken] = useState<string>('');
    const [isActivating, setIsActivating] = useState<boolean>(false);

    // Check if push notification and service worker are supported by the browser
    const _isPushNotificationSupported = () => {
        return "serviceWorker" in navigator && "PushManager" in window;
    }

    // Request permissions
    const _requestNotificationPermission = async () => {
        if (!_isPushNotificationSupported()) {
            toast.warning("Tu navegador no soporta esta funcionalidad");
        };

        if (_isPushNotificationDenied()) {
            toast.warning("Tienes cancelados los permisos de notificaciones para esta aplicación. Si quieres activarlos, ve a la configuración de tu navegador");
            return;
        }

        let token = await _getToken();

        if (token) {
            let deviceService = new DeviceService()
            let registeredResponse = (await deviceService.register(token)).getResponseData();
            handleErrors(registeredResponse);
            if (registeredResponse.success) {
                toast.success("Notificaciones activadas");
                dispatch(addDevice(registeredResponse.data));
            }
        }
    }

    // Check if push notification is allowed
    const _isPushNotificationAllowed = () => {
        return Notification.permission === "granted";
    }

    const _isPushNotificationDenied = () => {
        return Notification.permission === "denied";
    }

    const _getMessaging = () => {
        const messaging = getMessaging(app);
        setMessaging(messaging);
        onMessage(messaging, (payload: any) => {

            // log message only if we are in dev environment
            if (process.env.REACT_APP_ENV === 'dev') {
                console.log('Message received. ', payload);
            }

            const { title, body } = payload.data;

            toast.info(
                (<>
                    <b>{title}</b>:
                    <div dangerouslySetInnerHTML={{ __html: body }}></div>
                </>), {
                onClick: () => {
                    window.focus();
                    toast.dismiss();
                    if (payload.data.route !== undefined) {
                        window.location.href = payload.data.route;
                    }
                },
                autoClose: 20000,
                hideProgressBar: false,
            });
        });
    }

    const _getToken = async (): Promise<string> => {
        setIsActivating(true);
        const token = await getToken(messaging, { vapidKey: VAPID })

        setToken(token);
        if (!allowed) {
            setAllowed(true)
        }
        setIsActivating(false);

        return token;
    }

    const _isAllowed = () => {
        if (_isPushNotificationSupported()) {
            if (_isPushNotificationAllowed()) {
                setAllowed(true);
            } else {
                setAllowed(false);
            }
        } else {
            setAllowed(false);
        }
    }

    useEffect(() => {
        _getMessaging();
        _isAllowed();
    }, []);

    useEffect(() => {
        if (allowed) { _getToken() }
    }, [allowed]);

    useEffect(() => {
        // when receive token from firebase we check if it is already registered devices retrieved from state. if not, we mark as not allowed.
        if (token) {
            let isRegistered = devices?.find((device: Device) => device.token === token);
            if (!isRegistered) {
                setAllowed(false);
            }
        }
    }, [token]);

    return (
        <div
            role='presentation'
            className='navigation-item cursor-pointer'
            onClick={async () => { if (!allowed) { _requestNotificationPermission() } }}
        >
            <span className={classNames({
                'navigation-link navigation-link-pill': true,
                'bg-secondary': allowed,
            })}
            >
                <span className='navigation-link-info'>
                    {isActivating && (<Spinner className="me-2" size={30}></Spinner>)}
                    {!isActivating && (
                        <Tooltips title={allowed ? 'Notificaciones activadas' : 'Notificaciones desactivadas'}>
                            <Icon icon='Notifications' className='navigation-icon' color={allowed ? 'light' : 'warning'} />
                        </Tooltips>
                    )}

                    <span className={classNames('navigation-text', { 'text-warning': !allowed })}>Notificaciones</span>
                </span>
            </span>
        </div>
    )
};

export default PushNotificationManager;
import { type } from "os"
import { usePrivilege } from "../priviledge/PriviledgeProvider"
import { useCallback, useEffect, useState } from "react"
import useFetch from "../../hooks/useFetch"
import { UserService } from "../../services/users/userService"
import SearchableSelect from "./SearchableSelect"
import { showErrors } from "../../utils/Forms/FormikVerifyClass"

type UserSelectorProps = {
    name: string,
    onChange: (user: any) => void,
    formik: any,
    selectedId: string | undefined
}

export const UserSelector: React.FC<UserSelectorProps> = ({ name, onChange, selectedId, formik }) => {

    const { userCan } = usePrivilege();
    const defaultValue = { value: '', label: 'Todos' };
    const [selectedUser, setSelectedUser] = useState<any>(undefined);
    const [usersResponse] = useFetch(useCallback(async () => {
        if (!userCan('assign_other_users_to_appointment', 'appointments')) return;
        const response = await (await (new UserService()).getUsers({ limit: 100, filter_filters: { active: 1 } })).getResponseData();
        return response;
    }, []));

    useEffect(() => {
        if (usersResponse?.users === undefined) return;
        if (selectedId === undefined) return;
        const user = usersResponse?.users.find((u: any) => u.id === selectedId);
        if (user === undefined) return;
        setSelectedUser({
            value: user.id,
            label: `${user.name} ${user.lastName || ""} (${user.email})`,
        })
    }, [selectedId])

    useEffect(() => {
        if (selectedUser === undefined) return;
        onChange(selectedUser.value);
    }, [selectedUser])

    return (
        <>
            <SearchableSelect
                name={name}
                isSearchable
                onBlur={formik.handleBlur}
                placeholder='Usuario'
                value={selectedUser}
                isClearable
                defaultValue={defaultValue}
                onChange={(newValue: any) => {
                    if (newValue === null) {
                        setSelectedUser(defaultValue);
                        formik.setFieldValue(name, null);
                        return;
                    }
                    setSelectedUser(newValue)
                    formik.setFieldValue('user', newValue.value)
                }}
                options={usersResponse?.users.map((u: any) => ({
                    value: u.id,
                    label: `${u.name} ${u.lastName !== null ? u.lastName : ""} (${u.email})`,
                }))}
            />
            {showErrors(formik, 'user')}
        </>
    )

}
import React, { useCallback, useState } from 'react';
import { MenuService } from '../../services/menus/menuService';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import { toast } from 'react-toastify';
import { MenusApiResponse } from '../../type/clientMenus-type';
import useFetch from '../../hooks/useFetch';
import EasyEdit from 'react-easy-edit';
import { useFormik } from 'formik';
import useHandleErrors from '../../utils/hooks/useHandleErrors';
import SearchableSelect from '../select/SearchableSelect';
import Spinner from '../bootstrap/Spinner';
import { useMenuProvider } from './providers/MenuProvider';
import './styles/styles.scss';
import { FoodService } from '../../services/foods/foodService';

interface MenuActionsProps {
    menuId: string;
    data: any;
    refetchMenu: () => void;
}

const MenuActions: React.FC<MenuActionsProps> = ({ menuId, data, refetchMenu }) => {

    const { displayAddItem, setDisplayAddItem, setDisplayPatientData, setDisplayCreateTemplate } = useMenuProvider();
    const { handleErrors } = useHandleErrors();

    const [multiplierGroup, setMultiplierGroup] = useState<string>('');
    const [isSending, setIsSending] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [groups] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getAlimentGroups();
        return response.getResponseData();
    }, []));

    // Editar multiplicador
    const _handleUpdateMultiplier = async (values: any) => {
        values.menuId = menuId;
        try {
            const response = await (new MenuService()).editMultiplier(values);
            const responseData = response.getResponseData() as MenusApiResponse;
            if (responseData.success) {
                refetchMenu();
                toast.success("Multiplicador editado");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar el multiplicador");
        }
    };

    // Descargar menú
    const _downloadMenu = async () => {
        try {
            setIsDownloading(true);
            const response = await (new MenuService()).downloadMenu(menuId);
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.name.replace(/\s/g, "") + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                handleErrors(response);
            }
            setIsDownloading(false);
        } catch (error: any) {
            toast.error(error.message);
            setIsDownloading(false);
        }
    };

    // Enviar menú por email
    const _sendMenuByEmail = async () => {
        try {
            setIsSending(true);
            const response = await (new MenuService()).sendMenuByEmail(menuId, data.client.email);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                toast.success("Plan enviado al paciente");
            } else {
                handleErrors(response);
            }
            setIsSending(false);
        } catch (error: any) {
            toast.error("Error al enviar el plan al paciente");
            setIsSending(false);
        }
    };

    // Cambiar multiplicador por grupo
    const _handleGroupMultiplierChange = async (e: any) => {
        setMultiplierGroup(e);
        formik.setFieldValue('menuGroupMultiplier', e.value);
    };

    // obtener la lista de grupos de alimentos
    const getGroupsOptions = () => {
        if (groups) {
            return groups.food_groups.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const formik = useFormik({
        initialValues: {
            multiplier: 1,
            menuGroupMultiplier: ''
        },
        onSubmit: async (values) => { _handleUpdateMultiplier(values) }
    });

    if (!data) return null;

    return (
        <div className='actions-menu rounded row d-flex justify-content-between'>
            <div className='col-md-4'>
                {data.client && (
                    <form onSubmit={formik.handleSubmit} autoComplete="off" className='row'>
                        <div className='col-md-4 d-flex align-items-center'>
                            <h5 className="w-auto">
                                <EasyEdit
                                    type="number"
                                    onSave={(e: any) => {
                                        formik.setFieldValue('multiplier', e)
                                    }}
                                    value='Multiplicador'
                                    placeholder='1'
                                    attributes={{ className: 'easy-input' }}
                                    saveButtonLabel={<Icon icon='Check' color='dark' />}
                                    cancelButtonLabel={<Icon icon='Close' color='dark' />}
                                />
                            </h5>
                        </div>

                        <div className='col-md-6 d-flex align-items-center'>
                            {groups && (
                                <SearchableSelect
                                    onChange={(e: any) => _handleGroupMultiplierChange(e)}
                                    value={multiplierGroup}
                                    options={getGroupsOptions()}
                                    name='group'
                                    classname='w-100'
                                    placeholder='grupo'
                                />
                            )}
                        </div>

                        <div className='col-md-2 d-flex align-items-center p-0'>
                            <Button
                                className='p-2 m-0'
                                color='light'
                                onClick={() => formik.handleSubmit()}
                                title='Guardar'
                            >
                                <Icon icon="Save" color='secondary' size={'lg'} />
                            </Button>
                        </div>
                    </form>
                )}
            </div>

            <div className='col-md-8 d-flex justify-content-end p-0'>
                <Button
                    className="p-2"
                    color='primary'
                    isLight
                    onClick={() => { _downloadMenu() }}
                    title='Imprimir'
                >
                    {isDownloading ? <div className='text-center'><Spinner /></div> : <Icon icon="Print" color='dark' size={'2x'} />}
                </Button>

                {data.client && (
                    <>
                        <Button
                            className="p-2"
                            color='primary'
                            isLight
                            onClick={() => { _sendMenuByEmail() }}
                            title='Enviar por email'
                        >
                            {isSending ? <div className='text-center'><Spinner /></div> : <Icon icon="Email" color='dark' size={'2x'} />}
                        </Button>

                        <Button
                            className="p-2"
                            color='primary'
                            isLight
                            onClick={() => { setDisplayPatientData(true) }}
                            title='Datos del paciente'
                        >
                            <Icon icon="Portrait" color='dark' size={'2x'} />
                        </Button>
                    </>
                )}

                <Button
                    className='p-2'
                    color='primary'
                    isLight
                    onClick={() => { setDisplayCreateTemplate(true) }}
                    title='Crear plantilla'
                >
                    <Icon icon="Assignment" color='dark' size={'2x'} />
                </Button>

                <Button
                    className='p-2'
                    color='primary'
                    isLight
                    onClick={() => { displayAddItem ? setDisplayAddItem(false) : setDisplayAddItem(true) }}
                    title='Añadir items'
                >
                    <Icon icon="Add" color='dark' size={'2x'} />
                    {displayAddItem ? <Icon icon="KeyboardArrowUp" color='dark' size={'lg'} /> : <Icon icon="KeyboardArrowDown" color='dark' size={'lg'} />}
                </Button>
            </div>
        </div>
    );
};

export default MenuActions;
import { FC } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { MenuService } from "../../services/menus/menuService";
import { MenusApiResponse } from "../../type/clientMenus-type";
import { CardBody, CardFooter } from "../bootstrap/Card";
import useHandleErrors from "../../utils/hooks/useHandleErrors";
import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.scss';

interface CreateTemplateModalProps {
    isOpen: boolean;
    setIsOpen: any;
    menuId: string;
    menuName: string;
}

const CreateTemplateModal: FC<CreateTemplateModalProps> = ({ isOpen, setIsOpen, menuId, menuName }) => {

    const { handleErrors } = useHandleErrors();

    const handleCreateTemplate = async (values: any) => {
        try {
            const response = await (new MenuService()).createTemplate(values);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                setIsOpen(false);
                toast.success('Plantilla creada');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al crear la plantilla');
        }
    };

    const formik = useFormik({
        initialValues: {
            name: menuName,
            menu: menuId
        },
        validationSchema: Yup.object({
            name: Yup.string().required('El nombre es obligatorio')
        }),
        onSubmit: (values) => { handleCreateTemplate(values) }
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del paciente'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='patient-data'>Crear una nueva plantilla</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <CardBody>
                        <label htmlFor="name" className="labelStyle">Introduzca el nombre</label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger">{formik.errors.name}</div>
                        ) : null}
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">Crear</button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default CreateTemplateModal;
import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/daily_intakes';

export class DailyIntakesService extends RestServiceConnection {

    listIntakes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getIntake = async (dailyIntakeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { dailyIntakeId }
        }, true) as AxiosResponse;
        return this;
    }

    createIntake = async (intake: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: intake
        }, true) as AxiosResponse;
        return this;
    }

    editIntake = async (intake: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: intake
        }, true) as AxiosResponse;
        return this;
    }

    deleteIntake = async (dailyIntakeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { dailyIntakeId }
        }, true) as AxiosResponse;
        return this;
    }

    toggleIntakeStatus = async (dailyIntakeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { dailyIntakeId }
        }, true) as AxiosResponse;
        return this;
    }

    importIntakes = async (file: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/import',
            data: { excelFile: file },
            headers: { "Content-Type": "multipart/form-data" }
        }, true);
        return this;
    }

    exportIntakes = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/export',
            data: { ...filters },
            responseType: 'arraybuffer',
        }, true);
        return this;
    }

}
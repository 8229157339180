import { useDrop } from "react-dnd";
import React, { Key, useState } from "react";
import { MenuService } from "../../services/menus/menuService";
import { toast } from "react-toastify";
import { allergenList } from "../../utils/FoodUtils";
import DraggableMenuItem from "./DraggableMenuItem";
import { Intake, Day, MenuItem } from "../../type/menu-type";
import useHandleErrors from "../../utils/hooks/useHandleErrors";
import './styles/styles.scss';

interface DropContainerProps {
    menuId: string;
    day: Day;
    intake: Intake;
    menuItems: any[];
    allergens: string[];
    aversions: string[];
    refetch: () => void;
}

const DropContainer: React.FC<DropContainerProps> = ({ menuId, day, intake, menuItems, allergens, aversions, refetch }) => {

    const { handleErrors } = useHandleErrors();

    const [selectedFood, setSelectedFood] = useState<any | null>(null);
    const [selectedRecipe, setSelectedRecipe] = useState<any | null>(null);
    const [selectedActivity, setSelectedActivity] = useState<any | null>(null);

    const aMenuItems = menuItems.map((item: any) => {
        let name;

        if (item.food) {
            name = item.food.name;
        } else if (item.recipe) {
            name = item.recipe.name;
        } else if (item.physicalActivity) {
            name = item.physicalActivity.name;
        }

        return {
            id: item.id,
            name,
            dayId: item.days.id,
            intakeId: item.intakes.id,
        }
    });

    // Drop
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'menuItem',
        drop: async (item: any) => {
            if (item.id !== undefined) {
                await addFoodToMenuItem(day.id, intake.id, item.id, item.isFood, item.isRecipe, item.isActivity, item.quantity, item.fromSelectors);

                refetch();

                aMenuItems.push({
                    id: item.id,
                    name: item.name,
                    dayId: item.dayId,
                    intakeId: item.intakeId,
                });
            }
        },
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
    });

    // Filtra las aversiones que son alimentos
    const foodAversions = aversions?.filter((aversion: any) => aversion.food).map((aversion: any) => aversion.food);
    const foodGroupAversions = aversions?.filter((aversion: any) => aversion.foodGroup).map((aversion: any) => aversion.foodGroup);

    // Filtra los items del menú por día e ingesta para mostrarlos en cada celda
    const filteredItems = menuItems?.filter(
        (item: any) => item.days?.id === day.id && item.intakes?.id === intake.id
    );

    // Añadir alimento, receta o actividad al menuItem
    const addFoodToMenuItem = async (dayId: string, intakeId: string, itemId: string, isFood: boolean, isRecipe: boolean, isActivity: boolean, quantity: number, fromSelectors: boolean) => {
        const menuItem: MenuItem = {
            menu: menuId,
            day: dayId,
            intake: intakeId,
            food: isFood ? itemId : null,
            recipe: isRecipe ? itemId : null,
            activity: isActivity ? itemId : null,
            quantity: Number(quantity),
            calculate: fromSelectors,
        };

        try {
            const response = await (await (new MenuService()).addMenuItem(menuItem)).getResponseData();
            if (response.success) {
                //refetch();
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al añadir el item al menú");
        }
    };

    // Eliminar menuItem
    const deleteMenuItem = async (menuItem: string) => {
        const response = await (new MenuService()).deleteMenuItem(menuItem);
        refetch();
        return response;
    };

    // Filtra los alérgenos del menuItem que coinciden con los alérgenos del paciente
    const getFoodAllergens = (food: any) => {
        return Object.entries(food).filter(([key, value]) => {
            const allergen = allergenList.find((a) => a.name === key);
            return allergen && value === true && hasMatchingAllergens(allergens, key);
        });
    };

    // Comprueba si el menuItem tiene alérgenos que coincidan, recibe los alérgenos del paciente y del menuItem
    const hasMatchingAllergens = (allergens: any, key: Key) => {
        return allergens?.some((allergen: any) => allergen === key);
    };

    return (
        <div
            ref={drop}
            className="menu-cell-content d-flex justify-content-center flex-column"
            style={{
                backgroundColor: isOver ? "#f5d0a9" : "",
                opacity: isOver ? 0.5 : 1,
                minHeight: '55px',
                minWidth: '135px',
            }}
        >
            {filteredItems.length > 0
                ? (
                    <div className="menu-items-container">
                        {filteredItems.map((item: any) => {
                            return (
                                <DraggableMenuItem
                                    key={item.id}
                                    item={item}
                                    aversion={aversions}
                                    foodGroupAversions={foodGroupAversions}
                                    foodAversions={foodAversions}
                                    selectedFood={selectedFood}
                                    selectedRecipe={selectedRecipe}
                                    selectedActivity={selectedActivity}
                                    setSelectedFood={setSelectedFood}
                                    setSelectedRecipe={setSelectedRecipe}
                                    setSelectedActivity={setSelectedActivity}
                                    deleteMenuItem={deleteMenuItem}
                                    getFoodAllergens={getFoodAllergens}
                                />
                            );
                        })}
                    </div>
                )
                : <span className="text-muted">Aún no hay items</span>
            }
        </div>

    );
};

export default DropContainer;
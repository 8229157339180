import React, { useContext, useState } from "react";

type MenuProviderContextData = {
    displayAddItem?: boolean,
    setDisplayAddItem: (display: boolean) => void,
    displayPatientData?: boolean,
    setDisplayPatientData: (display: boolean) => void,
    displayCreateTemplate?: boolean,
    setDisplayCreateTemplate: (display: boolean) => void,
}

const MenuProviderContext: React.Context<MenuProviderContextData> = React.createContext<MenuProviderContextData>({
    displayAddItem: false,
    setDisplayAddItem: () => {},
    displayPatientData: false,
    setDisplayPatientData: () => {},
    displayCreateTemplate: false,
    setDisplayCreateTemplate: () => {},
});

type MenuProviderProps = {
    children: React.ReactNode;
}

const MenuProvider: React.FC<MenuProviderProps> = ({
    children,
}) => {
    const [displayAddItem, setDisplayAddItem] = useState(false);
    const [displayPatientData, setDisplayPatientData] = useState<boolean>(false);
    const [displayCreateTemplate, setDisplayCreateTemplate] = useState<boolean>(false);

    return (
        <MenuProviderContext.Provider value={{
            displayAddItem,
            setDisplayAddItem,
            displayPatientData,
            setDisplayPatientData,
            displayCreateTemplate,
            setDisplayCreateTemplate,
        }}>
            {children}
        </MenuProviderContext.Provider>
    );
}

export { MenuProvider, MenuProviderContext };

export function useMenuProvider() {
    return useContext(MenuProviderContext);
}
import { FC, useCallback, useState } from 'react'
import FormGroup from '../../../components/bootstrap/forms/FormGroup'
import Spinner from '../../../components/bootstrap/Spinner'
import useFetch from '../../../hooks/useFetch'
import Select from '../../../components/bootstrap/forms/Select'
import SearchableSelect from '../../../components/select/SearchableSelect'
import { monthsList } from '../../../utils/FoodUtils'
import Button from '../../bootstrap/Button'
import { FoodService } from '../../../services/foods/foodService'

interface IFoodFiltersProps {
    updateFilters: (filters: any) => void;
    filters: any;
    resetFilters: () => void;
}

const FoodFilters: FC<IFoodFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const [groupsSelected, setGroupsSelected] = useState<any>([]);
    const [highIn, setHighIn] = useState<any[]>([]);

    // buscar los grupos de alimentos
    const [groups] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getAlimentGroups();
        return response.getResponseData();
    }, []));

    // buscar los nutrientes
    const [nutrients] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getNutrientsList();
        return response.getResponseData();
    }, []));

    // controlar el cambio en el selector de grupos
    const handleGroupsChange = (e: any) => {
        setGroupsSelected(e);
        updateFilters({ group_food: e.map((group: any) => group.value) });
    }

    // controlar el cambio en el selector de nutrientes
    const handleNutrientsChange = (e: any) => {
        setHighIn(e);
        updateFilters({ high_in: e.value });
    }

    // controlar el cambio en el selector de comienzo de temporada
    const handleSeasonChange = (e: any) => {
        updateFilters({ season: e.target.value });
    }

    // obtener la lista de grupos de alimentos
    const getGroupsOptions = () => {
        if (groups) {
            return groups.food_groups.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    // obtener la lista de nutrientes
    const getNutrientsOptions = () => {
        if (nutrients) {
            return nutrients.map((option: { name: string; nombre: string; }) => {
                return {
                    value: option.name,
                    label: option.nombre
                }
            })
        }
        return [];
    }

    // resetear los filtros
    const _handleResetFilters = () => {
        resetFilters();
        setGroupsSelected([]);
        setHighIn([]);
    }

    return (
        <>
            <div className='row p-0'>
                <FormGroup id='filter1' label='Grupos de alimentos' className='col-md-4'>
                    {groups
                        ? (
                            <SearchableSelect
                                isMulti
                                onChange={(e: any) => handleGroupsChange(e)}
                                value={groupsSelected}
                                name='groups_filter'
                                placeholder='grupos'
                                options={getGroupsOptions()}
                            />
                        )
                        : <Spinner isSmall className='ms-3' />
                    }
                </FormGroup>

                <FormGroup id='filter2' label='Alto contenido' className='col-md-4'>
                    {nutrients
                        ? (
                            <SearchableSelect
                                onChange={(e: any) => handleNutrientsChange(e)}
                                value={highIn}
                                name='nutrients_filter'
                                placeholder='nutrientes'
                                options={getNutrientsOptions()}
                            />
                        ) : <Spinner isSmall className='ms-3' />
                    }
                </FormGroup>

                <FormGroup id='filter3' label='Temporalidad' className='col-md-2'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <p className='m-0 d-flex align-content-center' style={{ height: '100%' }}> </p>
                                <div className='col-md-12'>
                                    <Select
                                        onChange={(e: any) => handleSeasonChange(e)}
                                        value={filters.filter_filters.season}
                                        id='season-select'
                                        list={monthsList} ariaLabel={''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormGroup>

                <div className='col-md-2 d-flex justify-content-end align-items-center'>
                    <Button color='light' onClick={() => _handleResetFilters()}>
                        Limpiar
                    </Button>
                </div>
            </div>
        </>
    )
}

export default FoodFilters;
import { useCallback, useEffect, useState } from 'react';
import { PatientService } from '../../services/patients/patientService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage clients fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de clientes.
----------------------------------------------------------------------------------------*/

const useClients = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchClients = useCallback(async () => {
        try {
            const service = new PatientService();
            const response = await service.getPatients(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.patients) {
                const mappedData = fetchedData.patients?.map((data: { id: string; name: string; lastName: string; }) => ({
                    value: data.id,
                    label: data.name + (data.lastName ? ' ' + data.lastName : ''),
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching clients:', error);
        }
    }, [filters]);

    useEffect(() => {
        fetchClients();
    }, []);

    const updateClientsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getClientsList = () => {
        return data;
    };

    return { fetchClients, getClientsList, updateClientsHookFilters };
}

export default useClients;
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import { toast } from "react-toastify";
import { FormService } from "../../../services/forms/formService";
import useFetch from "../../../hooks/useFetch";
import FormForm from "./FormForm";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";

const FormEdit: React.FC = () => {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = (await (new FormService()).getFormById(id as string));
        return response.getResponseData();
    }, [id]));

    const handleEditForm = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (await (new FormService()).editForm(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success('Formulario editado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar el formulario');
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) return <div className="text-center"><Spinner /></div>;

    if (error) return <ErrorMessage message={error.message} />;

    return <FormForm submit={handleEditForm} isLoading={isLoading} data={data} />;
}

export default FormEdit;
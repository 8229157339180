import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContainer } from './LoginContainer';
import { LoginForm } from './LoginForm';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { login } from '../../redux/authSlice';
import { toast } from 'react-toastify';
import { LoginService } from '../../services/auth/loginService';
import { saveUser } from '../../utils/DBUtils';
import React from 'react';

const Login: FC = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const loginService = new LoginService();
	const [loading, setLoading] = React.useState<boolean>(false);

	const handleLogin = async (username: string, password: string) => {
		setLoading(true);
		const response = await loginService.authUserCredentials(username, password);
		const responseData = response.getResponseData();

		if (!responseData.success) {
			toast.error(responseData.message);
			setLoading(false);
			return;
		}

		// store data in indexDb for service worker.
		try {
			saveUser(responseData.data);
		} catch (error) {
			toast.error('Error saving user to indexDb');
			return;
		} finally {
			setLoading(false);
		}

		dispatch(login(responseData.data));
		navigate('/appointments/calendar');
	};

	return (
		<LoginContainer>
			<LoginForm isLoading={loading} submit={handleLogin} />
		</LoginContainer>
	);
};

export default Login;
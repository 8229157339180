import React, { useCallback, useEffect, useState } from 'react';
import { MenuService } from '../../services/menus/menuService';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import { toast } from 'react-toastify';
import { handleConfirmationAlert } from '../../components/ConfirmationAlert';
import NutritionalMenuSelectors from './NutritionalMenuSelectors';
import { Menu, MenusApiResponse } from '../../type/clientMenus-type';
import DropContainer from './DropContainer';
import NutritionalMenuValues from './NutritionalMenuValues';
import useFetch from '../../hooks/useFetch';
import PatientDataModal from './PatientDataModal';
import EasyEdit from 'react-easy-edit';
import CreateTemplateModal from './CreateTemplateModal';
import { useFormik } from 'formik';
import { Intake, Day } from '../../type/menu-type';
import classNames from 'classnames';
import useHandleErrors from '../../utils/hooks/useHandleErrors';
import { useMenuProvider } from './providers/MenuProvider';
import MenuActions from './MenuActions';
import './styles/styles.scss';

interface MenuNutricionalProps {
    menuId: string;
    allergens: string[];
}

const NutritionalMenu: React.FC<MenuNutricionalProps> = ({ menuId, allergens }) => {

    const { displayAddItem, displayPatientData, setDisplayPatientData, displayCreateTemplate, setDisplayCreateTemplate } = useMenuProvider();
    const { handleErrors } = useHandleErrors();
    const menuService = new MenuService();

    const [nutValuesFrom, setNutValuesFrom] = useState<any>();
    const [changedNutValues, setChangedNutValues] = useState<boolean>(false);
    const [displayNutValues, setDisplayNutValues] = useState(false);
    const [displayColorDay, setDisplayColorDay] = useState<string | null>(null);
    const [displayColorIntake, setDisplayColorIntake] = useState<string | null>(null);
    const [showingNutValues, setShowingNutValues] = useState(false);

    const [data, loadingMenu, menuError, refetchMenu] = useFetch(useCallback(async () => {
        const response = (await menuService.getMenuById(menuId as string, null));
        return response.getResponseData() as Menu;
    }, []));

    // Obtiene los valores nutricionales de las ingestas por día
    const [intakesValuesPerDay, loadingIntakesValuesPerDay, intakesValuesPerDayError, refetchIntakesValuesPerDay] = useFetch(useCallback(async () => {
        const response = (await menuService.getIntakesValuesPerDay({ menu: menuId, ...nutValuesFrom }));
        return response.getResponseData() as Menu;
    }, [nutValuesFrom]));

    // Filtrar los objetos cuyo "name" sea igual a "energy", "carbohydrates", "proteins" o "fat"
    const filteredData = intakesValuesPerDay?.map((day: any) => {
        const filteredValues = day.values?.filter((item: any) =>
            ['energy', 'carbohydrates', 'proteins', 'fat'].includes(item.name)
        ).map((item: any) => {
            if (item.name === 'energy') {
                return {
                    ...item,
                    nombre: 'Kcal',
                };
            } else if (item.name === 'carbohydrates') {
                return {
                    ...item,
                    nombre: 'HC (g)',
                };
            } else if (item.name === 'proteins') {
                return {
                    ...item,
                    nombre: 'Prot (g)',
                };
            } else if (item.name === 'fat') {
                return {
                    ...item,
                    nombre: 'Lip (g)',
                };
            }
            return item;
        });

        return {
            ...day,
            values: filteredValues,
        };
    });

    // Valor máximo de día e ingesta para ordenar las columnas y filas
    const maxDayValue = data?.days.reduce((prev: number, current: Day) => (prev > current.value) ? prev : current.value, 0);
    const maxIntakeValue = data?.intakes.reduce((prev: number, current: Intake) => (prev > current.value) ? prev : current.value, 0);

    // Editar nombre del día/ingesta
    const _handleUpdateName = async (id: string, name: string, day: boolean) => {
        const serviceFunction = day ? menuService.editDay : menuService.editIntake;
        const errorMessage = day ? "Error al editar el día" : "Error al editar la ingesta";

        try {
            const response = await serviceFunction(id, name);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                refetchMenu();
            } else {
                toast.error(errorMessage);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };
    // Añadir día/ingesta al menú
    const _addRowCol = async (rowCol: string) => {
        try {
            const response = await (rowCol === "day"
                ? await (menuService).addRowCol(menuId, true, false)
                : await (menuService).addRowCol(menuId, false, true)).getResponseData();
            if (response.success) {
                refetchMenu();
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error(error.message || "Error al añadir " + rowCol === "day" ? "día" : "ingesta");
        }
    };

    // Cambiar orden de día/ingesta
    const _handleChangeOrderItem = async (day: boolean, id: string, order: string) => {
        try {
            const response = await (day
                ? await (menuService).changeDayOrder(id, order)
                : await (menuService).changeIntakeOrder(id, order)).getResponseData();
            if (response.success) {
                refetchMenu();
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al cambiar el orden del día");
        }
    };

    // Eliminar día
    const _deleteDay = async (dayId: string) => {
        try {
            const response = (await (new MenuService()).deleteDay(dayId)).getResponseData();
            if (response.success) {
                refetchMenu();
                setChangedNutValues(true);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al eliminar el día");
        }
    };

    // Eliminar ingesta
    const _deleteIntake = async (intakeId: string) => {
        try {
            const response = (await (new MenuService()).deleteIntake(intakeId)).getResponseData();
            if (response.success) {
                refetchMenu();
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error(error.message || "Error al eliminar la ingesta");
        }
    };

    // Cambiar el color de fondo del día seleccionado
    const _handleDayClick = (dayId: string) => {
        if (displayColorDay === dayId) {
            setDisplayColorDay(null);
            setNutValuesFrom(undefined);
        } else {
            setDisplayColorDay(dayId);
            setNutValuesFrom({ day: dayId });
        }
        refetchIntakesValuesPerDay();
        setDisplayColorIntake(null);
        setChangedNutValues(false);
        setDisplayNutValues(true);
    };

    const _handleIntakeClick = (intakeId: string) => {
        if (displayColorIntake === intakeId) {
            setDisplayColorIntake(null);
            setNutValuesFrom(undefined);
        } else {
            setDisplayColorIntake(intakeId);
            setNutValuesFrom({ intake: intakeId });
        }
        refetchIntakesValuesPerDay();
        setDisplayColorDay(null);
        setChangedNutValues(false);
        setDisplayNutValues(true);
    };

    // Setea a true el estado de changedNutValues cuando se cambia el valor de nutValues
    useEffect(() => {
        if (nutValuesFrom !== null) {
            setChangedNutValues(true);
            setDisplayNutValues(true);
        }
    }, [nutValuesFrom]);

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            day: true
        },
        onSubmit: async (values) => { _handleUpdateName(values.id, values.name, values.day) }
    });

    if (!data) return <></>;

    return (
        <div className='nutritional-menu'>
            {displayPatientData && data.client && (
                <PatientDataModal isOpen={displayPatientData} setIsOpen={setDisplayPatientData} patientId={data.client.id} />
            )}

            {displayCreateTemplate && data && (
                <CreateTemplateModal isOpen={displayCreateTemplate} setIsOpen={setDisplayCreateTemplate} menuId={menuId} menuName={data.name} />
            )}

            <div className="row">
                <div className="col-md-9 p-0">
                    <MenuActions
                        menuId={menuId}
                        data={data}
                        refetchMenu={refetchMenu}
                    />

                    {displayAddItem && <NutritionalMenuSelectors />}

                    <div className="table-responsive" id="menuTab">
                        <table className="menu-table table table-striped" style={{ width: '99%' }}>
                            <thead>
                                <tr>
                                    <th className='sticky-header'>
                                        <Button
                                            className="btn btn-light"
                                            onClick={() => _addRowCol("day")}
                                            title='Añadir día'
                                            isDisable={data.days.length >= 31}
                                        >
                                            <Icon icon="Add" color='secondary' size={'lg'} />
                                        </Button>
                                    </th>
                                    {data.days.map((day: Day) => (
                                        <th key={day.id} className={'sticky-header' + (displayColorDay === day.id ? ' selected-day' : '')}>
                                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                                <EasyEdit
                                                    type="text"
                                                    onSave={(e: any) => {
                                                        formik.setValues({ name: e, id: day.id, day: true });
                                                        formik.handleSubmit();
                                                    }}
                                                    onBlur={(e: any) => {
                                                        formik.setValues({ name: e, id: day.id, day: true });
                                                        formik.handleSubmit();
                                                    }}
                                                    value={day.name.length > 0 ? day.name : '---'}
                                                    attributes={{
                                                        className: 'easy-input',
                                                        onKeyDown: (e: React.KeyboardEvent) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault(); // Evitar un comportamiento inesperado
                                                                const target = e.target as HTMLInputElement;
                                                                formik.setValues({ name: target.value, id: day.id, day: true });
                                                                formik.handleSubmit();
                                                            }
                                                        },
                                                    }}
                                                    inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                                    placeholder=''
                                                />
                                            </form>

                                            {day.value > 1 && (
                                                <Button
                                                    className="btn p-0"
                                                    onClick={() => _handleChangeOrderItem(true, day.id, 'down')}
                                                    title='Izquierda'
                                                >
                                                    <Icon icon="KeyboardArrowLeft" size={'2x'} style={{ color: 'grey' }} />
                                                </Button>
                                            )}

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => _handleDayClick(day.id)}
                                                title='Valores nut. del día'
                                            >
                                                <Icon icon="Search" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    handleConfirmationAlert({
                                                        title: "Eliminar día",
                                                        text: "¿Está seguro que desea eliminar el día completo?",
                                                        icon: "warning",
                                                        onConfirm: () => {
                                                            _deleteDay(day.id);
                                                        }
                                                    })
                                                }}
                                                title='Eliminar día'
                                            >
                                                <Icon icon="HighlightOff" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            {day.value < maxDayValue && (
                                                <Button
                                                    className="btn p-0"
                                                    onClick={() => _handleChangeOrderItem(true, day.id, 'up')}
                                                    title='Derecha'
                                                >
                                                    <Icon icon="KeyboardArrowRight" size={'2x'} style={{ color: 'grey' }} />
                                                </Button>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {data.intakes.map((intake: Intake) => (
                                    <tr key={intake.id}>
                                        <td
                                            className={'sticky-column' + (displayColorIntake === intake.id ? ' selected-column' : '')}
                                            style={{ minWidth: '100px', width: '110px', minHeight: '150px', padding: '5px' }}
                                        >
                                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                                <EasyEdit
                                                    type="text"
                                                    onSave={(e: any) => {
                                                        formik.setValues({ name: e, id: intake.id, day: false });
                                                        formik.handleSubmit();
                                                    }}
                                                    onBlur={(e: any) => {
                                                        formik.setValues({ name: e, id: intake.id, day: false });
                                                        formik.handleSubmit();
                                                    }}
                                                    value={intake.name.length > 0 ? intake.name : '---'}
                                                    attributes={{
                                                        className: 'easy-input',
                                                        onKeyDown: (e: React.KeyboardEvent) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault(); // Evitar un comportamiento inesperado
                                                                const target = e.target as HTMLInputElement;
                                                                formik.setValues({ name: target.value, id: intake.id, day: false });
                                                                formik.handleSubmit();
                                                            }
                                                        },
                                                    }}
                                                    inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                                    placeholder=''
                                                />
                                            </form>

                                            {intake.value > 1 && (
                                                <Button
                                                    className="btn p-0"
                                                    onClick={() => _handleChangeOrderItem(false, intake.id, 'down')}
                                                    title='Arriba'
                                                >
                                                    <Icon icon="KeyboardArrowUp" size={'2x'} style={{ color: 'grey' }} />
                                                </Button>
                                            )}

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => _handleIntakeClick(intake.id)}
                                                title='Valores nut. por ingesta'
                                            >
                                                <Icon icon="Search" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    handleConfirmationAlert({
                                                        title: "Eliminar ingesta",
                                                        text: "¿Está seguro que desea eliminar la ingesta completa?",
                                                        icon: "warning",
                                                        onConfirm: () => { _deleteIntake(intake.id) }
                                                    })
                                                }}
                                                title='Eliminar ingesta'
                                            >
                                                <Icon icon="HighlightOff" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            {intake.value < maxIntakeValue && (
                                                <Button
                                                    className="btn p-0"
                                                    onClick={() => _handleChangeOrderItem(false, intake.id, 'up')}
                                                    title='Abajo'
                                                >
                                                    <Icon icon="KeyboardArrowDown" size={'2x'} style={{ color: 'grey' }} />
                                                </Button>
                                            )}

                                            <div className='row'>
                                                <div className='col-12'>
                                                    {filteredData && displayNutValues && (
                                                        <>
                                                            {filteredData?.map((intakeFiltered: any) => (
                                                                intakeFiltered.id === intake.id && (
                                                                    <div key={intakeFiltered.id}>
                                                                        {
                                                                            intakeFiltered.values?.map((item: any) => (
                                                                                <p key={item.name} className='m-0 text-muted'>
                                                                                    {item.nombre + ' - ' + Math.round(item.value)}
                                                                                </p>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                )
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </td>

                                        {data.days.map((day: Day) => (
                                            <td key={`${intake.id}-${day.id}`} className="menu-cell" draggable style={{ width: '50px' }}>
                                                <DropContainer
                                                    menuId={menuId}
                                                    day={day} intake={intake}
                                                    menuItems={data.menuItems}
                                                    allergens={allergens}
                                                    aversions={data.client?.clientAversions}
                                                    refetch={refetchMenu}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ))}

                                <tr>
                                    <td className='sticky-column'>
                                        <Button
                                            className="btn btn-light sticky-column"
                                            onClick={() => _addRowCol("intake")}
                                            title='Añadir ingesta'
                                            isDisable={data.intakes.length >= 10}
                                        >
                                            <Icon icon="Add" color='secondary' size={'lg'} />
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={classNames("col-md-3 ps-3 p-0", { 'd-none': !changedNutValues || !data?.menuItems?.length })}>
                    <NutritionalMenuValues nutValues={nutValuesFrom} clientId={data?.client?.id} />
                </div>
            </div>
        </div>
    )
};

export default NutritionalMenu;
export const COLORS = [
    "#313e61",
    "#e07a5f",
    "#AF19FF",
    "#FF8042",
    "#FFBB28",
    "#0088FE",
    "#91a046",
    "#81abbc",
    "#00C49F",
    "#74bdcb",
    "#ffa384",
    "#fe4a3b",
    "#f5b461",
];
import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MenuService } from "../../services/menus/menuService";
import useFetch from "../../hooks/useFetch";
import { MenusApiResponse } from "../../type/clientMenus-type";
import ErrorMessage from "../ErrorMessage";
import { toast } from "react-toastify";
import { CustomTable } from "../table/CustomTable";
import moment from "moment";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import { menusMenu } from "../../menu";
import useHandleErrors from "../../utils/hooks/useHandleErrors";
import AssignTemplateModal from "../../pages/menus/menu-list/components/AssignTemplateModal";
import CloneModal from "../../pages/menus/menu-list/components/CloneModal";

interface MenusTabProps {
    filters: any;
    updateFilters: any;
    updateFilterOrder: any;
    updatePage: any;
    updatePageSize: any;
    page: string;
}

const MenusTab: FC<MenusTabProps> = ({ filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, page }) => {

    const { id = "" } = useParams();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();

    const [selectedMenuId, setSelectedMenuId] = useState<string>('');
    const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);

    const [menusData, fetchingMenus, menusError, refetchMenus] = useFetch(useCallback(async () => {
        const menuService = new MenuService();
        const response = await menuService.listMenusFilters(filters);
        return response.getResponseData() as MenusApiResponse;
    }, [filters]));

    const _handleSendMenuToPatient = async (menuId: string) => {
        try {
            const response = await (await (new MenuService()).sendMenuToClient(menuId)).getResponseData();
            if (response.success) {
                toast.success('Menú enviado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al enviar el menú al paciente");
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new MenuService()).deleteMenu(id)).getResponseData();
            if (response.success) {
                refetchMenus();
                updateFilters({ ...filters });

                setTimeout(() => {
                    toast.success('Menú eliminado correctamente');
                }, 100);
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el menú');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el menú');
        }
    };

    const getContent = () => {
        if (menusError) return <ErrorMessage message={menusError} />;

        if (menusData && menusData.menus.length > 0) {
            let apiData = menusData as MenusApiResponse;

            return (
                <CustomTable
                    title="Menús nutricionales"
                    loading={fetchingMenus}
                    data={apiData?.menus ? apiData.menus : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as MenusApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => { updatePage({ selected: page.selected + 1 }) },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div
                                        className="cursor-pointer text-secondary"
                                        onClick={() => { navigate(menusMenu.menus.path + `/${element.id}/view`) }}
                                    >
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Fecha de creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return <>{moment(element.createdAt.date).format('DD/MM/YYYY')}</>
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Asignar",
                            icon: "PersonAdd",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Asignar menú al paciente",
                            hidden: (page !== 'list') || (filters.filter_filters?.is_template !== 1),
                            click: (item: any) => {
                                setSelectedMenuId(item.id)
                                setIsOpen(true)
                            }
                        },
                        {
                            title: "Enviar",
                            icon: "ForwardToInbox",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Enviar menú al paciente",
                            click: (item: any) => { _handleSendMenuToPatient(item.id) }
                        },
                        {
                            title: "Clonar",
                            icon: "ContentCopy",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Clonar menú",
                            click: (item: any) => { setOpenCloneModal(true); setSelectedMenuId(item.id) },
                        },
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar menú",
                            click: (item: any) => {
                                navigate(menusMenu.menus.path + `/${item.id}/view`);
                            },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar menú",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar menú",
                                    text: "¿Está seguro que desea eliminar el menú?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            );
        }
    };

    return (
        <>
            {getContent()}
            {isOpen && <AssignTemplateModal isOpen={isOpen} setIsOpen={setIsOpen} menuId={selectedMenuId} refetch={refetchMenus} />}
            {openCloneModal && <CloneModal isOpen={openCloneModal} setIsOpen={setOpenCloneModal} menuId={selectedMenuId} refetch={refetchMenus} isPatientProfile={page === 'patient'} />}
        </>
    );
};

export default MenusTab;
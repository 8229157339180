import { FC } from "react";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { FormService } from "../../../services/forms/formService";
import { useFormik } from "formik";
import Button from "../../../components/bootstrap/Button";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import Spinner from "../../../components/bootstrap/Spinner";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import { useNavigate } from "react-router-dom";
import Icon from "../../../components/icon/Icon";
import EasyEdit from 'react-easy-edit';
import IconWithTooltip from "../../../components/bootstrap/IconWithTooltip";
import '../../../components/menu/styles/styles.scss';
import "./../../../styles/styles.scss";

interface ListFillValuesProps {
    data: any;
    loading: boolean;
    refetch: () => void;
}

interface IFieldsArray {
    form_fill_value_id: string;
    value: number | string;
}

interface FieldsForm {
    formFill: string;
    fields: IFieldsArray[];
}

interface FormFill {
    formFill: string;
    name: string;
}

const ListFillValues: FC<ListFillValuesProps> = ({ data, loading, refetch }) => {
    const navigate = useNavigate();

    // Obtener la id del form_fill
    const fillId = data?.id ? data.id : '';

    // Valores iniciales del formulario
    const formFieldInitialValues: FieldsForm = {
        formFill: fillId,
        fields: data.formFillValues?.map(
            (field: any) => (
                {
                    form_fill_value_id: field.id,
                    value: field.value || ""
                }
            )) || []
    };

    // Controlar el cambio de valor de un campo
    const _handleFieldValueChange = (formFillValueId: string, value: number | string) => {
        const updatedFields = formik.values.fields.map((field: IFieldsArray) => {
            // Si la id del campo es igual a la id del campo que se está modificando, se actualiza el valor
            if (field.form_fill_value_id === formFillValueId) {
                return { ...field, value };
            }
            return field;
        });

        formik.setFieldValue('fields', updatedFields);
    };

    const _handleEditFillName = async (values: FormFill) => {
        try {
            const response = await (await new FormService().editFill(values)).getResponseData();

            if (!response.success) {
                toast.error(response.message || 'Error al actualizar el nombre');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al actualizar el nombre');
        }
    };

    // Actualizar el valor de un campo
    const handleUpdate = async (values: FieldsForm) => {
        try {
            const response = await (await new FormService().editFieldsValues(values)).getResponseData();

            if (response.success) {
                refetch();
                navigate(-1);

                setTimeout(() => {
                    toast.success('Valores del formulario actualizados');
                }, 100);
            } else {
                toast.error(response.message || 'Error al actualizar el campo');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al actualizar el campo');
        }
    };

    const formik = useFormik<FieldsForm>({
        initialValues: formFieldInitialValues,
        onSubmit: values => {
            handleUpdate(values);
        }
    });

    const formikFill = useFormik<FormFill>({
        initialValues: {
            formFill: '',
            name: ''
        },
        onSubmit: values => {
            _handleEditFillName(values);
        }
    });

    if (loading) return <div className="d-flex justify-content-center"><Spinner /></div>;

    if (!data) return <ErrorMessage />;

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>Editar Valores</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button color='primary' onClick={() => formik.handleSubmit()}>Guardar</Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='sm' className="w-25">
                <Card>
                    <CardBody>
                        <CardTitle className="d-flex justify-content-center">
                            <form onSubmit={formikFill.handleSubmit} autoComplete="off">
                                <EasyEdit
                                    type="text"
                                    onSave={(e: any) => {
                                        formikFill.setFieldValue('name', e)
                                        formikFill.setFieldValue('formFill', data.id)
                                        formikFill.handleSubmit()
                                    }}
                                    value={data.name}
                                    placeholder={data.name}
                                    attributes={{ className: 'easy-input' }}
                                    saveButtonLabel={<Icon icon='Check' color='dark' />}
                                    cancelButtonLabel={<Icon icon='Close' color='dark' title='Cancelar' />}
                                    inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                />
                            </form>
                        </CardTitle>

                        <form onSubmit={formik.handleSubmit} autoComplete="off" className="d-flex justify-content-center mt-4">
                            <div className="row w-75">
                                {data.formFillValues
                                    ? (
                                        data.formFillValues.map((field: any) => {
                                            const fieldValue = formik.values.fields.find(
                                                (item: IFieldsArray) => item.form_fill_value_id === field.id
                                            )?.value;

                                            return (
                                                <FormGroup key={field.formField.id} className="col-md-12 mt-3">
                                                    <label htmlFor={field.formField.name} className="labelStyle">
                                                        {field.formField.name}
                                                        {field.formField.required && <span className="text-danger"> *</span>}
                                                        {
                                                            field.formField.description && (
                                                                <IconWithTooltip
                                                                    icon="Info"
                                                                    label={field.formField.description}
                                                                />
                                                            )
                                                        }
                                                    </label>
                                                    <Input
                                                        type={field.formField.type}
                                                        name={field.formField.name}
                                                        value={fieldValue}
                                                        onChange={(e: any) => _handleFieldValueChange(field.id, e.target.value)}
                                                        required={field.formField.required}
                                                    />
                                                </FormGroup>
                                            );
                                        })
                                    )
                                    : (
                                        <div className="text-center">
                                            <h5 className="text-muted mt-5">No hay campos en este formulario</h5>
                                        </div>
                                    )
                                }
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Page>
        </>
    );
};

export default ListFillValues;
import { FC, useCallback, useState } from 'react'
import FormGroup from '../../../components/bootstrap/forms/FormGroup'
import Spinner from '../../../components/bootstrap/Spinner'
import { FoodService } from '../../../services/foods/foodService'
import useFetch from '../../../hooks/useFetch'
import SearchableSelect from '../../../components/select/SearchableSelect'
import Button from '../../bootstrap/Button'
import { TECHNIQUES } from '../../../utils/FoodUtils'

interface IRecipeFiltersProps {
    updateFilters: (filters: any) => void;
    resetFilters: () => void;
}

const RecipeFilters: FC<IRecipeFiltersProps> = ({ updateFilters, resetFilters }) => {

    const [techniques, setTechniques] = useState<any[]>([]);
    const [groupsSelected, setGroupsSelected] = useState<any>([]);
    const [hightIn, setHightIn] = useState<any[]>([]);

    const [groups] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getAlimentGroups();
        return response.getResponseData();
    }, []));

    const [nutrients] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getNutrientsList();
        return response.getResponseData();
    }, []));

    const handleTechniquesChange = (e: any) => {
        setTechniques(e);
        updateFilters({ techniques: e.map((technique: { value: string, label: string }) => technique.value) });
    }

    const handleGroupsChange = (e: any) => {
        setGroupsSelected(e);
        updateFilters({ group_food: e.map((group: any) => group.value) });
    }

    const handleNutrientsChange = (e: any) => {
        setHightIn(e);
        updateFilters({ high_in: e.value });
    }

    // Obtener la lista de grupos de alimentos
    const getGroupsOptions = () => {
        if (groups) {
            return groups.food_groups.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    // Obtener la lista de nutrientes
    const getNutrientsOptions = () => {
        if (nutrients) {
            return nutrients.map((option: { name: string; nombre: string; }) => {
                return {
                    value: option.name,
                    label: option.nombre
                }
            })
        }
        return [];
    }

    // Resetear los filtros
    const _handleResetFilters = () => {
        setTechniques([]);
        setGroupsSelected([]);
        setHightIn([]);
        resetFilters();
    }

    const getContent = () => {
        return (
            <>
                <div className='row p-0 d-flex justify-content-end'>
                    <div className='col-md-3'>
                        <FormGroup id='filter0' label='Técnicas culinarias'>
                            <SearchableSelect
                                isMulti
                                onChange={(e: any) => handleTechniquesChange(e)}
                                value={techniques}
                                name='techniques_filter'
                                placeholder='técnicas culinarias'
                                options={TECHNIQUES}
                            />
                        </FormGroup>
                    </div>

                    <div className='col-md-3'>
                        <FormGroup id='filter1' label='Grupos de alimentos'>
                            <SearchableSelect
                                isMulti
                                onChange={(e: any) => handleGroupsChange(e)}
                                value={groupsSelected}
                                name='groups_filter'
                                placeholder='grupos'
                                options={getGroupsOptions()}
                            />
                        </FormGroup>
                    </div>

                    <div className='col-md-3'>
                        <FormGroup id='filter2' label='Alto contenido'>
                            <SearchableSelect
                                onChange={(e: any) => handleNutrientsChange(e)}
                                value={hightIn}
                                name='nutrients_filter'
                                placeholder='nutrientes'
                                options={getNutrientsOptions()}
                            />
                        </FormGroup>
                    </div>

                    <div className='col-md-2 d-flex justify-content-end align-items-center'>
                        <div>
                            <Button className='btn btn-light w-auto' isLight onClick={() => _handleResetFilters()}>
                                Limpiar
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {groups && nutrients ? getContent() : <div className='text-center'><Spinner /></div>}
        </>
    )
}

export default RecipeFilters;
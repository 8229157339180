import { RestServiceConnection } from '../restServiceConnection';

const TIPS_ENDPOINT = '/tips';

export class TipsService extends RestServiceConnection {

    listTips = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    getTip = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/get',
            data: {
                tip: id
            }
        }, true);
        return this;
    }

    createTip = async (tip: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/create',
            data: {
                ...tip
            }
        }, true);
        return this;
    }

    editTip = async (tip: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/edit',
            data: {
                ...tip
            }
        }, true);
        return this;
    }

    deleteTip = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/delete',
            data: {
                tip: id
            }
        }, true);
        return this;
    }

    assignTipsToPatient = async (client: string, tips?: any, tipGroupId?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/assign-tips',
            data: {
                tips: tips,
                client: client,
                tipGroupId: tipGroupId
            }
        }, true);
        return this;
    }

    toggleTipStatus = async (tip: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/toggle-tip',
            data: { tip: tip }
        }, true);
        return this;
    }

    addDocument = async (file: File, tip: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/add-document',
            data: {
                tipDocument: file,
                tip: tip
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    downloadDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: TIPS_ENDPOINT + '/download-document',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: {
                document: id
            }
        }, true);
        return this;
    }

}
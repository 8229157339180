import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import calendarReducer from './calendarSlice';
import clientReducer from './clientSlice';
import recipeReducer from './recipeSlice';
import { loadState } from './browser-storage';

const store = configureStore({
  reducer: {
    calendar: calendarReducer,
    auth: authReducer,
    client_profile: clientReducer,
    recipes: recipeReducer,
  },
  preloadedState: loadState(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
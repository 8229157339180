import { FC } from "react";
import { CardFooter, CardTitle } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { FormService } from "../../../services/forms/formService";
import { useFormik } from "formik";
import Button from "../../../components/bootstrap/Button";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import Spinner from "../../../components/bootstrap/Spinner";
import Icon from "../../../components/icon/Icon";
import EasyEdit from 'react-easy-edit';
import IconWithTooltip from "../../../components/bootstrap/IconWithTooltip";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import SearchableSelect from "../../../components/select/SearchableSelect";
import moment from "moment";
import * as yup from 'yup';
import '../../../components/menu/styles/styles.scss';
import "./../../../styles/styles.scss";

interface EditFormFillValuesProps {
    data: any;
    loading: boolean;
    setIsOpen?: (...args: unknown[]) => unknown;
    refetch?: () => void;
}

interface IFieldsArray {
    form_fill_value_id: string;
    value: number | string | string[];
}

interface FieldsForm {
    formFill: string;
    publicAt: Date | string;
    fields: IFieldsArray[];
}

interface FormFill {
    formFill: string;
    name: string;
}

const schema = yup.object({
    publicAt: yup.date().required('La fecha es obligatoria'),
    fields: yup.array().of(
        yup.object().shape({ value: yup.mixed() })
    )
});

const EditFormFillValues: FC<EditFormFillValuesProps> = ({ data, loading, setIsOpen, refetch }) => {

    const fillId = data?.id ? data.id : ''; // Obtener la id del form_fill
    const { handleErrors } = useHandleErrors();

    // Controlar el cambio de valor de un campo
    const _handleFieldValueChange = (formFillValueId: string, value: number | string | string[]) => {
        const updatedFields = formik.values.fields.map((field: IFieldsArray) => {
            // Si la id del campo es igual a la id del campo que se está modificando, se actualiza el valor
            if (field.form_fill_value_id === formFillValueId) {
                // Si el campo es de tipo multi-select, se guarda un array con los valores seleccionados
                if (Array.isArray(value)) {
                    return { ...field, value: value };
                }
                return { ...field, value };
            }
            return field;
        });
        formik.setFieldValue('fields', updatedFields);
    };

    const _handleEditFillName = async (values: FormFill) => {
        try {
            const response = await (await new FormService().editFill(values)).getResponseData();
            if (!response.success) {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al actualizar el nombre');
        }
    };

    const handleUpdate = async (values: FieldsForm) => {
        try {
            const response = await (await new FormService().editFieldsValues(values)).getResponseData();
            if (response.success) {
                refetch && refetch();
                setIsOpen && setIsOpen(false);
                toast.success('Valores del formulario actualizados');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al actualizar el campo');
        }
    };

    const formik = useFormik<FieldsForm>({
        initialValues: {
            formFill: fillId,
            publicAt: moment(data?.publicAt?.date).format('YYYY-MM-DD HH:mm:ss') || moment().format('YYYY-MM-DD HH:mm:ss'),
            fields: data?.formFillValues?.map((field: any) => ({
                form_fill_value_id: field.id,
                value: field.value || "",
            })) || []
        },
        validationSchema: schema,
        onSubmit: values => handleUpdate(values)
    });

    const formikFill = useFormik<FormFill>({
        initialValues: {
            formFill: '',
            name: '',
        },
        onSubmit: values => _handleEditFillName(values)
    });

    const verifyClass = (inputFieldID: keyof FieldsForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''; }

    const showErrors = (inputFieldID: keyof FieldsForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    if (loading) return <div className="d-flex justify-content-center"><Spinner /></div>;

    if (!data) return <ErrorMessage />;

    return (
        <>
            <CardTitle className="d-flex justify-content-center">
                <form onSubmit={formikFill.handleSubmit} autoComplete="off">
                    <EasyEdit
                        type="text"
                        onSave={(e: any) => {
                            formikFill.setFieldValue('name', e)
                            formikFill.setFieldValue('formFill', data.id)
                            formikFill.handleSubmit()
                        }}
                        value={data.name}
                        placeholder={data.name}
                        attributes={{ className: 'easy-input' }}
                        saveButtonLabel={<Icon icon='Check' color='dark' />}
                        cancelButtonLabel={<Icon icon='Close' color='dark' title='Cancelar' />}
                        inputStyle={{ width: '50px', minWidth: '50px', maxWidth: '60px' }}
                    />
                </form>
            </CardTitle>

            <form onSubmit={formik.handleSubmit} autoComplete="off" className="row mt-2">
                <FormGroup requiredInputLabel label='Fecha de publicación:' className="col-md-12 d-flex flex-column justify-content-center align-items-center mb-5">
                    <Input
                        key={data?.publicAt?.date}
                        id="publicAt"
                        type={'datetime-local'}
                        step={300}
                        onBlur={formik.handleBlur}
                        value={formik.values.publicAt ? moment(formik.values.publicAt).format('YYYY-MM-DD HH:mm') : ''}
                        onChange={(e: any) => formik.setFieldValue('publicAt', moment(e.target.value).format('YYYY-MM-DD HH:mm:ss'))}
                        className={verifyClass('publicAt') + ' w-25'}
                    />
                    {showErrors('publicAt')}
                </FormGroup>

                {data.formFillValues
                    ? (
                        data.formFillValues.map((field: any) => {
                            const fieldValue = formik.values.fields.find(
                                (item: IFieldsArray) => item.form_fill_value_id === field.id
                            )?.value;

                            const parsedOptions = JSON.parse(field?.formField?.options);

                            return (
                                <FormGroup key={field.formField.id} className={`col-md-${field.formField.columnSize} mb-2`}>
                                    <label htmlFor={field.formField.name} className="labelStyle">
                                        {field.formField.name}
                                        {field.formField.required && <span className="text-danger"> *</span>}
                                        {field.formField.description && <IconWithTooltip icon="Info" label={field.formField.description} />}
                                    </label>

                                    {field.formField.type === 'select' || field.formField.type === 'multi-select'
                                        ? (
                                            // si el tipo es select o multi-select
                                            <SearchableSelect
                                                name={field.formField.name}
                                                isMulti={field.formField.type === 'multi-select'}
                                                required={field.formField.required}
                                                isClearable
                                                options={parsedOptions?.map((option: any) => ({
                                                    value: option,
                                                    label: option,
                                                }))}
                                                value={
                                                    field.formField.type === 'select'
                                                        ? { value: fieldValue, label: fieldValue }
                                                        : (field.formField.type === 'multi-select') && Array.isArray(fieldValue)
                                                            ? fieldValue.map((item: any) => ({ value: item, label: item }))
                                                            : fieldValue
                                                }
                                                onChange={(e: any) => {
                                                    field.formField.type === 'multi-select'
                                                        ? _handleFieldValueChange(field.id, e?.map((item: any) => item.value))
                                                        : _handleFieldValueChange(field.id, e?.value);
                                                }}
                                                placeholder=""
                                            />
                                        ) : (
                                            // si el tipo es text, number o date
                                            <Input
                                                type={field.formField.type}
                                                name={field.formField.name}
                                                value={fieldValue}
                                                onChange={(e: any) => _handleFieldValueChange(field.id, e.target.value)}
                                                required={field.formField.required}
                                            />
                                        )}
                                </FormGroup>
                            );
                        })
                    )
                    : (
                        <div className="text-center">
                            <h5 className="text-muted mt-5">No hay campos en este formulario</h5>
                        </div>
                    )
                }
            </form>

            <CardFooter className="d-flex justify-content-end mt-3">
                <Button color='primary' onClick={() => { formik.handleSubmit() }}>
                    Guardar
                </Button>
            </CardFooter>
        </>
    );
};

export default EditFormFillValues;
import { FC, useEffect, useRef, useState } from "react";
import SearchableSelect from "../../../components/select/SearchableSelect";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import FormLabel from "../../../components/FormLabel";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import { toast } from "react-toastify";
import IconWithTooltip from "../../../components/bootstrap/IconWithTooltip";
import { RecipeService } from "../../../services/recipes/recipeService";
import CustomSearchSelect from "../../../components/select/CustomSearchSelect";
import CustomNumberInput from "../../../components/bootstrap/forms/CustomNumberInput";

interface IngredientsProps {
    ingredientsList: any[],
    setIngredientsList: any,
    selectedIngredient: any,
    setSelectedIngredient: any,
    ingredientQuantity: any,
    setIngredientQuantity: any,
    inputError: any,
    setInputError: any,
    foodList: any[],
    foodsData: any,
}

const Ingredients: FC<IngredientsProps> = ({
    ingredientsList, setIngredientsList,
    selectedIngredient, setSelectedIngredient,
    ingredientQuantity, setIngredientQuantity,
    inputError, setInputError,
    foodList, foodsData,
}) => {

    const timeoutRef = useRef<any>(null);

    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState<any>([]);
    const [localIngredientList, setLocalIngredientList] = useState<any[]>(ingredientsList);
    const [deletingItem, setDeletingItem] = useState<boolean>(false);

    // Get ingredient's list from API
    const getIngredientsOptions = async (searchText: string) => {
        const recipesService = new RecipeService();
        const response = (await recipesService.getIngredientsByCompany({ limit: 1000000, filter_filters: { search_array: searchText } })).response;

        if (response?.status === 200) {
            const options = response?.data?.data?.data?.map((item: { id: string; name: string }) => ({ value: item.id, label: item.name }));
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
            setOptions(options);
        }
    };

    // Update the selected ingredient
    const handleIngredientChange = (selectedOption: any) => {
        let ingredientSelected = foodsData.data?.find((ingredient: any) => ingredient.name === selectedOption.label);

        if (!ingredientSelected) {
            toast.error('No se ha encontrado el ingrediente seleccionado.');
        }

        setSelectedIngredient(ingredientSelected);
        setIngredientQuantity(ingredientSelected?.value);
    };

    // Add an ingredient to the recipe
    const _handleAddIngredient = (selectedIngredient: any, ingredientQuantity: number) => {
        if (!selectedIngredient) return toast.error('No se ha seleccionado ningún ingrediente');
        if (!ingredientQuantity) return toast.error('No se ha introducido el peso');
        if (ingredientQuantity < 0.0000000000001) return toast.error('No puedes introducir 0g o menos');
        if (inputError) return toast.error('El formato de la cantidad de los ingredientes es incorrecto');

        const ingredient = {
            ingredient: '',
            food: selectedIngredient.id,
            name: selectedIngredient.name,
            quantity: ingredientQuantity,
            order_number: ingredientsList.length + 1,
            company: selectedIngredient.companyFood?.id
        };

        // Añadir el ingrediente a la lista de ingredientes
        const newIngredients = [...ingredientsList, ingredient];
        setIngredientsList(newIngredients);

        // Limpiar los campos para poder añadir otro ingrediente
        setSelectedIngredient(null);
        setIngredientQuantity(0);
    };

    // Delete an ingredient from the recipe
    const handleDeleteIngredient = (index: number) => {
        setDeletingItem(true);

        const newIngredients = [...localIngredientList];
        newIngredients.splice(index, 1);

        setIngredientsList(newIngredients); // Actualiza el listado de ingredientes del componente padre
        setTimeout(() => {
            setLocalIngredientList(newIngredients);
            setDeletingItem(false);
        }, 300);
    };

    // Update the ingredient's quantity
    const handleQuantityChange = (value: string) => {
        if (parseFloat(value) < 0) {
            toast.error('No puedes introducir una cantidad negativa');
        } else {
            setIngredientQuantity(value);
        }
    };

    // Update the ingredient's quantity from the ingredient's list
    const handleQuantitiesChange = (value: string, index: number) => {
        const newIngredients = [...localIngredientList];
        parseFloat(value) < 0
            ? toast.error('No puedes introducir una cantidad negativa')
            : newIngredients[index].quantity = value;
        setIngredientsList(newIngredients); // Actualiza el listado de ingredientes del componente padre
    };

    // Update the ingredient's list when the local list of ingredients changes
    useEffect(() => {
        setLocalIngredientList(ingredientsList);
    }, [ingredientsList]);

    // Get the ingredients options when the search text changes
    useEffect(() => {
        if (searchText !== '' && searchText.length > 2) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                getIngredientsOptions(searchText)
            }, 500);
            return () => {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [searchText]);

    return (
        <Card stretch={false}>
            <CardHeader className='rounded-1 mb-0'>
                <CardLabel icon='SetMeal' iconColor='secondary'>
                    <CardTitle>Ingredientes</CardTitle>
                </CardLabel>
            </CardHeader>

            <CardBody>
                <div className="row mt-2">
                    <div className="col-md-12 mb-2">
                        <label className="labelStyle">Añade un ingrediente</label>
                        <IconWithTooltip
                            icon="Info"
                            label="Introduce la cantidad que desea que el paciente consuma. En su lista de la compra aparecerá el peso real del alimento (Ejemplo: Introduciendo aquí 100g de almejas, en la lista de la compra del paciente aparecerán 179g para comprar)"
                        />
                    </div>

                    <CustomSearchSelect
                        id='client-select'
                        options={options}
                        onInputChange={setSearchText}
                        onChangeSingle={handleIngredientChange}
                        defaultValue={selectedIngredient ? options.find((option: any) => option.value === selectedIngredient?.key) : null}
                        className="col-md-7"
                    />

                    {/* The input will only appear when an option is selected from the ingredients select */}
                    {(selectedIngredient !== null) && (
                        <div className="col-md-5">
                            <>
                                <div className="row">
                                    <CustomNumberInput
                                        cols="10"
                                        id="quantity-value"
                                        value={ingredientQuantity}
                                        onChange={handleQuantityChange}
                                        onKeyDown={(e: any) => {
                                            const allowedKeys = /[0-9.,]/;
                                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                                e.preventDefault();
                                            }
                                            if (e.key === 'Enter') {
                                                _handleAddIngredient(selectedIngredient, ingredientQuantity);
                                            }
                                        }}
                                        placeholder="Peso sin desperdicio (g)"
                                        smallPlaceholder
                                        setError={setInputError}
                                    />

                                    <Button
                                        icon="Save"
                                        color='brand'
                                        isLight
                                        onClick={() => _handleAddIngredient(selectedIngredient, ingredientQuantity)}
                                        className="col-md-1"
                                    />
                                </div>
                            </>
                        </div>
                    )}

                    <div className="col-md-8 mt-3">
                        <FormLabel label="Nombre del ingrediente" />
                    </div>

                    <div className="col-md-4 mt-3">
                        <FormLabel label="Peso sin desperdicio (g)" />
                    </div>
                </div>

                {deletingItem === false && localIngredientList.map((ingredient: any, index: number) => {
                    const defaultFoodValue = { value: ingredient.food, label: ingredient.name };
                    return (
                        <div className="row mt-2" key={'select-ingredient-' + ingredient.name}>
                            <div className="col-md-8">
                                <SearchableSelect
                                    isSearchable
                                    name={ingredient.food}
                                    defaultValue={defaultFoodValue}
                                    options={foodList}
                                    onChange={(e: any) => {
                                        const newIngredients = [...localIngredientList];
                                        newIngredients[index].food = e.value;
                                        newIngredients[index].name = e.label;
                                        setIngredientsList(newIngredients);
                                    }}
                                />
                            </div>

                            <CustomNumberInput
                                cols="3"
                                id={`ingredients[${index}].quantity`}
                                value={ingredient.quantity}
                                onChange={(e) => handleQuantitiesChange(e, index)}
                                placeholder="Peso sin desperdicio (g)"
                                min={0}
                                setError={setInputError}
                            />

                            <div className="col-md-1 d-flex justify-content-end">
                                <Button
                                    icon='Delete'
                                    title="Eliminar ingrediente"
                                    onClick={() => {
                                        handleConfirmationAlert({
                                            title: "Eliminar ingrediente",
                                            text: "¿Está seguro que desea eliminar el ingrediente?",
                                            icon: "warning",
                                            onConfirm: () => { handleDeleteIngredient(index) }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
            </CardBody>
        </Card>
    )
}

export default Ingredients;
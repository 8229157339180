import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Activity } from "../../../type/activities-type";
import { ActivitiesService } from "../../../services/activities/activitiesService";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import ActivityForm from "../ActivityForm";
import { menusMenu } from "../../../menu";

const ActivityEdit: React.FC = () => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const activitiesService = new ActivitiesService();

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = (await activitiesService.getActivity(id as string));
        return response.getResponseData() as Activity;
    }, [id]));

    const _handleUpdate = async (values: any) => {
        values.physicalActivity = id;

        try {
            const response = await activitiesService.editActivity(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(menusMenu.menus.subMenu.activities.path, { replace: true });
                setTimeout(() => {
                    toast.success('Ejercicio físico editado correctamente');
                }, 100);
            } else {
                toast.error(responseData.message || "Error al editar el ejercicio físico");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    if (loading) return <div className="text-center"><Spinner /></div>;

    if (error) return <ErrorMessage message={error.message} />;

    const activityData = {
        name: data.name !== null ? data.name : "",
        description: data.description !== null ? data.description : "",
        time: data.time !== null ? data.time : 0,
        calories: data.calories !== null ? data.calories : 0,
        img: data.img !== null ? data.img : "",
    };

    return <ActivityForm isLoading={false} submit={_handleUpdate} activityData={activityData} />;
};

export default ActivityEdit;
import { FC, Fragment, useCallback, useState } from 'react'
import Icon from '../../../../components/icon/Icon'
import Button from '../../../../components/bootstrap/Button'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Spinner from '../../../../components/bootstrap/Spinner'
import useFetch from '../../../../hooks/useFetch'
import SearchableSelect from '../../../../components/select/SearchableSelect'
import { RecipeService } from '../../../../services/recipes/recipeService'
import Select from '../../../../components/bootstrap/forms/Select'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import { DIFFICULTY, NUTRIENTS, RATION_PRESENTATION, TECHNIQUES } from '../../../../utils/FoodUtils'
import { FoodService } from '../../../../services/foods/foodService'

interface IUsersFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    updateFilterOrder: (keyValue: string, order: "asc" | "desc") => void
    filters: any
}

const RecipesFilters: FC<IUsersFiltersProps> = ({ updateFilters, filters, resetFilters, updateFilterOrder }) => {

    const [filterMenu, setFilterMenu] = useState(false);
    const [groupsSelected, setGroupsSelected] = useState<any>([]);
    const [techniquesSelected, setTechniquesSelected] = useState<any>([]);
    const [difficultySelected, setDifficultySelected] = useState<any>([]);
    const [nutritientsSelected, setNutritientsSelected] = useState<any>(null);
    const [orderSelected, setOrderSelected] = useState<any>(null);
    const [presentationSelected, setPresentationSelected] = useState<any>(null);
    const [ingredientsSelected, setIngredientsSelected] = useState<any>(null);
    const [allergensSelected, setAllergensSelected] = useState<any>([]);

    const [groups] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getAlimentGroups();
        return response.getResponseData();
    }, []));

    const [ingredients] = useFetch(useCallback(async () => {
        const response = await (new RecipeService()).getIngredientsByCompany({ limit: 1000000 });
        return response.getResponseData();
    }, []));

    const [allergens] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getAllergensList();
        return response.getResponseData();
    }, []));

    // controlar el cambio en el selector de grupos
    const handleGroupsChange = (e: any) => {
        setGroupsSelected(e);
        updateFilters({ group_food: e.map((group: any) => group.value) });
    }

    // controlar el cambio en el selector de técnicas culinarias
    const handleTechniquesChange = (e: any) => {
        setTechniquesSelected(e);
        updateFilters({ techniques: e.map((technique: any) => technique.value) });
    }

    // controlar el cambio en el selector de dificultad
    const handleDifficultyChange = (e: any) => {
        setDifficultySelected(e);
        updateFilters({ difficulty: e.value });
    }

    // controlar el cambio en el selector de presentación
    const handlePresentationChange = (e: any) => {
        setPresentationSelected(e);
        updateFilters({ presentation: e.value });
    }

    // controlar el cambio en el selector de nutrientes
    const handleNutritientsChange = (e: any) => {
        setNutritientsSelected(e);
        if (orderSelected?.value) {
            updateFilterOrder(e.value, orderSelected.value);
        }
    }

    // controlar el cambio en el selector de orden
    const handleOrderChange = (e: any) => {
        setOrderSelected(e);
        if (nutritientsSelected?.value) {
            updateFilterOrder(nutritientsSelected.value, e.value);
        }
    }

    // controlar el cambio en el selector de ingredientes
    const handleIngredientsChange = (e: any) => {
        setIngredientsSelected(e);
        updateFilters({ ingredients: e.map((ingredient: any) => ingredient.value) });
    }

    // controlar el cambio en el selector de alérgenos
    const handleAllergensChange = (e: any) => {
        setAllergensSelected(e);
        updateFilters({ allergens: e.map((allergen: any) => allergen.value) });
    }

    // obtener la lista de grupos de alimentos
    const getGroupsOptions = () => {
        if (groups) {
            return groups.food_groups.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    // obtener la lista de ingredientes
    const getIngredientsOptions = () => {
        if (ingredients && ingredients.data) {
            return ingredients.data.map((option: { id: string; name: string; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    // obtener la lista de alérgenos
    const getAllergensOptions = () => {
        if (allergens) {
            return allergens.map((option: { id: any; name: any; nombre: string }) => {
                return {
                    value: option.name,
                    label: option.nombre
                }
            })
        }
        return [];
    }

    const _handleReset = () => {
        setGroupsSelected([]);
        setTechniquesSelected([]);
        setIngredientsSelected([]);
        setAllergensSelected([]);
        resetFilters();
    }

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='ImportExport' size='2x' color='secondary' />
            </label>

            <SearchableSelect
                isMulti={false}
                onChange={(e: any) => handleNutritientsChange(e)}
                value={nutritientsSelected}
                name='nutrient_filter'
                placeholder='nutrientes '
                options={NUTRIENTS}
                styles={{ minWidth: '300px !important' }}
            />

            <SearchableSelect
                isMulti={false}
                onChange={(e: any) => handleOrderChange(e)}
                value={orderSelected}
                name='order_filter'
                completePlaceholder='Ordenar por...'
                options={[{ value: 'asc', label: 'Ascendente' }, { value: 'desc', label: 'Descendente' }]}
            />

            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput
                onSearch={(search: string) => { updateFilters({ search_array: search }) }}
                placeholder='Buscar...'
            />

            <Button color='secondary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='foodsFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='foodsFilters'> Filtros de Recetas </OffCanvasTitle>
                    <Button color="storybook" isLight onClick={_handleReset}>
                        Resetear
                    </Button>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Estado de la receta'>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                    value={filters.filter_filters.active.toString()}
                                    id='active-select' ariaLabel='Selector de estado' placeholder='Estado...'
                                    list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activo', text: 'Activo' }, { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                                />
                            </FormGroup>

                            <FormGroup id='filter2' label='Grupos de alimentos' className='mt-4'>
                                {groups
                                    ? (
                                        <SearchableSelect
                                            isMulti
                                            onChange={(e: any) => handleGroupsChange(e)}
                                            value={groupsSelected}
                                            name='groups_filter'
                                            placeholder='grupo de alimentos'
                                            options={getGroupsOptions()}
                                        />
                                    )
                                    : <Spinner isSmall className='ms-3' />
                                }
                            </FormGroup>

                            <FormGroup id='filter3' label='Técnicas culinarias' className='mt-4'>
                                <SearchableSelect
                                    isMulti
                                    onChange={(e: any) => handleTechniquesChange(e)}
                                    value={techniquesSelected}
                                    name='techniques_filter'
                                    placeholder='técnicas culinarías'
                                    options={TECHNIQUES}
                                />
                            </FormGroup>

                            <FormGroup id='filter4' label='Ingredientes' className='mt-4'>
                                {ingredients
                                    ? (
                                        <SearchableSelect
                                            isMulti
                                            onChange={(e: any) => handleIngredientsChange(e)}
                                            value={ingredientsSelected}
                                            name='ingredients_filter'
                                            placeholder='ingredientes'
                                            options={getIngredientsOptions()}
                                        />
                                    )
                                    : <Spinner isSmall className='ms-3' />
                                }
                            </FormGroup>

                            <FormGroup id='filter5' label='Alérgenos' className='mt-4'>
                                {allergens
                                    ? (
                                        <SearchableSelect
                                            isMulti
                                            onChange={(e: any) => handleAllergensChange(e)}
                                            value={allergensSelected}
                                            name='allergens_filter'
                                            placeholder='alérgenos'
                                            options={getAllergensOptions()}
                                        />
                                    )
                                    : <Spinner isSmall className='ms-3' />
                                }
                            </FormGroup>

                            <FormGroup id='filter6' label='Dificultad' className='mt-4'>
                                <SearchableSelect
                                    isMulti={false}
                                    onChange={(e: any) => handleDifficultyChange(e)}
                                    value={difficultySelected}
                                    name='difficulty'
                                    placeholder='Dificultad'
                                    options={DIFFICULTY}
                                />
                            </FormGroup>

                            <FormGroup id='filter7' label='Presentación de la ración' className='mt-4'>
                                <SearchableSelect
                                    isMulti={false}
                                    onChange={(e: any) => handlePresentationChange(e)}
                                    value={presentationSelected}
                                    name='presentation'
                                    placeholder='Presentación'
                                    options={RATION_PRESENTATION}
                                />
                            </FormGroup>

                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default RecipesFilters;
import { useCallback, useEffect, useState } from "react"
import useFetch from "../../hooks/useFetch"
import SearchableSelect from "./SearchableSelect"
import { showErrors } from "../../utils/Forms/FormikVerifyClass"
import { AppointmentService } from "../../services/appointments/appointmentService"

type AppointmentClientSelectorProps = {
    name: string,
    onChange: (data: any) => void,
    formik: any,
    selectedId: string|undefined
}

export const AppointmentClientSelector: React.FC<AppointmentClientSelectorProps> = ({name, onChange, selectedId, formik}) => {

    const defaultValue = {value: '', label: 'Todos'};
    const [selected, setSelected] = useState<any>(defaultValue);
    const [response] = useFetch(useCallback(async () => {
        const resp = await (await (new AppointmentService()).listClients({ limit: 100, filter_filters: { active: 1 } })).getResponseData();
        return resp;
    }, []));

    useEffect(() => {
        if(response?.patients === undefined) return;
        if(selectedId === undefined) return;
        const client = response?.patients.find((c: any) => c.id === selectedId);
        if(client === undefined) return;
        setSelected({
            value: client.id,
            label: `${client.name} ${client.lastName}`,
        })
    }, [selectedId])

    useEffect(() => {
        if(selected === undefined) return;
        onChange(selected.value);
    }, [selected])

    return (
        <>
            <SearchableSelect
                name={name}
                isSearchable
                onBlur={formik.handleBlur}
                placeholder='Usuario'
                value={selected}
                isClearable
                defaultValue={defaultValue}
                onChange={(newValue: any) => {
                    if(newValue === null) {
                        setSelected(defaultValue);
                        formik.setFieldValue(name, null);
                        return;
                    }
                    setSelected(newValue)
                    formik.setFieldValue(name, newValue?.value ? newValue.value : null)
                }}
                options={response?.patients.map((c: any) => ({
                    value: c.id,
                    label: `${c.name} ${c.lastName}`,
                }))}
            />
            {showErrors(formik, name)}
        </>
    )

}
import { FC, useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { FormService } from "../../../services/forms/formService";
import { CustomTable } from "../../../components/table/CustomTable";
import { useParams } from "react-router-dom";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import { CardBody } from "../../../components/bootstrap/Card";
import useFilters from "../../../hooks/useFilters";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import EditFillValues from "../edit-fill-values/EditFillValues";
import moment from "moment";
import PatientFieldGraph from "./PatientFieldGraph";

interface FormModalProps {
    formId: string;
}

export interface IFormsFilters {
    form?: string;
    client?: string;
}

const ListPatientForms: FC<FormModalProps> = ({ formId }) => {

    const { id = '' } = useParams<{ id: string }>();
    const formService = new FormService();

    const formsFilters: IFormsFilters = {
        form: formId,
        client: id
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(formsFilters);

    const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedColumn, setSelectedColumn] = useState<{} | undefined>(undefined);
    const [openGraph, setOpenGraph] = useState<boolean>(false);

    const [fills, loadingFills, fillError, refetchFills] = useFetch(useCallback(async () => {
        const response = (await formService.listFormFills(filters));
        return response.getResponseData();
    }, [filters]));

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new FormService()).deleteFill(id)).getResponseData();
            if (response.success) {
                refetchFills();
                toast.success('Formulario eliminado correctamente');
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el formulario');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el formulario');
        }
    };

    // Actualizar los filtros cuando se cambia el tipo de formulario
    useEffect(() => {
        if (formId !== null) {
            updateFilters({ ...filters, formType: formId, client: id });
        }
    }, [formId]);

    const getFormList = () => {
        if (fills?.formFills?.length > 0) {
            // Obtener los campos del formulario y crear las columnas de la tabla de forma dinámica
            const fieldsColumns = [] as any;

            fills.formFills.forEach((formFill: any) => {
                formFill.formFillValues.forEach((field: any, index: number) => {
                    if (
                        field?.formField?.main &&
                        fieldsColumns.length < 9 &&
                        !fieldsColumns.some((column: any) => column.id === field.formField.id) // Verificar duplicados
                    ) {
                        fieldsColumns.push({
                            id: field.formField.id, // ID del campo
                            name: field.formField.name,
                            keyValue: `field-${fieldsColumns.length}`, // Índice único basado en la longitud actual
                            // Si el campo es de tipo número, se muestra un botón para ver el gráfico
                            actionButton: field.formField.type === 'number'
                                ? {
                                    title: "Ver gráfico",
                                    icon: "BarChart",
                                    click: () => {
                                        setSelectedColumn({ id: field.formField.id, name: field.formField.name });
                                        setOpenGraph(true);
                                    }
                                }
                                : undefined,
                            render: (element: any) => {
                                return <div>{element.formFillValues[index]?.value}</div>;
                            }
                        });
                    }
                });
            });

            return (
                <CustomTable
                    data={fills ? fills.formFills : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: fills ? fills.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div
                                        className="cursor-pointer text-secondary"
                                        onClick={() => {
                                            setSelectedRow(element.id);
                                            setOpenModal(true);
                                        }}
                                    >
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        ...fieldsColumns,
                        {
                            name: "Publicación",
                            keyValue: "publicAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div>{moment(element.publicAt?.date).format('DD/MM/YY HH:mm')}</div>
                                )
                            }
                        },
                        {
                            name: "Creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div>{moment(element.createdAt?.date).format('DD/MM/YY HH:mm')}</div>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar formulario",
                            click: (item: any) => {
                                setSelectedRow(item.id);
                                setOpenModal(true);
                            }
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar formulario",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar formulario",
                                    text: "¿Está seguro que desea eliminar el formulario?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            )
        } else {
            return <div className="text-center mt-3"><h5 className="text-muted">No hay formularios</h5></div>;
        }
    };

    return (
        <>
            <CardBody className="cardBodyForms">
                {formId ? getFormList() : <div className="text-center"><h5 className="text-muted">No hay formularios</h5></div>}
            </CardBody>

            <Modal isOpen={openModal} setIsOpen={setOpenModal} size='xl' titleId='Datos del formulario'>
                <ModalHeader setIsOpen={setOpenModal} className='p-4'>
                    <ModalTitle id='form-data'>Datos del formulario</ModalTitle>
                </ModalHeader>
                <ModalBody className='px-4'>
                    <div className="row">
                        <EditFillValues formFillId={selectedRow} setIsOpen={setOpenModal} refetch={refetchFills} />
                    </div>
                </ModalBody>
            </Modal>

            {openGraph && (<PatientFieldGraph isOpen={openGraph} setIsOpen={setOpenGraph} data={{ column: selectedColumn, client: id }} />)}
        </>
    );
}

export default ListPatientForms;
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/tip_work_client';

export class TipsWorkClientService extends RestServiceConnection {

    listTipsWorkClient = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true);
        return this;
    }

    getTipWorkClient = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: {
                tipWorkClient: id
            }
        }, true);
        return this;
    }

    createTipWorkClient = async (tipWorkClient: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: {
                ...tipWorkClient
            }
        }, true);
        return this;
    }

    editTipWorkClient = async (tipWorkClient: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: {
                ...tipWorkClient
            }
        }, true);
        return this;
    }

    deleteTipWorkClient = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: {
                tipWorkClient: id
            }
        }, true);
        return this;
    }

    toggleTipWork = async (tipWorkClient: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle-worked',
            data: { tipWorkClient: tipWorkClient }
        }, true);
        return this;
    }


}
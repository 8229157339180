import React, { useCallback } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import useFetch from '../../../hooks/useFetch';
import { FormService } from '../../../services/forms/formService';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';

interface PatientFieldGraphProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    data: any;
}

const PatientFieldGraph: React.FC<PatientFieldGraphProps> = ({ isOpen, setIsOpen, data }) => {

    const [graphData] = useFetch(useCallback(async () => {
        const response = (await (new FormService).getFieldGraph({ client: data.client, formField: data.column.id }));
        return response.getResponseData();
    }, [data]));

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Datos del formulario'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='form-data'>{`Gráfica de ${data.column.name}`}</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4'>
                <ResponsiveContainer width='100%' height={400}>
                    <AreaChart
                        data={graphData}
                        margin={{ top: 0, right: 40, left: -20, bottom: 0 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Area type="monotone" dataKey="pv" stackId="1" stroke="#f49c38" fill="#FDF5EB" />
                    </AreaChart>
                </ResponsiveContainer>
            </ModalBody>
        </Modal>
    );
};

export default PatientFieldGraph;
import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle, } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import useFilters from "../../../hooks/useFilters";
import ErrorMessage from "../../../components/ErrorMessage";
import { toast } from "react-toastify";
import AsyncImg from "../../../components/AsyncImg";
import PatientsFilters from "./patients-options/PatientsFilters";
import { PatientService } from "../../../services/patients/patientService";
import { PatientsApiResponse } from "../../../type/patients-type";
import { CustomTable } from "../../../components/table/CustomTable";
import StatusDropdown from "../../../components/StatusDropdown";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";

export interface IPatientFilters {
  active?: number;
  minAge?: any;
  maxAge?: any;
  tags?: string[];
  user?: any;
}

const patientFilters: IPatientFilters = {
  active: 3,
  minAge: null,
  maxAge: null,
  tags: [],
  user: null,
};

const PatientsList = () => {

  const navigate = useNavigate();
  const patientService = new PatientService();

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(patientFilters);

  const [patientsData, fetchingPatients, patientsError, refetchPatients] = useFetch(useCallback(async () => {
    const response = await patientService.getPatients(filters);
    return response.getResponseData();
  }, [filters]));

  const getContent = () => {
    if (patientsError) return <ErrorMessage message={patientsError.message} />;

    const togglePatientStatus = async (id: string, status: boolean, toggleStatus: Function) => {
      try {
        setChangingStatus([...changingStatus, id]);
        const response = (await patientService.togglePatientStatus(id)).getResponseData();
        if (response.success) {
          setChangingStatus(changingStatus.filter((item) => item !== id));
          toggleStatus(status);
          refetchPatients();
          toast.success(status ? 'Se ha activado el paciente' : 'Se ha desactivado el paciente');
        } else {
          setChangingStatus(changingStatus.filter((item) => item !== id));
          toast.error(response.message || 'Error al cambiar el estado del paciente');
        }
      } catch (error: any) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toast.error(error.message || 'Error al cambiar el estado del paciente');
      }
    };

    const handleDelete = async (id: string) => {
      try {
        const response = (await patientService.deletePatient(id)).getResponseData();
        if (response.success) {
          refetchPatients();
          setTimeout(() => {
            toast.success('Paciente eliminado correctamente');
          }, 100);
        } else {
          setTimeout(() => {
            toast.error(patientsError.message || 'Error al eliminar el paciente');
          }, 100);
        }
      } catch (error: any) {
        toast.error(patientsError.message || 'Error al eliminar el paciente');
      }
    };

    if (patientsData && patientsData.patients.length > 0) {
      let apiData = patientsData as PatientsApiResponse;

      return (
        <CustomTable
          title="Pacientes"
          loading={fetchingPatients}
          data={apiData.patients || null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: apiData as PatientsApiResponse ? apiData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "",
              keyValue: "PatientImage",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <div className='user-avatar'>
                      <AsyncImg id={element.profileImg ? element.profileImg.id : null} />
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Nombre y Apellidos",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${element.id}/view`) }}>
                    {element.name + (element.lastName ? ' ' + element.lastName : '')}
                  </div>
                )
              },
            },
            {
              name: "Correo electrónico",
              keyValue: "email",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.email}
                  </>
                )
              }
            },
            {
              name: "Teléfono",
              keyValue: "telephone",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.telephone || "-"}
                  </>
                )
              }
            },
            {
              name: "Etiquetas",
              keyValue: "clientLabels",
              render: (element: any) => {
                return (
                  <>
                    {
                      element.clientLabels.length > 0
                        ? (
                          element.clientLabels?.map((tag: any) => {
                            return (
                              <span
                                key={tag.clientLabel.id}
                                className="badge text-center w-auto m-1 p-2 ps-3 pe-3 position-relative"
                                style={
                                  {
                                    backgroundColor: tag.clientLabel.color || '#d7d7d7',
                                    color: tag.clientLabel.color == '#3c3c3b' || tag.clientLabel.color == '#b81e53' || tag.clientLabel.color == '#e5133d' ? 'white' : 'black',
                                  }}
                              >
                                {tag.clientLabel.name}
                              </span>
                            )
                          })
                        ) : '-'
                    }
                  </>
                )
              },
              style: { maxWidth: '300px' }
            },
            {
              name: "Profesional",
              keyValue: "admin",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.user?.name + ' ' + (element.user?.lastName ? element.user?.lastName : '') || "-"}
                  </>
                )
              }
            },
            {
              name: "Estado",
              keyValue: "active",
              className: "text-center",
              render: (element: any) => {
                return (
                  <div className={'text-center'}>
                    <StatusDropdown
                      disabled={changingStatus.includes(element.id)}
                      itemId={element.id}
                      status={element.active}
                      change={togglePatientStatus}
                    />
                  </div>
                );
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
          ]}
          actions={[
            {
              title: "Editar",
              icon: "Edit",
              buttonType: 'icon',
              additionalClasses: 'text-primary',
              description: "Editar perfil de paciente",
              click: (item: any) => {
                navigate(`${item.id}/edit`);
              },
            },
            {
              title: "Eliminar",
              icon: "Delete",
              buttonType: 'icon',
              additionalClasses: 'text-danger',
              description: "Eliminar paciente",
              click: (item: any) => {
                handleConfirmationAlert({
                  title: "Eliminar paciente",
                  text: "¿Está seguro que desea eliminar el paciente?",
                  icon: "warning",
                  onConfirm: () => {
                    handleDelete(item.id);
                  }
                })
              },
            },
          ]}
        />
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Pacientes</CardTitle>
          <SubheaderSeparator />
          <Button
            title="Crear Paciente"
            color="storybook"
            icon="Add"
            isLight
            onClick={() => { navigate("create") }}
          />
        </SubHeaderLeft>
        <SubHeaderRight>
          <PatientsFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <CardBody className="table-responsive" isScrollable={true}>
            <>{getContent()}</>
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
};

export default PatientsList;
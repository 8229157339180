import { useCallback, useEffect, useState } from "react";
import { MenuService } from "../../services/menus/menuService";
import { Menu } from "../../type/clientMenus-type";
import useFetch from "../../hooks/useFetch";
import Accordion, { AccordionItem } from "../bootstrap/Accordion";
import Spinner from "../bootstrap/Spinner";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { COLORS } from "../../utils/color";

export const macronutrients = ["starch", "proteins", "fat"];

export interface NutritionalValuesProps {
    nutValues?: any;
    clientId?: string;
}

const NutritionalMenuValues: React.FC<NutritionalValuesProps> = ({ nutValues, clientId }) => {

    const [nutrientsGroups, setNutrientsGroups] = useState<any>([]);
    const [renderNutrients, setRenderNutrients] = useState(false);
    const [renderRecommendations, setRenderRecommendations] = useState(false);

    const [NutritionalValues, loadingValues] = useFetch(useCallback(async () => {
        const response = (await (new MenuService()).getNutritionalValues(nutValues));
        return response.getResponseData() as Menu;
    }, []));

    const [RecommendedDailyIntakes, loadingDailyIntakesValues] = useFetch(useCallback(async () => {
        if (clientId === undefined || !clientId) return;
        const response = (await (new MenuService()).getRecommendedDailyIntakes(clientId));
        return response.getResponseData();
    }, [clientId]));

    // Agrupa los nutrientes
    const groupNutrients = (nutrients: []) => {
        // Clasifica los nutrientes por grupo
        const groups = nutrients.reduce((acc: any, nutrient: any) => {
            const nutrientGroupExists = acc.find((group: any) => group.group === nutrient.group);

            if (!nutrientGroupExists) {
                acc.push({
                    group: nutrient.group,
                    group_name: nutrient.grupo,
                    nutrients: []
                })
            }

            return acc;
        }, []);

        // Añade los nutrientes a su respectivo grupo
        nutrients.forEach((nutrient: any) => {
            const groupIndex = groups.findIndex((group: any) => group.group === nutrient.group);

            groups[groupIndex].nutrients.push(nutrient);
        });

        setNutrientsGroups(groups);
    };

    // Renderiza las etiquetas de la gráfica de los macronutrientes
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        // Obtiene los nutrientes de los macronutrientes
        const data = nutrientsGroups.find((group: any) => group.group === 'Macronutrients')?.nutrients?.filter((nutrient: any) => macronutrients.includes(nutrient.name));

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
        const name = data[index]?.name === 'starch' ? 'HC' : data[index]?.nombre.replace(/\(\w+\)/, '');
        const value = data[index]?.value;
        const unitMeasure = data[index]?.nombre.match(/\(([^)]+)\)/)[1];

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {/* <tspan x={x < cx ? x + 22 : x - 22} dy="0em">{name}</tspan> */}
                <tspan x={x < cx ? x + 22 : x - 22} dy="0em">{`${value} ${unitMeasure}`}</tspan>
            </text>
        );
    };

    // Obtiene la lista de nutrientes separados por grupos
    const _renderNutrients = useCallback(() => {
        if (!renderNutrients || !renderRecommendations || nutrientsGroups.length === 0) return (<></>);

        return (
            <Accordion id={"nutrients-group"} className="row mx-2" shadow={'none'}>
                {nutrientsGroups.map((nutrientGroup: any) => {
                    let data;
                    if (nutrientGroup.group === 'Macronutrients') {
                        data = nutrientGroup.nutrients.filter((nutrient: any) => macronutrients.includes(nutrient.name));
                    }

                    data = data?.map((nutrient: any) => {
                        return {
                            name: nutrient.nombre,
                            value: nutrient.value,
                        }
                    });

                    return (
                        <AccordionItem
                            key={`group-${nutrientGroup.group}`}
                            id={nutrientGroup.group}
                            title={nutrientGroup.group_name}
                            className={"col-md-12 mb-4"}
                        >
                            {nutrientGroup.group === 'Macronutrients' && data.length > 0 && data.reduce((acc: number, nutrient: any) => acc + nutrient.value, 0) > 0
                                ? (
                                    <ResponsiveContainer width='100%' height={180}>
                                        <div className="d-flex flex-row">
                                            <PieChart width={180} height={180}>
                                                <Pie
                                                    data={data}
                                                    cx="50%"
                                                    cy="50%"
                                                    labelLine={false}
                                                    label={renderCustomizedLabel}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                >
                                                    {data.map((entry: any, index: number) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % data.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>

                                            {/* Leyenda */}
                                            <div className="d-flex flex-column justify-content-center ml-4">
                                                {data.map((entry: any, index: number) => (
                                                    <div key={`legend-${index}`} className="d-flex flex-row align-items-center">
                                                        <div style={{ width: '10px', height: '10px', backgroundColor: COLORS[index % data.length] }}></div>
                                                        <div className="ms-2">{entry.name === 'HC complejos (g)' ? 'HC (g)' : entry.name}</div>
                                                    </div>
                                                ))}
                                                <div className="text-center">Energía: {Math.round(nutrientGroup.nutrients.find((nutrient: any) => nutrient.name === 'energy')?.value)} Kcal</div>
                                            </div>
                                        </div>
                                    </ResponsiveContainer>
                                )
                                : (
                                    <>
                                        <div className="row mb-2">
                                            <div className="col-6"></div>
                                            <div className="col-3">APORTE</div>
                                            <div className="col-3">OBJETIVO</div>
                                        </div>
                                        {nutrientGroup.nutrients.map((nutrient: any) => {
                                            const recomendedDailyIntake = RecommendedDailyIntakes?.find((item: any) => item.nutrient === nutrient.nombre);
                                            return (
                                                <div key={`nutrient-${nutrient.name}`} className="row" style={{ borderBottom: '1px solid #f5d0a9' }}>
                                                    <div className="col-md-6">{nutrient.nombre}</div>
                                                    <div className="col-md-3">{Math.round(nutrient.value)}</div>
                                                    <div className="col-md-3">{recomendedDailyIntake ? recomendedDailyIntake?.quantity : '-'}</div>
                                                    {/* <div className="col-md-3">{Number.isInteger(nutrient.value) ? nutrient.value : nutrient.value.toFixed(2)}</div> */}
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                        </AccordionItem>
                    )
                })
                }
            </Accordion>
        )
    }, [renderNutrients, renderRecommendations, nutrientsGroups]);

    // Cuando se obtienen los valores nutricionales, se clasifican por grupos
    useEffect(() => {
        if (NutritionalValues !== null) {
            groupNutrients(NutritionalValues);
            setRenderNutrients(true);
        }
    }, [NutritionalValues]);

    useEffect(() => {
        if (RecommendedDailyIntakes !== null) {
            setRenderRecommendations(true);
        }
    }, [RecommendedDailyIntakes]);

    if (loadingValues && loadingDailyIntakesValues) return (<div className="row d-flex justify-content-center align-content-center" style={{ height: '80vh' }}><Spinner className='h-max' /></div>);

    return (
        <>{renderNutrients && renderRecommendations && _renderNutrients()}</>
    )
}

export default NutritionalMenuValues;
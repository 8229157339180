import React, { useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import { toast } from 'react-toastify';
import Input from "../../../components/bootstrap/forms/Input";
import { useFormik } from 'formik';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import { TipsService } from '../../../services/tips/tipsService';
import SearchableSelect from '../../../components/select/SearchableSelect';
import * as Yup from 'yup';

interface CreateTipModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    refetchTips: () => void;
    getTipGroupsList: () => any;
}

interface CreateTipFormModal {
    tipGroups: string[];
    name: string;
    description: string;
}

let initialFormikValues = {
    name: '',
    description: '',
    tipGroups: [],
};

const CreateTipModal: React.FC<CreateTipModalProps> = ({ isOpen, setIsOpen, refetchTips, getTipGroupsList }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('El nombre es obligatorio'),
        description: Yup.string().notRequired(),
        tipGroups: Yup.array().required('El grupo es obligatorio').min(1, 'El grupo es obligatorio'),
    });
    const formik = useFormik({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleCreateTip(values);
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    const handleCreateTip = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new TipsService()).createTip(values)).getResponseData();

            if (response.success) {
                setTimeout(() => {
                    toast.success('Consejo creado correctamente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al crear el consejo');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear el consejo');
        } finally {
            setLoading(false);
            setIsOpen(false);
            refetchTips();
        }
    }

    const verifyClass = (inputFieldID: keyof CreateTipFormModal) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : 'p-0';
    }

    const showErrors = (inputFieldID: keyof CreateTipFormModal) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del consejo'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4 border-bottom'>
                <ModalTitle id='patient-data'>Crear un nuevo consejo</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4 border-bottom'>
                <form onSubmit={formik.handleSubmit} className='m-3' >

                    <FormGroup label='' className='row gap-3'>
                        <SearchableSelect
                            name="tipGroups"
                            isSearchable
                            isMulti
                            options={getTipGroupsList()}
                            onChange={(e: any) => formik.setFieldValue('tipGroups', e?.map((group: any) => group.value))}
                            placeholder='grupo'
                            classname={verifyClass('tipGroups')}
                        />
                        {showErrors('tipGroups')}
                        <Input
                            name='name'
                            type='text'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Nombre del consejo'
                        />
                        {showErrors('name')}
                        <Textarea
                            name='description'
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Descripción del consejo'
                        />
                        {showErrors('description')}
                    </FormGroup>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button
                    color='primary'
                    onClick={formik.handleSubmit}
                    isDisable={loading}
                >
                    {loading ? <Spinner color={'light'} /> : 'Crear'}
                </Button>
                <Button
                    color='danger'
                    onClick={() => setIsOpen(false)}
                    isDisable={loading}
                >
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    )

};

export default CreateTipModal;
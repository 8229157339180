import { ChangeEvent, useCallback, useState } from "react";
import { toast } from 'react-toastify';
import { PatientService } from "../../services/patients/patientService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../components/bootstrap/Modal";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Select from "../../components/bootstrap/forms/Select";
import Input from "../../components/bootstrap/forms/Input";
import { PatientCreateSchema, PatientForm, patientInitialValues } from "./PatientForm";
import { useFormik } from "formik";
import useFetch from "../../hooks/useFetch";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import { ROLES } from "../../utils/RoleMapping";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import { CompanyService } from "../../services/companies/organizationService";
import { UserService } from "../../services/users/userService";
import { RecipeService } from "../../services/recipes/recipeService";
import { Users, UsersApiResponse } from "../../type/user-type";
import { AlimentsApiResponse, NutrientList } from "../../type/aliments-type";
import InputPhone from "react-phone-number-input";
import CustomPasswordInput from "../../components/bootstrap/forms/CustomPasswordInput";
import es from "react-phone-number-input/locale/es.json";
import 'react-phone-number-input/style.css';
import CustomSearchSelect from "../../components/select/CustomSearchSelect";
import { countries } from "../../utils/countries";
import SearchableSelect from "../../components/select/SearchableSelect";
import Checks from "../../components/bootstrap/forms/Checks";
import Button from "../../components/bootstrap/Button";
import Spinner from "../../components/bootstrap/Spinner";
import { useAppointmentCalendar } from "../appointments/providers/AppointmentCalendarProvider";
import useHandleErrors from "../../utils/hooks/useHandleErrors";
import { FoodService } from "../../services/foods/foodService";

interface PatientModalFormProps {
    openModal: boolean;
    setOpenModal: (open: boolean) => void;
    onConfirm: any;
}

const PatientModalForm: React.FC<PatientModalFormProps> = ({ openModal, setOpenModal, onConfirm }) => {

    const auth = usePrivilege();
    const { userCan } = auth;
    const { setNewPatientSelected } = useAppointmentCalendar();
    const { handleErrors } = useHandleErrors();

    const [loading, setLoading] = useState<boolean>(false);
    const [allergensSelected, setAllergensSelected] = useState<string[]>([]);

    // Obtiene las organizaciones
    const [organizations] = useFetch(useCallback(async () => {
        if (!auth.userHasRole(ROLES.SUPER_ADMIN)) return [];
        const response = await (new CompanyService()).getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []));

    // Obtiene los usuarios
    const [users] = useFetch(useCallback(async () => {
        if (!userCan('access_to_all_patients', 'clients')) return;
        const response = await (new UserService()).getUsers();
        return response.getResponseData() as UsersApiResponse;
    }, []));

    // Obtiene los alimentos
    const [foodsData] = useFetch(useCallback(async () => {
        const response = await (new RecipeService()).getIngredientsByCompany();
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    // Obtiene los grupos de alimentos
    const [groupsData] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getAlimentGroups();
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    // Obtiene los alérgenos
    const [allergensList] = useFetch(useCallback(async () => {
        const response = await (await (new FoodService()).getAllergensList()).getResponseData();
        return response;
    }, []));

    const handleCreatePatient = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new PatientService()).createPatient(values)).getResponseData();
            if (response.success) {
                onConfirm();
                setNewPatientSelected({ label: response.data.name, value: response.data.id });
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al crear el paciente');
        } finally {
            setLoading(false);
        }
    };

    const getOrganizationsList = () => {
        if (organizations as Companies) {
            return organizations.companies.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    };

    const getUsersList = () => {
        if (users as Users) {
            return users.users.map((option: { id: string; name: string; lastName: string; }) => {
                return {
                    value: option.id,
                    label: option.name + (option.lastName ? (' ' + option.lastName) : '')
                }
            })
        }
        return [];
    };

    const getFoodsList = () => {
        return foodsData?.data?.map((food: any) => {
            return {
                value: food.id,
                label: food.name,
                isSelected: false
            }
        }) || [];
    };

    const getGroupsList = () => {
        return groupsData?.food_groups.map((group: any) => {
            return {
                value: group.id,
                label: group.name,
                isSelected: false
            }
        }) || [];
    };

    // Actualizar el estado de los alérgenos seleccionados
    const handleAllergensChecks = (name: string) => {
        const isSelected = allergensSelected.includes(name); // Verificar si el checkbox ya está seleccionado
        // Actualizar el estado según el checkbox
        if (isSelected) {
            setAllergensSelected(allergensSelected.filter((allergen) => allergen !== name));
        } else {
            setAllergensSelected([...allergensSelected, name]);
        }

    };

    // Verificar si el alérgeno está seleccionado
    const _alergenChecked = (allergen: any) => {
        return allergensSelected.includes(allergen.name);
    };

    const formik = useFormik({
        initialValues: patientInitialValues,
        validationSchema: PatientCreateSchema,
        onSubmit: values => {
            values.allergens = allergensSelected;
            handleCreatePatient(values);
        }
    });

    const verifyClass = (inputFieldID: keyof PatientForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''; };

    const showErrors = (inputFieldID: keyof PatientForm) => {
        //@ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    return (
        <Modal isOpen={openModal} setIsOpen={setOpenModal} size='xl' titleId='Datos del paciente'>
            <ModalHeader setIsOpen={setOpenModal} className='p-4'>
                <ModalTitle id='patient-form-data'>Datos del paciente</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="row">
                        <div className={"col-md-2"}>
                            {(users as Users) && (
                                <Card className="mx-2">
                                    <CardBody>
                                        <CardTitle>Asignar</CardTitle>
                                        {(auth.userHasRole(ROLES.SUPER_ADMIN)) && (
                                            <FormGroup requiredInputLabel label='Organización'>
                                                <Select
                                                    id='company'
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        formik.handleChange(e)
                                                        formik.setFieldValue('company', e.target.value)
                                                    }}
                                                    value={formik.values.company}
                                                    onBlur={formik.handleBlur}
                                                    className={verifyClass('company')}
                                                    ariaLabel='Listado de organizaciones'
                                                    placeholder='Elegir...'
                                                    list={getOrganizationsList()}
                                                />
                                                {showErrors('company')}
                                            </FormGroup>
                                        )}

                                        <FormGroup requiredInputLabel label='Profesional' className='mt-3'>
                                            <Select
                                                id='user'
                                                onChange={formik.handleChange}
                                                value={formik.values.user}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('user')}
                                                ariaLabel='Listado de profesionales'
                                                placeholder='Elegir...'
                                                list={getUsersList()}
                                            />
                                            {showErrors('user')}
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            )}
                        </div>

                        <div className="col-md-7">
                            <div>
                                <div className="row mx-1">
                                    <Card stretch={false}>
                                        <CardBody>
                                            <CardTitle>Información de la cuenta</CardTitle>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <FormGroup requiredInputLabel label='Nombre'>
                                                        <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                                        {showErrors('name')}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormGroup label='Apellidos'>
                                                        <Input id='last_name' onChange={formik.handleChange} value={formik.values.last_name} onBlur={formik.handleBlur} className={verifyClass('last_name')} />
                                                        {showErrors('last_name')}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormGroup requiredInputLabel label='Género'>
                                                        <Select
                                                            name="sex"
                                                            id='sex'
                                                            value={formik.values.sex}
                                                            onChange={formik.handleChange}
                                                            ariaLabel='Selector de género'
                                                            list={[
                                                                { value: 'H', label: 'Hombre', },
                                                                { value: 'M', label: 'Mujer' },
                                                            ]}
                                                            className={verifyClass('sex')}
                                                            placeholder={'H / M'}
                                                        />
                                                        {showErrors('sex')}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-6">
                                                    <FormGroup requiredInputLabel label='Email' className="col-md-12">
                                                        <Input id='email' type='email' autoComplete="new-email" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} className={verifyClass('email')} />
                                                        {showErrors('email')}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6">
                                                    <FormGroup requiredInputLabel label='DNI' className="col-md-12">
                                                        <Input id='dni' onChange={formik.handleChange} value={formik.values.dni} onBlur={formik.handleBlur} className={verifyClass('dni')} />
                                                        {showErrors('dni')}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-6">
                                                    <FormGroup label='Fecha de nacimiento'>
                                                        <Input id='birthday_date' type='date' onChange={formik.handleChange} value={formik.values.birthday_date} className={verifyClass('birthday_date')} />
                                                        {showErrors('birthday_date')}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6">
                                                    <FormGroup label='Teléfono'>
                                                        <InputPhone
                                                            id='telephone'
                                                            onBlur={formik.handleBlur}
                                                            labels={es}
                                                            international
                                                            defaultCountry="ES"
                                                            onChange={e => { formik.setFieldValue('telephone', e) }}
                                                            value={formik.values.telephone || ''}
                                                            className={verifyClass('telephone')}
                                                        />
                                                        {showErrors('telephone')}
                                                    </FormGroup>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <CardTitle>Ubicación</CardTitle>
                                                <FormGroup label='Provincia' className="col-md-3">
                                                    <Input id='province' onChange={formik.handleChange} value={formik.values.province} onBlur={formik.handleBlur} className={verifyClass('province')} />
                                                    {showErrors('province')}
                                                </FormGroup>
                                                <FormGroup label='Localidad' className="col-md-3">
                                                    <Input id='city' onChange={formik.handleChange} value={formik.values.city} onBlur={formik.handleBlur} className={verifyClass('city')} />
                                                    {showErrors('city')}
                                                </FormGroup>
                                                <FormGroup label='Dirección' className="col-md-6">
                                                    <Input id='address' onChange={formik.handleChange} value={formik.values.address} className={verifyClass('address')} />
                                                    {showErrors('address')}
                                                </FormGroup>
                                                <FormGroup label='País' className="col-md-6">
                                                    <CustomSearchSelect isMulti={false} id='country' defaultValue={countries.find((country) => country.value === formik.values.country)}
                                                        placeholder="Selecciona un país..." search={true} options={countries} onChangeSingle={(e: any) => { formik.setFieldValue('country', e.value) }} />
                                                    {showErrors('country')}
                                                </FormGroup>
                                                <FormGroup label='Código Postal' className="col-md-6">
                                                    <Input type='number' id='pc' onChange={formik.handleChange} value={formik.values.pc} onBlur={formik.handleBlur} className={verifyClass('pc')} placeholder="00000" />
                                                    {showErrors('pc')}
                                                </FormGroup>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>

                                <div className="row">
                                    <div className='col-md-6'>
                                        <Card stretch={false}>
                                            <CardBody>
                                                <CardTitle>Aversiones</CardTitle>
                                                {foodsData && (
                                                    <FormGroup label='Alimentos' className='mt-4'>
                                                        <div className='row g-4'>
                                                            <SearchableSelect
                                                                name="food_aversions"
                                                                isSearchable
                                                                isMulti
                                                                options={getFoodsList()}
                                                                onChange={(e: any) => {
                                                                    const foods = e.map((food: any) => food.value);
                                                                    formik.setFieldValue('food_aversions', foods);
                                                                }}
                                                                placeholder='alimentos'
                                                                onBlur={formik.handleBlur}
                                                                defaultValue={getFoodsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                )}

                                                {groupsData && (
                                                    <FormGroup label='Grupos' className='mt-3'>
                                                        <div className='row g-4'>
                                                            <SearchableSelect
                                                                name="group_aversions"
                                                                isSearchable
                                                                isMulti
                                                                options={getGroupsList()}
                                                                onChange={(e: any) => {
                                                                    const groups = e.map((group: any) => group.value);
                                                                    formik.setFieldValue('group_aversions', groups);
                                                                }}
                                                                placeholder='grupos'
                                                                onBlur={formik.handleBlur}
                                                                defaultValue={getGroupsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>

                                    <div className='col-md-6'>
                                        <Card stretch={false}>
                                            <CardBody>
                                                <CardTitle>Seguridad</CardTitle>

                                                <CustomPasswordInput
                                                    formik={formik}
                                                    id='password'
                                                    value={formik.values.password}
                                                    label="Contraseña"
                                                />

                                                <CustomPasswordInput
                                                    formik={formik}
                                                    id='passwordConfirm'
                                                    value={formik.values.passwordConfirm}
                                                    label="Confirmar Contraseña"
                                                />
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <Card stretch={false}>
                                <CardBody>
                                    <div className="row">
                                        <CardTitle>Alergias</CardTitle>
                                        <span className={'form-label'}>Selecciona las alergias que posea:</span>
                                        {
                                            allergensList?.map((allergen: NutrientList, index: number) => (
                                                <FormGroup key={`allergen-${index}`}>
                                                    <Checks
                                                        id={`check-${allergen.name.toLowerCase()}`}
                                                        key={index}
                                                        type={'switch'}
                                                        checked={_alergenChecked(allergen)}
                                                        onChange={(e: any) => {
                                                            handleAllergensChecks(allergen.name);
                                                            formik.handleChange(e);
                                                        }}
                                                        name={allergen.name}
                                                        label={(
                                                            <div className="ps-3">
                                                                <img key={allergen.name} className="d-inline-block" src={`/allergens/${allergen.name}.png`} alt={allergen.name} width={35} data-bs-toggle="tooltip" title={allergen.name} />
                                                                <span className="form-label ps-3 capitalize">{allergen.nombre}</span>
                                                            </div>
                                                        )}
                                                        className="mt-3 col-6"
                                                    />
                                                </FormGroup>
                                            ))
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </form>
            </ModalBody>

            <ModalFooter>
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setOpenModal(false)} className='btn btn-outline-danger me-2'>Cancelar</Button>
                    <Button type='submit' onClick={formik.handleSubmit} className='btn btn-primary' isDisable={loading}>
                        {loading ? <Spinner color={'dark'} /> : 'Guardar'}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
};

export default PatientModalForm;
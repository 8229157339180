import { useFormik } from "formik";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import Button from "../../components/bootstrap/Button";
import { CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Aliments, AlimentsApiResponse } from "../../type/aliments-type";
import { RecipeService } from "../../services/recipes/recipeService"
import Page from "../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { TECHNIQUES } from "../../utils/FoodUtils";
import { Process } from "./components/Process";
import Ingredients from "./components/Ingredients";
import { RecipeGeneralInfo } from "./components/RecipeGeneralInfo";
import { ImageForm } from "../../components/ImageForm";
import { toast } from "react-toastify";
import { FoodService } from "../../services/foods/foodService";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    recipeData?: RecipeForm;
    recipeImage?: any;
}

export interface RecipeForm {
    name: string;
    process: string[];
    duration: number;
    difficulty: string;
    company: any;
    foodGroup: string;
    secondaryGroups?: string[];
    ingredients?: any[];
    techniques?: string[];
    storageConditions?: string;
    presentation?: string;
}

const recipeInitialValues: RecipeForm = {
    name: '',
    process: [],
    duration: 0,
    difficulty: 'Fácil',
    company: '',
    foodGroup: '',
    secondaryGroups: [],
    ingredients: [],
    techniques: [],
    storageConditions: '',
    presentation: ''
}

const RecipeSchema = yup.object({
    company: yup.string(),
    name: yup.string().required('El nombre es obligatorio'),
    duration: yup.number().required('La duración es obligatoria'),
    difficulty: yup.string().required('La dificultad es obligatoria'),
    foodGroup: yup.string(),
    secondaryGroups: yup.array(),
    ingredients: yup.array(),
    techniques: yup.array(),
    storageConditions: yup.string(),
    presentation: yup.string()
});

const RecipeForm: FC<CreateFormProps> = ({ isLoading, submit, recipeData, recipeImage }) => {

    const token = useSelector((state: RootState) => state.auth);
    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const mode = recipeData ? 'Editar' : 'Crear';
    const buttonTitle = mode === 'Editar' ? 'Guardar Cambios' : 'Crear';
    const recipeService = new RecipeService();

    const [image, setImage] = useState<File>(new File([], ''));
    const [selectedIngredient, setSelectedIngredient] = useState<any | null>(null);
    const [ingredientQuantity, setIngredientQuantity] = useState<number>(0);
    const [ingredientsList, setIngredientsList] = useState<any[]>([]);
    const [inputError, setInputError] = useState<boolean>(false);
    const [group, setGroup] = useState<string>('');
    const [newGroup, setNewGroup] = useState<string>('');
    const [processData, setProcessData] = useState<string[]>([]);

    const [foodsGroupsData, fetchingGroups, groupsError, refetchGroups] = useFetch(useCallback(async () => {
        const response = await (new FoodService()).getAlimentGroups();
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    const [foodsData] = useFetch(useCallback(async () => {
        const response = await recipeService.getIngredientsByCompany({ limit: 1000000 });
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    // Obtener el listado de grupos de alimentos
    const getFoodsGroupsList = () => {
        if (foodsGroupsData) {
            return foodsGroupsData.food_groups.map((group: { id: string; name: string; }) => {
                return {
                    value: group.id,
                    label: group.name,
                    //isSelected: formik.values.foodGroup === group.id
                }
            })
        }
        return [];
    };

    // Obtener el listado de grupos adicionales
    const getAditionalGroupsList = () => {
        const foodGroup = recipeData?.foodGroup || formik.values.foodGroup; // Id del grupo principal
        const secondaryGroups = recipeData?.secondaryGroups; // Grupos adicionales de la receta
        const newGroupList = foodsGroupsData?.food_groups.filter((group: any) => group.id !== foodGroup); // Lista de grupos excluyendo el principal 
        const secondaryGroupsIds = secondaryGroups?.map((group: any) => group.foodGroup?.id); // Ids de los grupos adicionales

        return newGroupList?.map((group: any) => {
            const isGroupSelected = secondaryGroupsIds?.includes(group.id);

            return {
                value: group.id,
                label: group.name,
                isSelected: isGroupSelected
            }
        }) || [];
    };

    // Obtener el listado de técnicas
    const getTechniquesList = () => {
        const techniques = formik.values.techniques || [];

        return TECHNIQUES.map((technique: { value: string; label: string }) => {
            return {
                value: technique.value,
                label: technique.label,
                isSelected: techniques.includes(technique.value)
            }
        })
    };

    // Obtener el listado de ingredientes
    const getIngredientsList = () => {
        if (foodsData as Aliments && foodsData.data) {
            return foodsData.data.map((aliment: any) => {
                return {
                    value: aliment.id,
                    label: aliment.name
                }
            })
        }
        return [];
    };

    // Obtener el nombre del grupo por su id
    const getGroupNameById = (id: string) => {
        const group = foodsGroupsData?.food_groups.find((group: any) => group.id === id);
        if (group !== undefined) return group?.name;
        return 'Sin definir';
    };

    // Envía los datos del formulario
    const _handleSubmit = (values: any) => {
        // Obtener los ids de los ingredientes que ya existen en la receta
        const recipeIngredientsIDs = recipeData?.ingredients?.map((ingredient: any) => {
            return {
                id: ingredient.food?.id,
                recipeIngredientId: ingredient.id
            }
        });

        ingredientsList.map((ingredient: any) => {
            // Reemplazar comas por puntos para permitir tanto ',' como '.' como separador decimal
            if (typeof ingredient.quantity === 'string') {
                ingredient.quantity = ingredient.quantity.replace(',', '.');
            }

            // Añadir el id del ingrediente a la lista de ingredientes si no existe ya
            recipeIngredientsIDs?.map((recipeIngredient: any) => {
                if (ingredient.food === recipeIngredient.id) {
                    ingredient.ingredient = recipeIngredient.recipeIngredientId;
                }
            })
        });

        if (inputError) {
            toast.error('El formato de la cantidad de los ingredientes es incorrecto')
            return;
        }

        // comprobar si values.secondaryGroups es un array de objetos, si lo es, actualizar el array para almacenar solo la id de cada grupo
        if (values.secondaryGroups) {
            if (values.secondaryGroups[0]?.id) {
                values.secondaryGroups = values.secondaryGroups.map((group: any) => group.foodGroup?.id);
            }
        }

        const recipe = {
            company: token?.user?.company,
            name: values.name,
            process: processData,
            duration: values.duration,
            difficulty: values.difficulty,
            foodGroup: values.foodGroup === group ? null : values.foodGroup,
            secondaryGroups: values.secondaryGroups,
            ingredients: ingredientsList,
            techniques: values.techniques,
            storageConditions: values.storageConditions,
            presentation: values.presentation,
            image: image
        };

        submit(recipe);
    };

    const formik = useFormik<RecipeForm>({
        initialValues: recipeData ? recipeData : recipeInitialValues,
        validationSchema: RecipeSchema,
        onSubmit: values => _handleSubmit(values),
    });

    // Cargar los ingredientes en ingredientList al editar
    useEffect(() => {
        if (recipeData?.ingredients) {
            const ingredients = recipeData.ingredients.map((ingredient: any) => {
                return {
                    ingredient: ingredient.id,
                    food: ingredient.food?.id,
                    name: ingredient.food?.name,
                    quantity: ingredient.quantity,
                    order_number: ingredient.order_number,
                    company: ingredient.companyFood?.id
                }
            });
            setIngredientsList(ingredients);
        }
    }, [recipeData]);

    // Carga el grupo de alimentos y el proceso al editar
    useEffect(() => {
        setGroup(formik.values.foodGroup);
        setProcessData(formik.values.process);
    }, []);

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                    </Button>
                    <SubheaderSeparator />
                    <CardTitle>{mode} Receta</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button color='primary' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner color='dark' isSmall /> : `${buttonTitle}`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>

            <Page container='fluid'>
                <form onSubmit={formik.handleSubmit} autoComplete="off" className="row">
                    <div className="col-md-2">
                        <ImageForm dataId={id} dataImage={recipeImage} name="la receta" service={recipeService} setImage={setImage} />
                    </div>

                    <div className="col-md-5 p-1">
                        <RecipeGeneralInfo
                            formik={formik}
                            mode={mode}
                            getTechniquesList={getTechniquesList}
                            getFoodsGroupsList={getFoodsGroupsList}
                            getAditionalGroupsList={getAditionalGroupsList}
                            getGroupNameById={getGroupNameById}
                            group={group}
                            newGroup={newGroup} setNewGroup={setNewGroup}
                            foodsGroupsData={foodsGroupsData}
                            refetchGroups={refetchGroups}
                        />

                        <Ingredients
                            ingredientsList={ingredientsList}
                            setIngredientsList={setIngredientsList}
                            selectedIngredient={selectedIngredient}
                            setSelectedIngredient={setSelectedIngredient}
                            ingredientQuantity={ingredientQuantity}
                            setIngredientQuantity={setIngredientQuantity}
                            inputError={inputError}
                            setInputError={setInputError}
                            foodList={getIngredientsList()}
                            foodsData={foodsData}
                        />
                    </div>

                    <div className="col-md-5">
                        <Process formik={formik} processData={processData} setProcessData={setProcessData} />
                    </div>
                </form>
            </Page>
        </Fragment>
    )
}

export default RecipeForm;
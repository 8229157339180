import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import useFetch from "../../hooks/useFetch";
import { toast } from "react-toastify";
import Accordion, { AccordionItem } from "../../components/bootstrap/Accordion";
import { useNavigate, useParams } from "react-router-dom";
import AsyncImg from "../../components/AsyncImg";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Checks from "../../components/bootstrap/forms/Checks";
import Page from "../../layout/Page/Page";
import { NUTRIENTS, monthsList } from "../../utils/FoodUtils";
import * as yup from "yup";
import CustomNumberInput from "../../components/bootstrap/forms/CustomNumberInput";
import { FixNumber } from "../../utils/FixNumber";
import { FoodService } from "../../services/foods/foodService";
import "./../../styles/styles.scss";

interface CreateFormProps {
  isLoading: boolean;
  submit: (values: FoodForm) => void;
  alimentData?: FoodForm;
  foodImage?: any;
}

interface INutrientArray {
  name_en?: string;
  value?: number;
}

interface FoodForm {
  id: string;
  name: string;
  name_en: string;
  group_food: string;
  edible_part: number;
  nutrients: INutrientArray[];
  season: string;
  seasonend: string;
  supplement: boolean;
}

const initialValues: FoodForm = {
  id: '',
  name: '',
  name_en: '',
  group_food: '',
  edible_part: 100,
  nutrients: [{}],
  season: 'enero',
  seasonend: 'diciembre',
  supplement: false,
}

const AlimentEditSchema = yup.object({
  name: yup.string().min(1, 'Demasiado Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
  name_en: yup.string().min(1, 'Demasiado Corto').max(50, 'Demasiado Largo').notRequired(),
  edible_part: yup.number().min(1, 'El mínimo es 1').max(100, 'El máximo es 100').notRequired(),
  group_food: yup.string().required('Campo Obligatorio'),
  season: yup.string().min(1, 'Demasiado Corto').max(10, 'Demasiado Largo').notRequired(),
  seasonend: yup.string().min(1, 'Demasiado Corto').max(10, 'Demasiado Largo').notRequired(),
  supplement: yup.boolean().notRequired(),
});

const FoodForm: FC<CreateFormProps> = ({ isLoading, submit, alimentData, foodImage }) => {

  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const mode = alimentData ? 'Editar' : 'Crear';
  const buttonTitle = mode === 'Editar' ? 'Guardar Cambios' : 'Crear';

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [alimentValues, setAlimentValues] = useState<any>(initialValues);
  const [nutrientsGroups, setNutrientsGroups] = useState<any>([]);
  const [inputError, setInputError] = useState<boolean>(false);

  const [food] = useFetch(useCallback(async () => {
    if (!id) return;
    const response = (await (new FoodService()).getAlimentById(id)).getResponseData();
    return response;
  }, []));

  const [alimentGroups] = useFetch(useCallback(async () => {
    const response = (await (new FoodService()).getAlimentGroups()).getResponseData();
    return response;
  }, []));

  const [nutrientsList] = useFetch(useCallback(async () => {
    const response = (await (new FoodService()).getNutrientsList()).getResponseData();
    return response;
  }, []));

  const [allergensList] = useFetch(useCallback(async () => {
    const response = (await (new FoodService()).getAllergensList()).getResponseData();
    return response;
  }, []));

  // Agrupar nutrientes
  const groupNutrients = (nutrients: []) => {
    // Classify nutrients by group
    const groups = nutrients.reduce((acc: any, nutrient: any) => {

      const nutrientGroupExists = acc.find((group: any) => group.group === nutrient.group);

      if (!nutrientGroupExists) {
        acc.push({
          group: nutrient.group,
          group_name: nutrient.grupo,
          nutrients: []
        })
      }

      return acc;
    }, []);

    // Add nutrients to their respective group
    nutrients.forEach((nutrient: any) => {
      const groupIndex = groups.findIndex((group: any) => group.group === nutrient.group);

      // If food exists and has a value, add it to the nutrient
      if (food && food[nutrient.name]) {
        nutrient.value = food[nutrient.name];
      } else {
        nutrient.value = 0;
      }

      groups[groupIndex].nutrients.push(nutrient);
    });

    setNutrientsGroups(groups);
  };

  const handleImageUpload = async (event: React.ChangeEvent<any>) => {
    const file = event.target.files[0] as File;
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);

    try {
      const response = await (new FoodService()).editAlimentImg(id, file);
      const responseData = response.getResponseData();

      if (responseData.success) {
        setTimeout(() => {
          toast.success('Imagen actualizada');
        }, 100);
      }
    } catch (error: any) {
      toast.error("Formato de imagen incorrecto");
    }
  };

  const deleteImage = async () => {
    try {
      const response = await (new FoodService()).deleteAlimentImg(id);
      const responseData = response.getResponseData();

      if (responseData.success) {
        setSelectedImage(null);
        foodImage = null;
        setTimeout(() => {
          toast.success('Imagen eliminada');
        }, 100);
      }
    } catch (error: any) {
      toast.error("Error al eliminar la imagen");
    }
  };

  // Select de grupos de alimentos
  const getFoodGroups = () => {
    if (alimentGroups) {
      return alimentGroups?.food_groups.map((alimentData: { id: any; name: any; }) => {
        return {
          key: alimentData.id,
          value: alimentData.id,
          label: alimentData.name
        }
      })
    }
    return [];
  };

  // Setear valores iniciales del formulario
  const formik = useFormik({
    initialValues: alimentValues,
    validationSchema: AlimentEditSchema,
    onSubmit: values => {
      if (food) { // Añadir id si está en modo edición y existe
        values = { ...values, food: food.id }
      }

      // Recorrer values y comparar con NUTRIENTS.value para saber cuales son los nutrientes. Y si el nutriente es de tipo string, cambiar la coma por un punto
      Object.keys(values).forEach((key: any) => {
        NUTRIENTS.forEach((nutrient: any) => {
          if (key === nutrient.value) {
            if (typeof values[key] === 'string') {
              values[key] = values[key].replace(',', '.');
            }
          }
        });
      });

      if (inputError) {
        toast.error('El formato de la cantidad de los nutrientes es incorrecto');
        return;
      }

      submit(values);
    }
  });

  const verifyClass = (inputFieldID: keyof FoodForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

  const showErrors = (inputFieldID: keyof FoodForm) => {
    // @ts-ignore
    return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]} </div> : <></>;
  };

  // Cuando se obtiene el alimento, setear los valores del formulario
  useEffect(() => {
    if (food != null) {
      formik.setValues({
        ...food,
        name_en: food.nameEn,
        edible_part: food.ediblePart,
        group_food: food.foodGroup?.id,
      })
    }
  }, [food]);

  // Cuando se obtiene la lista de nutrientes, los agrupa
  useEffect(() => {
    if (nutrientsList) {
      groupNutrients(nutrientsList);
    }
  }, [nutrientsList]);

  // Renderizar formulario
  const getContent = () => {
    return (
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className='row'>
          {mode === 'Editar'
            ? (
              <div className="col-md-2">
                <Card stretch={false} className='mx-2'>
                  <CardBody className='text-center'>
                    <div className='text-center pb-4'>
                      {selectedImage
                        ? <img width={200} height={200} src={selectedImage} alt="selected"
                          className='mx-auto d-block img-fluid mb-3 rounded' />
                        : foodImage
                          ? <AsyncImg isBackground height="200px" width="200px" styles="avatar-content" id={foodImage.id} />
                          : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                      }
                    </div>
                    <Input
                      type='file'
                      onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                      autoComplete='photo'
                      placeholder="Cambiar imagen"
                    />
                  </CardBody>
                  <CardFooter className='justify-content-center'>
                    <Button color='dark' isLight icon='Delete' onClick={deleteImage}>Eliminar imagen</Button>
                  </CardFooter>
                </Card>
              </div>
            )
            : <></>
          }

          <div className={"col-md-6"}>
            <Card stretch={false} className={'mx-2'}>
              <CardBody>
                <div className={'row'}>
                  <div className={"col-md-4"}>
                    <CardTitle>Datos</CardTitle>
                    <FormGroup requiredInputLabel label='Nombre' className={'pb-2'}>
                      <Input id='name' onChange={formik.handleChange} value={formik.values.name}
                        onBlur={formik.handleBlur} className={verifyClass('name')} />
                      {showErrors('name')}
                    </FormGroup>
                  </div>

                  <div className={"col-md-4"}>
                    <CardTitle>Temporalidad</CardTitle>
                    <FormGroup label='Mes de inicio' className={'pb-2'}>
                      <Select
                        name="season"
                        id='season'
                        list={monthsList}
                        onChange={formik.handleChange}
                        value={formik.values.season}
                        onBlur={formik.handleBlur} className={verifyClass('season')}
                        ariaLabel='Mes de inicio de temporada'
                      />
                      {showErrors('season')}
                    </FormGroup>

                    <FormGroup label='Mes de fin' className={'pb-2'}>
                      <Select
                        name="seasonend"
                        id='seasonend'
                        list={monthsList}
                        onChange={formik.handleChange}
                        value={formik.values.seasonend}
                        onBlur={formik.handleBlur} className={verifyClass('seasonend')}
                        ariaLabel='Mes de fin de temporada'
                      />
                      {showErrors('seasonend')}
                    </FormGroup>
                  </div>

                  <div className={"col-md-4"}>
                    <CardTitle>Información nutricional</CardTitle>
                    <>
                      <div className="row">
                        <FormGroup requiredInputLabel id='food_group_form' label='Grupo de alimentos' className={'pb-2'} key={alimentGroups}>
                          <Select
                            name="group_food"
                            id='group_food'
                            list={getFoodGroups()}
                            onChange={formik.handleChange}
                            value={formik.values.group_food}
                            onBlur={formik.handleBlur} className={verifyClass('group_food')}
                            ariaLabel='Grupos de alimentos'
                          />
                          {showErrors('group_food')}
                        </FormGroup>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup label='Comestible (%)' className='pb-2'>
                            <Input
                              id='edible_part'
                              onChange={formik.handleChange}
                              value={formik.values.edible_part}
                              onBlur={formik.handleBlur}
                              className={verifyClass('edible_part')}
                              placeholder={'' + formik.values.edible_part}
                            />
                            {showErrors('edible_part')}
                          </FormGroup>
                        </div>

                        <div className="col-md-6">
                          <FormGroup label='Suplemento' className='pb-2'>
                            <Checks
                              name='supplement'
                              id='supplement'
                              onChange={formik.handleChange}
                              checked={formik.values.supplement}
                              type='switch'
                              className="mt-3"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </CardBody>
            </Card>

            <CardTitle className={'pb-4 h3 mx-2'}>Nutrientes (para 100g)</CardTitle>
            <Accordion id={"nutrients-group"} className="mb-5 mx-2" shadow={'none'} isFlush={true}>
              {nutrientsGroups !== null && nutrientsGroups.length > 0 &&
                nutrientsGroups.map((nutrientGroup: any) => (
                  <AccordionItem key={`group-${nutrientGroup.group}`} id={nutrientGroup.group} title={nutrientGroup.group_name}>
                    {nutrientGroup.nutrients.map((nutrient: any) => (
                      <div key={`nutrient-${nutrient.name}`} className={'w-25 d-inline-block p-2'}>
                        <CustomNumberInput
                          id={nutrient.name}
                          label={nutrient.nombre}
                          value={FixNumber(formik.values[nutrient.name], 2) || 0}
                          onChange={(value: any) => formik.setFieldValue(nutrient.name, value)}
                          setError={setInputError}
                        />
                      </div>
                    ))}
                  </AccordionItem>
                ))}
            </Accordion>
          </div>

          <div className={"col-md-4"}>
            <Card stretch={false} className={'mx-2'}>
              <CardBody>
                <CardTitle>Alérgenos</CardTitle>
                <span className={'form-label'}>Selecciona los alérgenos que posea el alimento:</span>
                {
                  allergensList?.map((allergen: any, index: number) => (
                    <FormGroup key={`allergen-${index}`}>
                      <Checks
                        name={allergen.name}
                        id={`check-${allergen.name.toLowerCase()}`}
                        key={allergen.id}
                        onChange={formik.handleChange}
                        checked={formik.values[allergen.name]}
                        type={'switch'}
                        label={(
                          <div className="ps-3">
                            <img key={allergen.nombre} className="d-inline-block" src={`/allergens/${allergen.name}.png`} alt={allergen.nombre} width={35} data-bs-toggle="tooltip" title={allergen.nombre} />
                            <span className="form-label ps-3 capitalize">{allergen.nombre}</span>
                          </div>
                        )}
                        className="mt-3"
                      />
                    </FormGroup>
                  ))
                }
              </CardBody>
            </Card>
          </div>
        </div>
      </form>
    )
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            onClick={() => navigate(-1)}
            icon="ArrowBack"
            color="secondary"
            isLink
          />
          <SubheaderSeparator />
          <CardTitle>{mode} alimento</CardTitle>
        </SubHeaderLeft>
        <SubHeaderRight>
          <Button type="submit" color='primary' onClick={formik.handleSubmit}>
            {isLoading ? <Spinner /> : `${buttonTitle} Alimento`}
          </Button>
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        {(mode === "Editar" && food) && getContent()}
        {mode === "Crear" && getContent()}
      </Page>
    </Fragment>
  )
}

export default FoodForm;
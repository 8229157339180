import React, { useCallback } from 'react';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import SearchableSelect from '../../../components/select/SearchableSelect';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useFetch from '../../../hooks/useFetch';
import { FormService } from '../../../services/forms/formService';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody, CardTitle } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../../layout/Page/Page';
import FormFields from './FormFields';

interface FormFormProps {
    submit: (values: any) => void;
    isLoading: boolean;
    data?: any;
}

export interface Form {
    form: string;
    name: string;
    formType: string;
    fields: FormField[];
}

export interface FormField {
    formFieldId: string;
    order: number;
    name: string;
    required: boolean;
    type: string;
    main: boolean;
    columnSize: number;
    options: string[];
}

const formFieldInitialValues: FormField = {
    formFieldId: '',
    order: 0,
    name: '',
    required: false,
    type: 'number',
    main: false,
    columnSize: 12,
    options: [],
}

const formSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    formType: yup.string().required('El tipo de formulario es obligatorio'),
    fields: yup.array().of(
        yup.object().shape({
            name: yup.string().required('El nombre del campo es obligatorio'),
            required: yup.boolean().notRequired(),
            type: yup.string().required('El tipo de campo es obligatorio'),
            main: yup.boolean().notRequired(),
            columnSize: yup.number().required('El tamaño de la columna es obligatorio'),
            //options: yup.array().of(yup.string()).notRequired().nullable(),
        })
    ).notRequired(),
});

const FormForm: React.FC<FormFormProps> = ({ submit, isLoading, data }) => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const mode = data ? 'Editar' : 'Crear';

    const [field, setField] = React.useState<FormField>(formFieldInitialValues);

    const [formTypeData] = useFetch(useCallback(async () => {
        const response = (await (new FormService).getFormTypes());
        return response.getResponseData();
    }, []));

    const getFormTypesList = () => {
        if (formTypeData) {
            return formTypeData.formTypes?.map((formType: any) => {
                return {
                    value: formType.id,
                    label: formType.name
                }
            })
        }
        return [];
    };

    const formik = useFormik<Form>({
        initialValues: {
            form: data?.id || id,
            name: data?.name || '',
            formType: data?.formType?.id || '',
            fields: data?.formFields ? data.formFields.map((f: any) => ({
                formFieldId: f.id,
                name: f.name,
                required: f.required,
                type: f.type,
                main: f.main,
                order: f.order,
                columnSize: f.columnSize,
                options: f.options
            })) : [],
        },
        validationSchema: formSchema,
        onSubmit: values => {
            // Add field to fields if it has a name
            if (field.name) {
                values.fields.push(field);
            }
            // Add order to fields depending on the index
            values.fields.forEach((field, index) => {
                field.order = index;
            });
            submit(values);
        }
    });

    const verifyClass = (inputFieldID: keyof Form) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''; }

    const showErrors = (inputFieldID: keyof Form) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Formulario</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit} isDisable={isLoading}>
                        {isLoading ? <Spinner color={'dark'} /> : `${mode} Formulario`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid'>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Card>
                        <CardBody>
                            <div className="row">
                                <FormGroup requiredInputLabel label='Nombre' className="col-md-4">
                                    <Input
                                        id='name'
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        onBlur={formik.handleBlur}
                                        className={verifyClass('name')}
                                    />
                                    {showErrors('name')}
                                </FormGroup>

                                <FormGroup requiredInputLabel label='Tipo de formulario' className="col-md-3">
                                    <SearchableSelect
                                        name="formType"
                                        isSearchable
                                        options={getFormTypesList()}
                                        onChange={(e: any) => formik.setFieldValue('formType', e.value)}
                                        value={{ value: formik.values.formType, label: formTypeData?.formTypes?.find((formType: any) => formType.id === formik.values.formType)?.name }}
                                        placeholder='tipo'
                                        classname={verifyClass('formType')}
                                    />
                                    {showErrors('formType')}
                                </FormGroup>
                            </div>

                            <FormFields formik={formik} field={field} setField={setField} verifyClass={verifyClass} showErrors={showErrors} />
                        </CardBody>
                    </Card>
                </form>
            </Page>
        </>
    );
};

export default FormForm;
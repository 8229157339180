import React, { useCallback, useEffect, useState } from "react";
import { APPOINTMENT_SERVICES_MAP, APPOINTMENT_STATUSES, AppointmentFormData, PAYMENT_METHODS_MAP, getAppointmentServiceType, getAppointmentStatus, getAppointmentType } from "../../../type/appointment-type";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Input from "../../../components/bootstrap/forms/Input";
import moment from "moment";
import Button from "../../../components/bootstrap/Button";
import { useFormik } from "formik";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import useFetch from "../../../hooks/useFetch";
import SearchableSelect from "../../../components/select/SearchableSelect";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { AppointmentService } from "../../../services/appointments/appointmentService";
import useHandleErrors from "../../../utils/hooks/useHandleErrors";
import { toast } from "react-toastify";
import { CirclePicker, ColorResult } from 'react-color';
import * as yup from "yup";
import Select from "../../../components/bootstrap/forms/Select";
import Spinner from "../../../components/bootstrap/Spinner";
import { useAppointmentCalendar } from "../providers/AppointmentCalendarProvider";

interface AppointmentFormProps {
    appointment: AppointmentFormData | null;
    onSubmitted?: (values: any) => void;
}

const AppointmentFormSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio').min(1, 'Demasiado Corto').max(40, 'Demasiado Largo'),
    start: yup.date().required('La fecha de inicio es obligatoria'),
    end: yup.date().required('La fecha de fin es obligatoria'),
    user: yup.string().optional(),
    client: yup.string().nullable().optional(),
    comments: yup.string().nullable().optional(),
    type: yup.string().required('El tipo de cita es obligatorio'),
    serviceType: yup.string().optional(),
    status: yup.string().required("El estado de la cita es obligatorio"),
    appointment_color: yup.string().nullable().optional(),
    paymentMethod: yup.string().optional(),
    price: yup.number().min(0, 'El precio mínimo es 0€').max(1000000, 'El precio máximo es 1.000.000€').optional(),
});

export const AppointmentForm: React.FC<AppointmentFormProps> = ({ appointment, onSubmitted }) => {

    const { setOpenPatientModal, newPatientSelected } = useAppointmentCalendar();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const appointmentService = new AppointmentService();

    const [selectedUser, setSelectedUser] = useState<any | null>(null);
    const [selectedPatient, setSelectedPatient] = useState<any | null>(null);

    const serviceOptions = Object.values(APPOINTMENT_SERVICES_MAP).map((service) => ({
        value: service.name,
        label: service.label,
    }));

    // check if received default status, we set it to the select
    const receivedAppointmentStatus = appointment?.status && getAppointmentStatus(appointment?.status);
    const [selectedStatus, setSelectedStatus] = useState<any | null>(receivedAppointmentStatus && { value: receivedAppointmentStatus.id, label: receivedAppointmentStatus.name });

    const receivedAppointmentType = appointment?.type && getAppointmentType(appointment?.type);
    const [selectedAppointmentType, setSelectedAppointmentType] = useState<any | null>(receivedAppointmentType && { value: receivedAppointmentType.name, label: receivedAppointmentType.label });

    const receivedServiceType = appointment?.serviceType && getAppointmentServiceType(appointment?.serviceType);
    const [selectedServiceType, setSelectedServiceType] = useState<any | null>(receivedServiceType ? { value: receivedServiceType.name, label: receivedServiceType.label } : serviceOptions[0]);

    const [usersResponse] = useFetch(useCallback(async () => {
        if (!userCan('assign_other_users_to_appointment', 'appointments')) return;
        const response = (await appointmentService.listUsers({ limit: 100, filter_filters: { active: 1 } })).getResponseData();
        return response;
    }, []));

    const [clientsResponse] = useFetch(useCallback(async () => {
        const response = (await appointmentService.listClients({ limit: 100, filter_filters: { active: 1 } })).getResponseData();
        return response;
    }, []));

    const [appointmentTypes] = useFetch(useCallback(async () => {
        const response = (await appointmentService.getTypes()).getResponseData();
        return response;
    }, []));

    const createAppointment = async (values: any) => {
        const response = (await appointmentService.createAppointment(values)).getResponseData();
        if (response.success) {
            toast.success('Cita creada correctamente');
        } else {
            handleErrors(response)
        }
        return response;
    }

    const updateAppointment = async (values: any) => {
        const response = (await appointmentService.updateAppointment(values)).getResponseData();
        if (response.success) {
            toast.success('Cita actualizada correctamente');
        } else {
            handleErrors(response)
        }
        return response;
    }

    // mark as selected the user if the appointment data received a selected user
    useEffect(() => {
        if (usersResponse?.users && appointment?.user) {
            let foundUser = usersResponse?.users.find((user: any) => user.id === appointment?.user);
            if (foundUser) {
                setSelectedUser({ value: foundUser.id, label: `${foundUser.name} ${foundUser.lastName} (${foundUser.email})` })
            }
        }
    }, [usersResponse]);

    // mark as selected the client if the appointment data received a selected client
    useEffect(() => {
        if (clientsResponse?.patients && appointment?.client) {
            let foundPatient = clientsResponse?.patients.find((patient: any) => patient.id === appointment?.client);
            if (foundPatient) {
                setSelectedPatient({ value: foundPatient.id, label: `${foundPatient.name} ${foundPatient.lastName} (${foundPatient.email})` })
            }
        }
    }, [clientsResponse]);

    // mark as selected the appointmentType if the appointment data received a selected appointmentType
    useEffect(() => {
        if (appointmentTypes && appointment?.type) {
            let foundAppointmentType = appointmentTypes.find((type: any) => type.id === appointment?.type);
            if (foundAppointmentType) {
                setSelectedAppointmentType({ value: foundAppointmentType.id, label: foundAppointmentType.label })
            }
        }
    }, [appointmentTypes]);

    useEffect(() => {
        if (newPatientSelected && newPatientSelected.value !== '') {
            formik.setFieldValue('client', newPatientSelected.value);
            setSelectedPatient(newPatientSelected);
        }
    }, [newPatientSelected]);

    const verifyClass = (inputFieldID: string) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : ''
    }

    const showErrors = (inputFieldID: string) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]} </div> : <></>;
    }

    const formik = useFormik({
        validationSchema: AppointmentFormSchema,
        initialValues: appointment || {},
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                start_date: moment(values.start).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(values.end).format('YYYY-MM-DD HH:mm:ss'),
            }

            if (appointment?.id) {
                // Update appointment
                // @ts-ignore
                formattedValues.appointment = appointment?.id;
                await updateAppointment(formattedValues);
            } else {
                // Create appointment
                await createAppointment(formattedValues);
            }

            onSubmitted && onSubmitted(formattedValues);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className='row g-4'>
                {/* Name */}
                <div className='col-12'>
                    <FormGroup requiredInputLabel label='Asunto'>
                        <Input id="name" type='text' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('name')} />
                        {showErrors('name')}
                    </FormGroup>
                </div>

                {/* Comments */}
                <div className='col-12'>
                    <FormGroup label='Observaciones'>
                        <Textarea id="comments" value={formik.values.comments || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} className={verifyClass('comments')} />
                        {showErrors('comments')}
                    </FormGroup>
                </div>

                {/* Dates */}
                <div className='col-12'>
                    <Card className='mb-0' color="primary" shadow='sm'>
                        <CardHeader className='bg-l25-secondary'>
                            <CardLabel>
                                <CardTitle className='text-secondary'>
                                    Fechas Seleccionadas
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-3'>
                                <div className='col-12'>
                                    <FormGroup requiredInputLabel label="Fecha de inicio">
                                        <Input
                                            id="start"
                                            type={'datetime-local'}
                                            step={300}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('start')}
                                            value={formik.values.start ? moment(formik.values.start).format('YYYY-MM-DDTHH:mm') : ''}
                                            onChange={(event: any) => {
                                                formik.setFieldValue('start', event.target.value);
                                                formik.setFieldValue('end', moment(event.target.value).add(30, 'minutes').format('YYYY-MM-DDTHH:mm'));
                                            }}
                                        />
                                        {showErrors('start')}
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup requiredInputLabel label="Fecha de fin">
                                        <Input
                                            id="end"
                                            type={'datetime-local'}
                                            step={300}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('end')}
                                            value={formik.values.end ? moment(formik.values.end).format('YYYY-MM-DDTHH:mm') : ''}
                                            onChange={(event: any) => {
                                                formik.setFieldValue('end', event.target.value);
                                            }}
                                        />
                                        {showErrors('end')}
                                    </FormGroup>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                {/* Client */}
                {clientsResponse?.patients && (
                    <div className='col-12'>
                        <Card className='mb-0' color="primary" shadow='sm'>
                            <CardHeader className='bg-l25-secondary'>
                                <CardLabel>
                                    <CardTitle>Seleccionar paciente</CardTitle>
                                </CardLabel>
                                <CardActions>
                                    <Button
                                        color='primary'
                                        onClick={() => { setOpenPatientModal(true) }}
                                        icon="Add"
                                    />
                                    <Button
                                        color='danger'
                                        onClick={() => {
                                            setSelectedPatient(null)
                                            formik.setFieldValue('client', null)
                                        }}
                                        icon="Delete"
                                    />
                                </CardActions>
                            </CardHeader>
                            <CardBody>
                                <FormGroup label="">
                                    <SearchableSelect
                                        name="client"
                                        isSearchable
                                        onBlur={formik.handleBlur}
                                        completePlaceholder='Paciente existente...'
                                        value={selectedPatient}
                                        onChange={(newValue: any) => {
                                            setSelectedPatient(newValue)
                                            formik.setFieldValue('client', newValue.value)
                                        }}
                                        options={clientsResponse?.patients.map((u: any) => ({
                                            value: u.id,
                                            label: `${u.name} ${u.lastName} (${u.email})`,
                                        }))}
                                    />

                                    {showErrors('client')}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </div>
                )}

                {/* User */}
                {usersResponse?.users && (
                    <div className='col-12'>
                        <Card className='mb-0' color="primary" shadow='sm'>
                            <CardHeader className='bg-l25-secondary'>
                                <CardLabel icon='User Add' iconColor='secondary'>
                                    <CardTitle>Asignar a otro usuario</CardTitle>
                                </CardLabel>
                                <CardActions>
                                    <Button color='danger' onClick={() => {
                                        setSelectedUser(null)
                                        formik.setFieldValue('user', null)
                                    }} icon="Delete" />
                                </CardActions>
                            </CardHeader>
                            <CardBody>
                                <FormGroup label="">
                                    <SearchableSelect
                                        name="user"
                                        isSearchable
                                        onBlur={formik.handleBlur}
                                        placeholder='Usuario'
                                        value={selectedUser}
                                        onChange={(newValue: any) => {
                                            setSelectedUser(newValue)
                                            formik.setFieldValue('user', newValue.value)
                                        }}
                                        options={usersResponse?.users.map((u: any) => ({
                                            value: u.id,
                                            label: `${u.name} ${u.lastName ? u.lastName : ""} (${u.email})`,
                                        }))}
                                    />
                                    {showErrors('user')}
                                    {!selectedUser ? (<small className="text-muted mt-1">Si no seleccionas un usuario se te asignará la cita a ti.</small>) : <></>}

                                </FormGroup>
                            </CardBody>
                        </Card>
                    </div>
                )}

                {/* Appointment configuration */}
                {appointmentTypes && (
                    <div className='col-12'>
                        <Card className='mb-0' color="primary" shadow='sm'>
                            <CardHeader className='bg-l25-primary'>
                                <CardLabel icon='User Add' iconColor='primary'>
                                    <CardTitle>Configuración de la cita</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <FormGroup className="mb-3" label="Tipo" requiredInputLabel>
                                    <SearchableSelect
                                        name="appointment_type"
                                        isSearchable
                                        classname={verifyClass('type')}
                                        onBlur={formik.handleBlur}
                                        placeholder='Tipo de la cita'
                                        value={selectedAppointmentType}
                                        onChange={(newValue: any) => {
                                            setSelectedAppointmentType(newValue ? newValue : null)
                                            formik.setFieldValue('type', newValue.value)
                                        }}
                                        options={appointmentTypes.map((type: any) => ({
                                            value: type.name,
                                            label: type.label,
                                        }))}
                                    />
                                    {showErrors('type')}
                                </FormGroup>

                                <FormGroup label="Servicio" className="mb-3" requiredInputLabel>
                                    <SearchableSelect
                                        name="serviceType"
                                        isSearchable
                                        classname={verifyClass('serviceType')}
                                        onBlur={formik.handleBlur}
                                        placeholder='Servicio de la cita'
                                        value={selectedServiceType}
                                        defaultValue={null}
                                        onChange={(newValue: any) => {
                                            setSelectedServiceType(newValue ? newValue : null)
                                            formik.setFieldValue('serviceType', newValue.value)
                                        }}
                                        options={serviceOptions}
                                    />
                                    {showErrors('serviceType')}
                                </FormGroup>

                                <FormGroup label="Estado" className="mb-3" requiredInputLabel>
                                    <SearchableSelect
                                        name="status"
                                        isSearchable
                                        classname={verifyClass('status')}
                                        onBlur={formik.handleBlur}
                                        placeholder='Estado de la cita'
                                        value={selectedStatus}
                                        onChange={(newValue: any) => {
                                            setSelectedStatus(newValue)
                                            formik.setFieldValue('status', newValue.value)
                                        }}
                                        options={APPOINTMENT_STATUSES.map((status: any) => ({
                                            value: status.id,
                                            label: status.name,
                                        }))}
                                    />
                                    {showErrors('status')}
                                </FormGroup>

                                <FormGroup className="mb-3" label="Color">
                                    <CirclePicker color={formik.values.appointment_color ? formik.values.appointment_color : ''} onChangeComplete={(color: ColorResult) => {
                                        formik.setFieldValue('appointment_color', color ? color.hex : null)
                                    }} />
                                    {showErrors('appointment_color')}
                                </FormGroup>

                                <div className="row">
                                    <div className="col-md-6">
                                        <FormGroup className="mb-3" label="Método de pago" requiredInputLabel>
                                            <Select
                                                id="paymentMethod"
                                                value={formik.values.paymentMethod || 'Tarjeta'}
                                                list={PAYMENT_METHODS_MAP}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('paymentMethod')}
                                                ariaLabel="Método de pago"
                                            />
                                            {showErrors('paymentMethod')}
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup className="mb-3" label="Importe (€)" requiredInputLabel>
                                            <Input
                                                id="price"
                                                type='number'
                                                value={formik.values.price || 0}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('price')}
                                            />
                                            {showErrors('price')}
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                )}

                <div className='d-flex justify-content-end text-right relative'>
                    <Button color='primary' className="flex-grow-1" type='submit' onClick={formik.handleSubmit}>
                        Guardar
                    </Button>
                </div>
            </div>
        </form>
    );
}
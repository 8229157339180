import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import Chat, { ChatGroup } from "./Chat";
import InputGroup from "../bootstrap/forms/InputGroup";
import Textarea from "../bootstrap/forms/Textarea";
import Button from "../bootstrap/Button";
import { toast } from "react-toastify";
import { ChatService } from "../../services/chat/chatService";
import useFetch from "../../hooks/useFetch";
import { EventSourcePolyfill } from "event-source-polyfill";
import { DocumentService } from "../../services/documents/documentService";
import Spinner from "../bootstrap/Spinner";
import AsyncImg from "../AsyncImg";
import moment from "moment";
import './ClientChat.css';

type ClientChatProps = {
    client: string
}

const ClientChat: React.FC<ClientChatProps> = ({ client }) => {

    const fileInputRef = useRef<HTMLInputElement>(null);
    const chatService = new ChatService();

    const [allMessages, setAllMessages] = useState<any[]>([]);
    const [newMessage, setNewMessage] = useState<string | undefined>(undefined);
    const [receivedMessage, setReceivedMessage] = useState<any | null>(null)

    const [chat, loadingChat, chatError] = useFetch(useCallback(async () => {
        const response = await chatService.getPatientMessages(client);
        return response.getResponseData();
    }, []));

    useEffect(() => {
        createEventSource();
    }, []);

    useEffect(() => {
        setAllMessages(chat);
    }, [chat]);

    useEffect(() => {
        _sendScrollDown();
    }, [allMessages]);

    useEffect(() => {
        if (receivedMessage !== null) {
            _pushNewMessage();
        }
    }, [receivedMessage]);

    const _pushNewMessage = () => {
        setAllMessages([receivedMessage, ...allMessages]);
        setReceivedMessage(null);
    };

    // Mueve el scroll al final del chat
    const _sendScrollDown = () => {
        setTimeout(() => {
            const chat = document.querySelector('.chat-container');
            chat?.scrollTo({
                top: chat.scrollHeight,
                behavior: 'smooth'
            });
            const chatGroup = document.querySelector('.chat-group');
            chatGroup?.scrollTo({
                top: chatGroup.scrollHeight,
                behavior: 'smooth'
            });
        }, 100);
    };

    const _handleSendMessage = async () => {
        if (newMessage?.length != 0 && newMessage !== "") {
            try {
                const response = await chatService.sendMessage(client, newMessage ?? "");
                const responseData = response.getResponseData();
                if (responseData.success) {
                    setNewMessage('');
                } else {
                    toast.error('Error al enviar el mensaje');
                }
            } catch (error: any) {
                toast.error("Error al enviar el mensaje");
            }
        }
    };

    const _handleSendDocument = async (event: React.ChangeEvent<any>) => {
        try {
            const file = event.target.files && event.target.files[0];
            const response = (await chatService.sendDocument(client, file)).getResponseData();
            if (!response.success) {
                toast.error('Error al enviar el archivo');
            }
        } catch (error: any) {
            toast.error("Error al enviar el archivo");
        }
    };

    const _downloadDocument = async (documentId: string, documentName: string) => {
        try {
            const response = (await chatService.downloadDocument(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar el archivo');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _handleEventMessage = (event: any) => {
        const data = JSON.parse(event.data);

        // Crea un nuevo mensaje con los datos recibidos del evento
        const newDataMessage = {
            id: data.id ? data.id : '',
            data: data.message.message || (<>{drawImage(data.message.document.id, data.message.document.originalName)}</>),
            sender: data.user,
            isUser: data.isUser,
            sentAt: data.sentAt,
        };

        setReceivedMessage(newDataMessage);
    };

    const drawImage = (id: string, src: string) => {
        const fileExtension = src.slice(((src.lastIndexOf(".") - 1) >>> 0) + 2);
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

        if (imageExtensions.includes(fileExtension.toLowerCase())) {
            return (
                <>
                    <div className="row">
                        <AsyncImg
                            id={id}
                            width='120px'
                            height='120px'
                            styles='rounded mb-3'
                        />
                    </div>
                    <div className="row">
                        <div
                            onClick={() => { _downloadDocument(id, src) }}
                            className="cursor-pointer text-secondary w-auto col-md-12"
                        >
                            {src}
                        </div>
                    </div>
                </>
            );
        }
        else {
            return <div onClick={() => { _downloadDocument(id, src) }} className="cursor-pointer text-secondary w-auto">{src}</div>;
        }
    };

    const createEventSource = async () => {
        if (!process.env.REACT_APP_MERCURE_URL) {
            toast.error("URL de mercure no está definida como variable de entorno");
            return;
        }
        // Crea la URL de suscripción a mercure
        const subscribeURL = new URL(process.env.REACT_APP_MERCURE_URL);

        // Añade los parámetros necesarios para suscribirse al canal de mensajes de un paciente
        subscribeURL.searchParams.append("topic", client);

        // Obtiene el token de mercure
        const mercureTokenResponse = await (await (new ChatService()).getMercureToken()).getResponseData();
        const token = mercureTokenResponse?.data;

        // Crea un evento source para escuchar los eventos de mercure
        const eventSource = new EventSourcePolyfill(subscribeURL, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        // Escucha los eventos de mercure
        eventSource.onmessage = (event: any) => {
            _handleEventMessage(event);
        };
    };

    if (loadingChat) return <div className="text-center"><Spinner /></div>;

    if (chatError) return <h5 className="text-center text-muted mt-5 mb-5">Error al cargar los mensajes</h5>;

    return (
        <section className="client-chat">
            {allMessages && (
                <Chat className="mh-100">
                    {
                        [...allMessages]?.reverse().map((msg: any) => {
                            const msgId: string = msg.id;
                            const messageData = msg.data || (<>{drawImage(msg.document.id, msg.document.originalName)}</>);
                            return (
                                <ChatGroup
                                    key={msg.id}
                                    messages={[
                                        {
                                            id: msgId,
                                            message: messageData,
                                            sentAt: moment(msg.sentAt.date).format('DD/MM/YYYY HH:mm')
                                        }
                                    ]}
                                    user={{
                                        name: msg.sender.name,
                                        src: (new DocumentService()).renderDocumentURL(msg.sender.profileImg?.id),
                                    }}
                                    isReply={msg.isUser}
                                />
                            )
                        })
                    }
                </Chat>
            )}

            <InputGroup>
                <Textarea
                    name='message'
                    value={newMessage}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNewMessage(e.target.value)}
                    ariaLabel='Message'
                    style={{ height: '20px' }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault()
                            _handleSendMessage()
                        }
                    }}
                    className="rounded non-resize"
                />
                <Button
                    onClick={() => { if (fileInputRef.current) fileInputRef.current.click() }}
                    color='secondary' isLight icon='AttachFile' className="ms-2 rounded"
                />
                <Button
                    onClick={_handleSendMessage}
                    color='secondary' isLight icon='Send' className="ms-2 rounded"
                />
            </InputGroup>

            <input
                type="file"
                ref={fileInputRef}
                onChange={_handleSendDocument}
                style={{ display: 'none' }}
            />
        </section>
    );
}

export default ClientChat;
import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Page from "../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../layout/SubHeader/SubHeader";
import Button from "../../components/bootstrap/Button";
import Spinner from "../../components/bootstrap/Spinner";
import FormLabel from "../../components/FormLabel";
import IconWithTooltip from "../../components/bootstrap/IconWithTooltip";
import { Rations } from "./components/Rations";
import * as yup from 'yup';
import { toast } from "react-toastify";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    configData?: GeneralConfigForm;
    token?: any;
}

interface GeneralConfigForm {
    timeSlot: number;
    textField: string;
    foodRationConfiguration: { id: string, name: string, description: string, value: number }[];
}

const configInitialValues: GeneralConfigForm = {
    timeSlot: 15,
    textField: '',
    foodRationConfiguration: []
}

export interface RationForm {
    name: string;
    multiplier: number;
    description: string;
}

const rationInitialValues: RationForm = {
    name: '',
    multiplier: 1,
    description: '',
}

const ConfigSchema = yup.object({
    timeSlot: yup.number().min(1, 'El mínimo es 1').max(120, 'Demasiado tiempo'),
    textField: yup.string(),
    foodRationConfiguration: yup.array()
});

const ConfigRationSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    multiplier: yup.number().min(0.1, 'El mínimo es 0.1').max(100, 'No puedes multiplicar por más de 100').required('El multiplicador es obligatorio'),
    description: yup.string(),
});

const GeneralConfigForm: FC<CreateFormProps> = ({ isLoading, submit, configData, token }) => {

    const [rationsData, setRationsData] = useState<{ id: string, name: string, description: string, value: number }[]>([]);

    // Añade la ración al array de raciones
    const _handleAddRation = (values: RationForm) => {
        const newRationsData = [...rationsData, {
            id: '',
            name: values.name,
            description: values.description,
            value: values.multiplier
        }];
        setRationsData(newRationsData);
    }

    const _handleSubmit = (values: GeneralConfigForm) => {
        // Comprobar que no haya raciones sin nombre o con valor 0
        const rationsDataErrors = rationsData.filter(r => r.name === '' || r.value <= 0);
        if (rationsDataErrors.length > 0) {
            toast.error('El nombre y el multiplicador son obligatorios en las raciones');
            return;
        }

        const config = {
            timeSlot: values.timeSlot,
            textField: values.textField,
            foodRationConfiguration: values.foodRationConfiguration
        }

        submit(config);
    }

    const formik = useFormik<GeneralConfigForm>({
        initialValues: configData || configInitialValues,
        validationSchema: ConfigSchema,
        onSubmit: values => {
            values.foodRationConfiguration = [...rationsData];

            // Añadir los valores que tenga formikRation al array de raciones
            if (formikRation.values.name !== '' && formikRation.values.multiplier > 0) {
                values.foodRationConfiguration.push({
                    id: '',
                    name: formikRation.values.name,
                    description: formikRation.values.description,
                    value: formikRation.values.multiplier
                });
            }
            _handleSubmit(values);
        }
    });

    const verifyClass = (inputFieldID: keyof GeneralConfigForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrors = (inputFieldID: keyof GeneralConfigForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const formikRation = useFormik<RationForm>({
        initialValues: rationInitialValues,
        validationSchema: ConfigRationSchema,
        onSubmit: values => _handleAddRation(values)
    });

    const verifyClassRation = (inputFieldID: keyof RationForm) => { return (formikRation.touched[inputFieldID] && formikRation.errors[inputFieldID]) ? 'is-invalid' : '' }

    const showErrorsRation = (inputFieldID: keyof RationForm) => {
        return (formikRation.touched[inputFieldID] && formikRation.errors[inputFieldID]) ? <div className="invalid-feedback">{formikRation.errors[inputFieldID]}</div> : <></>;
    }

    // Setea los valores de las raciones cuando recibe los datos
    useEffect(() => {
        if (configData?.foodRationConfiguration) {
            setRationsData(configData.foodRationConfiguration);
        }
    }, [configData]);

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Configuración general</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : 'Modificar'}
                    </Button>
                </SubHeaderRight>
            </SubHeader>

            <Page container='md'>
                <Card>
                    <CardHeader className='rounded-1 mb-0'>
                        <CardLabel icon='DisplaySettings' iconColor='secondary'>
                            <CardTitle>Datos generales para todos los usuarios de {token?.companyName}</CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody>
                        <form onSubmit={formik.handleSubmit} autoComplete="off" className="row">
                            <div className='col-md-4'>
                                <FormLabel label="Tiempo del calendario (min)" className="w-auto" />
                                <IconWithTooltip label="Tiempo de cada slot del calendario. Esto esa el tiempo en minutos que tendrá cada fila del calendario cuando se muestre la vista diaria" />
                                <Input
                                    id='timeSlot'
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.timeSlot}
                                    onBlur={formik.handleBlur}
                                    className={verifyClass('timeSlot') + ' w-25'}
                                />
                                {showErrors('timeSlot')}
                            </div>

                            <FormGroup label="Texto para la firma de consentimiento" className="col-md-8">
                                <textarea
                                    id="textField"
                                    onChange={formik.handleChange}
                                    rows={5}
                                    value={formik.values.textField}
                                    style={{
                                        borderRadius: '10px',
                                        boxShadow: '0 0 0 3px rgb(245, 156, 57, 0.3)',
                                        backgroundColor: '#f8f9fa',
                                        width: '100%',
                                        minHeight: '90px',
                                        padding: '15px',
                                        border: '0'
                                    }}
                                    className="mt-1"
                                />
                            </FormGroup>
                        </form>

                        <Rations
                            rationsData={rationsData} setRationsData={setRationsData}
                            formik={formikRation} verifyClass={verifyClassRation} showErrors={showErrorsRation}
                        />
                    </CardBody>
                </Card>
            </Page>
        </>
    );
}

export default GeneralConfigForm;
import React from 'react';
import FormGroup from './FormGroup';
import Input from './Input';
import '../../../styles/styles.scss';

interface CustomNumberInputProps {
    cols?: string;
    label?: string;
    id: string;
    value: string | number;
    onChange: (value: string) => void;
    min?: number;
    required?: boolean;
    placeholder?: string;
    setError?: (value: boolean) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    smallPlaceholder?: boolean;
}

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({ cols, label, id, value, onChange, min, required, placeholder, setError, onKeyDown, smallPlaceholder }) => {

    // Check if the input value has more than one comma or dot
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;

        const commaCount = (inputValue.match(/,/g) || []).length;
        const dotCount = (inputValue.match(/\./g) || []).length;

        if ((commaCount > 1 || dotCount > 1) || (commaCount > 0 && dotCount > 0)) {
            setError && setError(true);
        } else {
            setError && setError(false);
        }

        onChange(inputValue);
    };

    // Prevent non-numeric input
    const preventNonNumericInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = /[0-9.,]/;
        if (!allowedKeys.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
            event.preventDefault();
        }
    };

    return (
        <FormGroup label={label} className={`col-md-${cols || 12}`} requiredInputLabel={required} >
            <Input
                type="text"
                id={id}
                onChange={handleInputChange}
                onKeyDown={onKeyDown || preventNonNumericInput}
                placeholder={placeholder}
                value={value}
                min={min || 0}
                required={required}
                className={smallPlaceholder ? 'custom-placeholder' : ''}
            />
        </FormGroup>
    );
};

export default CustomNumberInput;
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { MenuService } from "../../../services/menus/menuService";
import { Menu, MenusApiResponse } from "../../../type/clientMenus-type";
import { useFormik } from "formik";
import Input from "../../../components/bootstrap/forms/Input";
import Icon from "../../../components/icon/Icon";
import NutritionalMenu from "../../../components/menu/NutritionalMenu";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { allergenList } from "../../../utils/FoodUtils";
import Allergens from "../../../components/Allergens";
import moment from "moment";
import { useIdle } from "react-use";
import { authMenu } from "../../../menu";
import { MenuProvider } from "../../../components/menu/providers/MenuProvider";

export interface MenuFormEdit {
    name: string;
}

export const menuInitialValues: MenuFormEdit = {
    name: '',
}

const MenuView: FC = () => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const isIdle = useIdle(500); // Tiempo de inactividad
    const menuService = new MenuService();

    const [patientAversions, setAversions] = useState<string[]>([]);
    const [name, setName] = useState('');
    const [inputFocused, setInputFocused] = useState(false);

    const fetchMenu = useCallback(async () => {
        const response = (await menuService.getMenuById(id as string, null));
        return response.getResponseData() as Menu;
    }, []);

    const [data] = useFetch(fetchMenu);

    // Envia el formulario a la api
    const handleUpdateMenu = async (values: any) => {
        values.menu = id as string;

        try {
            const response = await menuService.editMenu(values);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                //toast.success('Nombre del menú editado correctamente');
            } else {
                toast.error(responseData.message || "Error al editar el menú");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Busca los alérgenos del paciente
    function findPatientAllergens(patientData: any, allergenList: any) {
        const allergens = [];
        for (const allergen of allergenList) {
            const allergenName = allergen.name;
            if (patientData && patientData[allergenName]) {
                allergens.push(allergenName);
            }
        }
        return allergens;
    };

    // Busca las aversiones del paciente. Dentro de data.client.clientAversions se encuentran las aversiones. Puede ser un alimento o un grupo de alimentos (food o foodGroup)
    function findPatientAversions(patientData: any) {
        const aversions = [];
        if (patientData && patientData?.clientAversions) {
            for (const aversion of patientData.clientAversions) {
                if (aversion.food) {
                    aversions.push(aversion.food.name);
                } else if (aversion.foodGroup) {
                    aversions.push(aversion.foodGroup.name);
                }
            }
        }
        return aversions;
    };

    const patientAllergens = findPatientAllergens(data?.client, allergenList);

    const formik = useFormik({
        initialValues: menuInitialValues,
        onSubmit: () => { }
    });

    // Actualiza el estado name
    useEffect(() => {
        if (data) {
            setName(data.name);
        }
    }, [data]);

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            name: name || ''
        });
    }, [name]);

    // Actualiza el nombre del menú cuando se deja de escribir en el input
    useEffect(() => {
        if (isIdle && inputFocused) {
            handleUpdateMenu(formik.values);
        }
    }, [isIdle, inputFocused, formik.values]);

    useEffect(() => {
        if (data?.client) {
            setAversions(findPatientAversions(data.client));
        }
    }, [data]);

    return (
        data !== null
            ? (
                <MenuProvider>
                    <SubHeader>
                        <SubHeaderLeft>
                            <Button color="secondary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />

                            <form onSubmit={formik.handleSubmit} className="d-flex" autoComplete="off">
                                <Input
                                    id='name'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onFocus={() => setInputFocused(true)}
                                    onBlur={() => setInputFocused(false)}
                                />
                            </form>
                        </SubHeaderLeft>
                    </SubHeader>

                    {/* Si existe un paciente, muestra los datos del paciente */}
                    {data.client && (
                        <div className="row mt-3 mb-3 ms-5">
                            <div className='col-12 d-flex justify-content-center flex-column'>
                                <div className='row g-5'>
                                    <div className='col-md-3 d-flex justify-content-start px-0'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon icon='PermIdentity' size='2x' color='primary' />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <a
                                                    className='fw-bold fs-6 mb-0 cursor-pointer text-secondary text-decoration-none'
                                                    href={authMenu.patients.path + `/${data.client.id}/view`}
                                                >
                                                    {(data.client.name + ' ' + (data.client.lastName || ''))}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-4 d-flex justify-content-start flex-column px-0'>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon icon='Mail' size='2x' color='primary' />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-6 mb-0'>
                                                            {
                                                                data.client.email
                                                                    ? data.client.email
                                                                    : <div className="text-muted">No proporcionado</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon icon='Phone' size='2x' color='primary' />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-6 mb-0'>
                                                            {data.client.telephone
                                                                ? data.client.telephone
                                                                : <div className="text-muted">No proporcionado</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        {data.client.sex
                                                            ? data.client.sex === 'H' ? <Icon icon="Male" size="2x" color="primary" /> : <Icon icon="Female" size="2x" color="primary" />
                                                            : <div className="text-muted">No proporcionado</div>
                                                        }
                                                    </div>

                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-6 mb-0'>
                                                            {data.client.sex === 'H' ? 'Hombre' : 'Mujer'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon icon='Cake' size='2x' color='primary' />
                                                    </div>

                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-6 mb-0'>
                                                            {moment().diff(data.client.birthdayDate?.date, 'years') + ' años' || <div className="text-muted">No proporcionado</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(patientAllergens.length > 0) && (
                                        <div className='col-md-5 d-flex justify-content-end ps-0'>
                                            {patientAllergens.length > 0 && (
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon icon='Warning' size='2x' color='danger' />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-6 mb-0'>
                                                            {data.client
                                                                ? <Allergens allergens={patientAllergens} size={30} />
                                                                : <div className="text-muted">No proporcionado</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                {patientAversions.length > 0 && (
                                    <div className='row mt-3'>
                                        <div className="col-12 d-flex align-items-center p-0">
                                            <div className='flex-shrink-0'>
                                                <Icon icon='ThumbDown' size='2x' color='secondary' />
                                            </div>
                                            <div className="container">
                                                <ul className='mb-0 row row-cols-5'>
                                                    {patientAversions.map((aversion, index) => (
                                                        <li key={index} className='col'>
                                                            {aversion}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {/* Provider que permite dar contexto para hacer drag & drop */}
                    <DndProvider backend={HTML5Backend}>
                        <NutritionalMenu menuId={data.id} allergens={patientAllergens} />
                    </DndProvider>
                </MenuProvider>
            )
            : <></>
    );
};

export default MenuView;
import { useCallback, useEffect, useState } from 'react';
import { FoodService } from '../../services/foods/foodService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage nutrients fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de nutrientes.
----------------------------------------------------------------------------------------*/

const useNutrients = () => {

    const [data, setData] = useState<any[]>([]);
    const [allData, setAllData] = useState<any[]>([]);

    const fetchData = useCallback(async () => {
        try {
            const response = await (new FoodService()).getNutrientsList();
            const fetchedData = response.getResponseData();

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { name: string; nombre: string; }) => ({
                    value: data.name,
                    label: data.nombre,
                }));
                setData(mappedData);
                setAllData(fetchedData.data);
            }
        } catch (error) {
            console.log('Error fetching nutrients:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const getNutrientsList = () => {
        return data;
    };

    const getAllNutrientsData = () => {
        return allData;
    }

    return { fetchData, getNutrientsList, getAllNutrientsData };
}

export default useNutrients;
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { CustomTable } from "../../../components/table/CustomTable";
import { useNavigate } from "react-router-dom";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import moment from "moment";
import useFilters from "../../../hooks/useFilters";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { TipsGroupsService } from "../../../services/tips/groupsService";
import { tipsMenu } from "../../../menu";
import ErrorMessage from "../../../components/ErrorMessage";
import Input from "../../../components/bootstrap/forms/Input";
import Icon from "../../../components/icon/Icon";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";
import { useFormik } from "formik";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import * as Yup from 'yup';
import Spinner from "../../../components/bootstrap/Spinner";
import { debounce } from "lodash";
import CreateGroupModal from "../create-group/CreateGroupModal";
import SvgCustomCheckMark from "../../../components/icon/svg-icons/CustomCheckMark";
import "./../../../styles/svg-styles.scss";

interface ITipsFilters {
    client?: any;
}

const tipsFilters: ITipsFilters = {
    client: null,
};

let initialFormikValues = {
    names: [],
    descriptions: []
};

const GroupsList = () => {
    const navigate = useNavigate();
    const tipsService = new TipsGroupsService();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(tipsFilters);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [updating, setUpdating] = useState(0);

    const [groups, loadingGroups, groupsError, refetchGroups] = useFetch(useCallback(async () => {
        const response = (await tipsService.listGroups(filters));
        return response.getResponseData();
    }, [filters]));

    // Create initialFormikValues and store it in formik
    useEffect(() => {
        setIsMounted(false);
        initialFormikValues.names = groups?.tip_groups.reduce((acc: any, group: any) => {
            acc[group.id] = group.name ? group.name.trim() : '';
            return acc;
        }, {});
        initialFormikValues.descriptions = groups?.tip_groups.reduce((acc: any, group: any) => {
            acc[group.id] = group.description ? group.description.trim() : '';
            return acc;
        }, {});
        formik.resetForm();
        setIsMounted(true);
    }, [groups]);

    // Create formik validation schema
    const validationSchema = Yup.object({
        names: Yup.object().shape(
            groups?.tip_groups?.reduce((acc: any, element: any) => {
                acc[element.id] = Yup.string()
                    .required('El nombre es obligatorio')
                    .min(3, 'Debe tener al menos 3 caracteres');
                return acc;
            }, {})
        ),
    });

    const formik = useFormik({
        initialValues: initialFormikValues,
        validationSchema: validationSchema,
        onSubmit: (values) => console.log(values),
        validateOnChange: true,
        validateOnBlur: true,
    });

    const debouncedCall = debounce(() => {
        if (!formik.dirty || formik.initialValues?.names?.length <= 0) return;
        if (!formik.isValid || ((formik.errors?.names && formik.errors?.names?.length >= 1) || (formik.errors?.descriptions && formik.errors?.descriptions?.length >= 1))) {
            setUpdating(2);
            return toast.error('Hay errores en el formulario');
        }

        let rowsUpdated = 0;
        groups?.tip_groups.forEach((group: any) => {
            if (formik.values.names?.[group.id] !== formik.initialValues.names?.[group.id] ||
                formik.values.descriptions?.[group.id] !== formik.initialValues.descriptions?.[group.id]) {
                _handleUpdate(group.id);
                rowsUpdated++;
            }
        });
        setUpdating(0);
    }, 2500);

    useEffect(() => {
        if (formik.dirty || formik.initialValues?.names?.length <= 0) {
            setUpdating(1);
            debouncedCall();
        }
        return () => {
            debouncedCall.cancel();
            setUpdating(0);
        };
    }, [formik.isValid, formik.values]);

    // Function to delete a tip group by id
    const handleDelete = async (id: string) => {
        try {
            const response = (await tipsService.deleteGroup(id)).getResponseData();
            if (response.success) {
                refetchGroups();

                toast.success('Grupo eliminado correctamente');
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el grupo');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el grupo');
        }
    };

    // Function to handle the search input filter
    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    // Function to edit the group with the new values
    const _handleUpdate = async (groupId: any) => {

        let editGroupValues = {
            tipGroup: groupId,
            name: formik.values?.names?.[groupId],
            description: formik.values?.descriptions?.[groupId]
        }

        try {
            const response = await tipsService.editGroup(editGroupValues);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(tipsMenu.tips.subMenu.tipsGroups.path, { replace: true });
            } else {
                toast.error(responseData.message || "Error al editar el consejo");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            refetchGroups();
        }
    };

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Grupos de Consejos</CardTitle>
                    <SubheaderSeparator />

                    {formik.values !== initialFormikValues && (
                        <Button
                            title="Descartar cambios"
                            color="danger"
                            isLight
                            onClick={() => {
                                handleConfirmationAlert({
                                    title: '¿Estás seguro?',
                                    text: 'Se descartarán todos los cambios realizados',
                                    icon: 'warning',
                                    onConfirm: async () => {
                                        formik.resetForm();
                                    }
                                })
                            }}
                        >
                            Descartar cambios
                        </Button>
                    )}


                    <div className="mx-2 d-flex flex-row justify-content-center align-content-center gap-2">
                        {updating === 1 ? (
                            <>
                                <Spinner color={'dark'} />
                                <h6 className="m-auto fw-bold align-middle">Actualizando...</h6>
                            </>) : updating === 0 ? (
                                <>
                                    <SvgCustomCheckMark />
                                    <h6 className="m-auto fw-bold align-middle">Guardado</h6>
                                </>
                            ) : (
                                <h6 className="m-auto fw-bold align-middle">Error en el formulario</h6>
                            )
                        }
                    </div>

                </SubHeaderLeft>
                <SubHeaderRight>
                    <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='secondary' />
                    </label>

                    <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <form onSubmit={formik.handleSubmit} autoComplete="off">
                            {!isMounted ? (
                                <div className="text-center">
                                    <Spinner color={'primary'} />
                                </div>
                            ) : (
                            <CustomTable
                                title="Grupos de consejos"
                                    data={groups ? groups?.tip_groups : null}
                                    pagination
                                    paginationData={{
                                        pageSize: filters.limit,
                                        currentPage: filters.page,
                                        pageCount: groups ? groups.lastPage : 1,
                                        handlePagination: (page: any) => {
                                            updatePage({ selected: page.selected + 1 });
                                        },
                                        handlePerPage: updatePageSize,
                                    }}
                                    className={"table-striped table-hover"}
                                    columns={[
                                        {
                                            name: "Nombre",
                                            keyValue: "name",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            render: (element: any) => {
                                                const hasErrors = formik.errors.names?.[element.id];
                                                return (
                                                    <div key={element.id}>
                                                        <Input
                                                            type="text"
                                                            name={`names.${element.id}`}
                                                            value={formik.values.names?.[element.id] || ''}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            placeholder="Nombre del grupo"
                                                            className={hasErrors ? "form-control is-invalid" : "form-control"}
                                                            style={{ minWidth: '300px' }}
                                                        />
                                                        {formik.errors.names?.[element.id] && (
                                                            <div className="text-danger p-1">
                                                                {formik.errors.names[element.id]}
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            name: "Descripción",
                                            keyValue: "description",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            render: (element: any) => {
                                                return (
                                                    <Textarea
                                                        name={"description" + element.id}
                                                        value={formik.values.descriptions?.[element.id] || ''}
                                                        onChange={(e: any) => {
                                                            formik.setFieldValue(`descriptions.${element.id}`, e.target.value);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        className="form-control"
                                                        placeholder="Descripción del grupo"
                                                        style={{ minWidth: '300px' }}
                                                    />
                                                )
                                            }
                                        },
                                        {
                                            name: "Fecha de creación",
                                            keyValue: "createdAt",
                                            sortable: true,
                                            style: { maxWidth: 'fit-content', width: 'auto' },
                                            sortColumn: updateFilterOrder,
                                            render: (element: any) => {
                                                return (
                                                    <>
                                                        {moment(element.createdAt?.date).format('DD/MM/YYYY') || "-"}
                                                    </>
                                                )
                                            }
                                        },
                                        {
                                            name: "Última actualización",
                                            keyValue: "updatedAt",
                                            sortable: true,
                                            sortColumn: updateFilterOrder,
                                            render: (element: any) => {
                                                return (
                                                    <>
                                                        {element.updatedAt?.date ? moment(element.updatedAt?.date).format('DD/MM/YYYY') : "-"}
                                                    </>
                                                )
                                            }
                                        },
                                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                                    ]}
                                    actions={[
                                        {
                                            title: "Eliminar",
                                            icon: "Delete",
                                            buttonType: 'icon',
                                            additionalClasses: 'text-danger',
                                            description: "Eliminar grupo",
                                            click: (item: any) => {
                                                handleConfirmationAlert({
                                                    title: "Eliminar grupo",
                                                    text: "¿Está seguro que desea eliminar el grupo?",
                                                    icon: "warning",
                                                    onConfirm: () => {
                                                        handleDelete(item.id);
                                                    }
                                                })
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </form>
                        <div className="w-100 d-flex flex-row justify-content-center">
                            <CreateGroupModal isOpen={isOpen} setIsOpen={setIsOpen} refetchGroups={() => refetchGroups()} />
                            <Button
                                title="Crear Grupo"
                                color="storybook"
                                isLight
                                onClick={() => setIsOpen(true)}
                                className="d-flex flex-row flex-nowrap align-content-center"
                            >Añadir Grupo
                                <Icon icon="North" className="ms-2 m-auto" ></Icon></Button>
                        </div>
                    </CardBody>
                </Card>
            </Page>
        </>
    );
}

export default GroupsList;
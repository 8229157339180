import { useEffect, useMemo, useState } from 'react';
import { FoodService } from '../../services/foods/foodService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage allergens fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de alergenos.
----------------------------------------------------------------------------------------*/

const useAllergens = () => {

    const service = useMemo(() => new FoodService(), [])
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await service.getAllergensList();
            const fetchedData = response.getResponseData()?.data;

            if (fetchedData) {
                const mappedData = fetchedData?.map((data: any) => data);
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching allergens: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getOptions = () => {
        return data.map((option: any) => (
            {
                value: option.name,
                label: option.nombre
            }
        ));
    };

    const getList = (entityId?: string) => {
        if (entityId) return data.filter((state: any) => state.id === entityId);
        return data;
    };

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    };

    const groupNutrients = (nutrients: []) => {
        // Clasifica los nutrientes por grupo
        const groups = nutrients.reduce((acc: any, nutrient: any) => {
            const nutrientGroupExists = acc.find((group: any) => group.group === nutrient.group);
            if (!nutrientGroupExists) {
                acc.push({
                    group: nutrient.group,
                    group_name: nutrient.grupo,
                    nutrients: []
                })
            }
            return acc;
        }, []);

        // Añade los nutrientes a su respectivo grupo
        nutrients.forEach((nutrient: any) => {
            const groupIndex = groups.findIndex((group: any) => group.group === nutrient.group);
            groups[groupIndex].nutrients.push(nutrient);
        });

        return groups;
    };

    return {
        fetchAllergens: fetchData,
        getAllergenOptions: getOptions,
        getAllergensList: getList,
        groupNutrients,
        getAllergenId: getEntity,
        isLoadingAlergens: isLoading
    };
}

export default useAllergens;
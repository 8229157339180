import { useFormik } from "formik";
import { useAppointmentCalendar } from "../providers/AppointmentCalendarProvider";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import { UserSelector } from "../../../components/select/UserSelector";
import { AppointmentClientSelector } from "../../../components/select/AppointmentClientSelector";
import { AppointmentTypeSelector } from "../../../components/select/AppointmentTypeSelector";
import Button from "../../../components/bootstrap/Button";
import { AppointmentServiceSelector } from "../../../components/select/AppointmentServiceSelector";
import { AppointmentStatusSelector } from "../../../components/select/AppointmentStatusSelector";
import { AppointmentPaymentMethodSelector } from "../../../components/select/AppointmentPaymentMethodSelector";
import Input from "../../../components/bootstrap/forms/Input";

export const AppointmentCalendarFilters: React.FC = () => {

    const { updateFilters, filters } = useAppointmentCalendar();

    const formik = useFormik({
        initialValues: {
            user: undefined,
            client: undefined,
            appointment_status: undefined,
            type: undefined,
            serviceType: undefined,
            paymentMethod: undefined,
            minAmount: undefined,
            maxAmount: undefined,
        },
        onSubmit: (values) => { updateFilters(values) }
    })

    return (
        <form onSubmit={formik.handleSubmit} className="d-flex flex-column">
            <div className="flex-grow">
                <FormGroup label="Profesional" className="mt-2">
                    <UserSelector name="user" formik={formik} selectedId={formik.values.user} onChange={formik.handleChange} />
                </FormGroup>

                <FormGroup label="Paciente" className="mt-2">
                    <AppointmentClientSelector name="client" formik={formik} selectedId={formik.values.client} onChange={formik.handleChange} />
                </FormGroup>

                <FormGroup label="Estado" className="mt-4">
                    <AppointmentStatusSelector name="appointment_status" formik={formik} selectedId={formik.values.appointment_status} onChange={formik.handleChange} />
                </FormGroup>

                <FormGroup label="Tipo de cita" className="mt-2">
                    <AppointmentTypeSelector name="type" formik={formik} selectedId={formik.values.type} onChange={formik.handleChange} />
                </FormGroup>

                <FormGroup label="Tipo de servicio" className="mt-2">
                    <AppointmentServiceSelector name="serviceType" formik={formik} selectedId={formik.values.serviceType} onChange={formik.handleChange} />
                </FormGroup>

                <FormGroup label="Método de pago" className="mt-4">
                    <AppointmentPaymentMethodSelector name="paymentMethod" formik={formik} selectedId={formik.values.paymentMethod} onChange={formik.handleChange} />
                </FormGroup>

                <FormGroup label='Importe' className='mt-4 row'>
                    <div className='col-6'>
                        <div className='row'>
                            <div className='col-md-4 d-flex flex-column'>
                                <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Mínimo: </p>
                            </div>
                            <div className='col-md-8'>
                                <Input
                                    name='minAmount'
                                    type='number'
                                    value={formik.values.minAmount}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='row d-flex justify-content-end'>
                            <div className='col-md-4 d-flex flex-column'>
                                <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Máximo: </p>
                            </div>
                            <div className='col-md-8'>
                                <Input
                                    name='maxAmount'
                                    type='number'
                                    value={formik.values.maxAmount}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </FormGroup>
            </div>

            <div className="row mt-4">
                <div className="d-flex justify-content-start col">
                    <Button type="button" color="secondary" className="me-4" isLight onClick={() => { formik.resetForm() }}>Limpiar</Button>
                </div>
                <div className="d-flex justify-content-end col">
                    <Button type="submit" color="primary">Filtrar</Button>
                </div>
            </div>
        </form>
    );
}
import React, { ChangeEvent, FC, Fragment, useState } from 'react'
import Button from '../../../components/bootstrap/Button'
import FormGroup from '../../../components/bootstrap/forms/FormGroup'
import Input from '../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas'
import Icon from '../../../components/icon/Icon'
import Select from '../../../components/bootstrap/forms/Select'
import CustomSearchInput from '../../../components/bootstrap/forms/CustomSearchInput'
import { physiologicalState } from '../../patients/PatientForm'
import useNutrients from '../../../hooks/api-calls/useNutrients'
import SearchableSelect from '../../../components/select/SearchableSelect'

interface IDailyIntakesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const DailyIntakesFilters: FC<IDailyIntakesFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const { getNutrientsList } = useNutrients();
    const [filterMenu, setFilterMenu] = useState(false);

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={(search: string) => { updateFilters({ search_array: search }) }} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='DailyIntakesFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='DailyIntakesFilters'>Filtros de Ingestas</OffCanvasTitle>

                    <Button
                        color="storybook"
                        isLight
                        onClick={() => { resetFilters(); }}
                    >
                        Resetear
                    </Button>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <FormGroup id='active-filter' label='Estado' className='col-md-12'>
                            <Select
                                id='active-select'
                                list={[
                                    { value: 3, label: 'Todos', text: 'Todos' },
                                    { value: 1, label: 'Activo', text: 'Activo' },
                                    { value: 0, label: 'Desactivado', text: 'Desactivado' }
                                ]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                value={filters.filter_filters.active.toString()}
                                placeholder='Estado...'
                                ariaLabel='Estado'
                            />
                        </FormGroup>

                        <FormGroup id='age-filter' label='Edad' className='col-md-12'>
                            <Input
                                type='number'
                                id='age'
                                name='age'
                                value={filters.filter_filters.age}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => updateFilters({ age: parseInt(e.target.value) })}
                            />
                        </FormGroup>

                        <FormGroup id='gender-filter' label='Género' className='col-md-12'>
                            <Select
                                id='gender-select'
                                list={[
                                    { value: 'Todos', label: 'Todos', text: 'Todos' },
                                    { value: 'Hombre', label: 'Hombre', text: 'Hombre' },
                                    { value: 'Mujer', label: 'Mujer', text: 'Mujer' }
                                ]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ gender: e.target.value !== 'Todos' ? e.target.value : null })}
                                value={filters.filter_filters.gender}
                                placeholder='Género...'
                                ariaLabel='Género'
                            />
                        </FormGroup>

                        <FormGroup id='physiological-state-filter' label='Estado Fisiológico' className='col-md-12'>
                            <Select
                                id='physiological-state-select'
                                list={[{ value: 'Todos', label: 'Todos', text: 'Todos' }, ...physiologicalState]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ physiological_state: e.target.value !== 'Todos' ? e.target.value : null })}
                                value={filters.filter_filters.physiological_state}
                                placeholder='Elegir...'
                                ariaLabel='Estado Fisiológico'
                            />
                        </FormGroup>

                        <FormGroup id='nutrient-filter' label='Nutriente' className='col-md-12'>
                            <SearchableSelect
                                name='nutrients-select'
                                isClearable
                                isMulti
                                options={getNutrientsList()}
                                onChange={(e: any) => updateFilters({ nutrients: e?.map((item: any) => item.label) })}
                                value={filters.filter_filters.nutrients?.map((item: any) => ({ value: getNutrientsList().find((nutrient: any) => nutrient.label === item)?.value, label: item }))}
                                placeholder='nutriente'
                            />
                        </FormGroup>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default DailyIntakesFilters;
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { FC, useCallback } from "react";
import { FormService } from "../../../services/forms/formService";
import ErrorMessage from "../../../components/ErrorMessage";
import Spinner from "../../../components/bootstrap/Spinner";
import ListFillValues from "./ListFillValues";
import EditFormFillValues from "../patient-forms/EditFormFillValues";

interface FillValuesProps {
    routes?: boolean;
    formFillId?: string;
    setIsOpen?(...args: unknown[]): unknown;
    refetch?(): void;
}

const EditFillValues: FC<FillValuesProps> = ({ formFillId, routes, setIsOpen, refetch }) => {
    const { id = '' } = useParams<{ id: string }>();
    const formService = new FormService();

    // Obtener los campos del formulario
    const [fillData, loadingFill, fillError, refetchFill] = useFetch(useCallback(async () => {
        const response = (await formService.getFillValues(formFillId || id));
        return response.getResponseData();
    }, []));

    if (loadingFill) return <div className="text-center"><Spinner /></div>;

    if (fillError) return <ErrorMessage message={fillError} />;

    return (
        routes
            ? <ListFillValues data={fillData} loading={loadingFill} refetch={refetchFill} />
            : <EditFormFillValues data={fillData} loading={loadingFill} setIsOpen={setIsOpen} refetch={refetch} />
    )
}

export default EditFillValues;
import { TIcons } from './icons-type';

export type TMenu = { [key: string]: { text: string; icon: TIcons; path: string; id: string } };

interface IFoodFilters {
    group_food: string[];
    high_in: string;
    season: string;
    seasonend: string;
}

interface ISupplementFilters {
    supplement: boolean;
}

interface IRecipeFilters {
    active: number;
    high_in: string;
    group_food: string[];
}

interface IActivityFilters {
    status: number;
}

export const foodFilters: IFoodFilters = {
    group_food: [],
    high_in: "",
    season: "enero",
    seasonend: "diciembre",
};

export const supplementFilters: ISupplementFilters = {
    supplement: true,
};

export const recipeFilters: IRecipeFilters = {
    active: 1,
    high_in: "",
    group_food: [],
};

export const activityFilters: IActivityFilters = {
    status: 1,
};

export interface Day {
    id: string;
    name: string;
    value: number;
    createdAt: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    updatedAt: null;
}


export interface Intake {
    id: string;
    name: string;
    value: number;
    createdAt: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    updatedAt: null;
}

export interface MenuItem {
    menu: string;
    day?: string;
    intake?: string;
    food?: string | null;
    recipe?: string | null;
    activity?: string | null;
    quantity?: number;
    calculate?: boolean;
    company?: string;
}